<script>
import RescheduleAppointmentForm from '@satellite/modules/appointments/components/reschedule/RescheduleAppointmentFormBaseOld.vue';

export default {
  name: 'RescheduleAppointmentFormOld',
  extends: RescheduleAppointmentForm,
  data() {
    return {
      allowDockSelect: true
    };
  }
};
</script>
