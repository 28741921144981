var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "mb-4" },
    [
      _c("v-col", { attrs: { sm: "6" } }, [
        _c("h1", [_vm._v("Live Yard View")]),
      ]),
      _c("v-spacer"),
      _vm.selectedWarehouse?.id
        ? _c(
            "v-col",
            { staticClass: "d-flex flex-row justify-end", attrs: { sm: "6" } },
            _vm._l(_vm.cards, function (card) {
              return _c(
                "v-card",
                {
                  key: card.title,
                  staticClass: "ml-4",
                  attrs: {
                    elevation: "1",
                    "data-testid": `yard-view-page-header-card-${card.title}`,
                    width: "190",
                    height: "70",
                  },
                },
                [
                  _c(
                    "v-card-subtitle",
                    { staticClass: "pb-0 pt-3 text-uppercase" },
                    [_vm._v(" " + _vm._s(card.title) + " ")]
                  ),
                  _c("v-card-text", [_c("h3", [_vm._v(_vm._s(card.count))])]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }