var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 d-flex justify-center align-center mt-10" },
    [
      _c(
        "div",
        {
          staticClass: "overlay-contents",
          attrs: { "data-testid": "yard-no-warehouse-overlay" },
        },
        [
          _c("svg-warehouse"),
          _c("h3", { staticClass: "align-center" }, [
            _vm._v("No Warehouse Selected"),
          ]),
          _c("p", [_vm._v("Please select a warehouse first.")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }