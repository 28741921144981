<template>
  <v-row justify="space-between">
    <v-col sm="6">
      <warehouse-select
        data-testid="yard-warehouse-select"
        :multi-select="false"
        :show-create-dock-button="false"
        :should-disable-if-no-docks="false"
        hide-icon
        :clearable="false"
        :restricted-warehouse-ids="$me.warehouseAccessList"
        :auto-select-by-id="defaultWarehouseId"
        v-model="selectedWarehouse" />
    </v-col>
    <v-col sm="3" v-if="selectedWarehouse?.id">
      <yard-view-area-select></yard-view-area-select>
    </v-col>
    <v-col class="d-flex justify-end" v-if="selectedWarehouse?.id && !isReadOnly">
      <v-btn data-testid="yard-subheader-add-area" text @click="addSpotArea">+ Area</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import WarehouseSelect from '@/components/elements/selects/WarehouseSelect.vue';
import YardViewAreaSelect from '@/modules/yard/components/Selects/YardViewAreaSelect.vue';
import { useRouter } from 'vue-router/composables';

export default defineComponent({
  name: 'YardViewPageSubHeader',
  components: { YardViewAreaSelect, WarehouseSelect },
  props: {},
  setup() {
    const yardStore = useYard();
    const router = useRouter();

    const addSpotArea = () =>
      router.push({
        name: 'yard-add-spot-area',
        params: { warehouseId: yardStore.defaultWarehouseIdRef.value }
      });

    return {
      addSpotArea,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      defaultWarehouseId: yardStore.defaultWarehouseIdRef,
      isReadOnly: yardStore.isReadOnlyRef
    };
  }
});
</script>
