import { kebabCase } from 'lodash';
import Vue from 'vue';

export default {
  registerComponents() {
    let requireComponents = [];

    // Unfortunately, this cannot be abstracted out because require.context arguments must be literals
    requireComponents.push(require.context('@/components', true, /[A-Z]\w+\.(vue)$/));
    requireComponents.push(require.context('@/modules', true, /[A-Z]\w+\.(vue)$/));
    // TODO: Investigate excluding v2 with regex more - did not work initially with /(?!.*\/v2\/)[A-Z]\w+\.(vue)$/))
    requireComponents.push(
      require.context('@satellite/components/elements', true, /[A-Z]\w+\.(vue)$/)
    );
    requireComponents.push(
      require.context('@satellite/components/mixins', true, /[A-Z]\w+\.(vue)$/)
    );
    requireComponents.push(require.context('@satellite/modules', true, /[A-Z]\w+\.(vue)$/));

    requireComponents.map(requireComponent => {
      requireComponent.keys().forEach(fileName => {
        const componentConfig = requireComponent(fileName);
        const componentName = kebabCase(
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        );
        // Register component globally if not already registered
        if (!Vue.options.components[componentName]) {
          Vue.component(componentName, componentConfig.default || componentConfig);
        }
      });
    });
  }
};
