<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row align="center" class="search-field">
          <v-col md="6">
            <text-field
              class="mb-5"
              v-model="filters.searchStr"
              append-icon="mdi-magnify"
              label="Search orgs"
              hint="Search orgs"
              single-line></text-field>
          </v-col>
          <v-col>
            <v-switch
              class="mb-9"
              v-model="showOnlyInactive"
              @change="getData()"
              inset
              label="Show only inactive"></v-switch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="rowHeaders"
        :items="$orgs"
        :loading="$data.$globalLoading"
        :server-items-length="$orgPagination.total"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="footerProps"
        :class="{ 'text--secondary': showOnlyInactive }"
        :options.sync="options">
        <template v-slot:item.orgType="{ item }">
          {{ item.orgType }}
        </template>

        <template v-slot:item.name="{ item }">
          <copy-content :content="item.name">{{ item.name }}</copy-content>
        </template>

        <template v-slot:item.id="{ item }">
          <copy-content :content="item.id">{{ item.id }}</copy-content>
        </template>

        <template v-slot:item.tags="{ item }">
          <v-chip v-for="tag in item.tags" :key="tag" x-small class="px-2 mb-1 font-weight-bold">
            {{ novaCore.truncateString(tag, 20) }}
          </v-chip>
        </template>

        <template v-slot:item.createdByUser="{ item }">
          <template v-if="item.createdByUser">
            {{ item.createdByUser.email }}
          </template>
          <template v-else>
            <v-chip color="red" x-small class="px-2 mb-1 font-weight-bold">User Deleted</v-chip>
          </template>
        </template>

        <template v-slot:item.createDateTime="{ item }">
          {{ novaCore.formatExpiresAt(item.createDateTime) }}
        </template>

        <template v-slot:item.expiresAt="{ item }">
          <v-tooltip bottom v-if="novaCore.isExpiresAtWarningVisible(item.expiresAt)">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="red" small class="mr-1">mdi-alert</v-icon>
            </template>
            <span>{{ novaCore.getExpirationWarningText(item.expiresAt) }}</span>
          </v-tooltip>
          {{ novaCore.formatExpiresAt(item.expiresAt) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <org-management-menu @updated="getData" :org="item"></org-management-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';

export default {
  mixins: [dataListMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          searchable: true,
          sortable: true
        },
        {
          text: 'ID',
          value: 'id',
          searchable: true
        },
        {
          text: 'Org Type',
          value: 'orgType',
          searchable: false,
          sortable: false
        },
        {
          text: 'Tags',
          value: 'tags',
          searchable: true,
          width: '20%'
        },
        {
          text: 'Creation Date',
          value: 'createDateTime',
          searchable: true,
          width: '120px'
        },
        {
          text: 'Created By',
          value: 'createdByUser',
          searchable: true
        },
        {
          text: 'Expiration date',
          value: 'expiresAt',
          searchable: true,
          width: '150px'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      sortBy: ['name'],
      showOnlyInactive: false,
      searchFields: ['name', 'id', 'tags'],
      joins: ['users']
    };
  },
  methods: {
    async getData() {
      await this.$store.dispatch('Orgs/getOrgs', this.getQuery());
    },
    getQuery() {
      const query = this.getQueryBase();
      query.s.isActive = { $eq: !this.showOnlyInactive };
      return query;
    }
  }
};
</script>
