<template>
  <div class="chat-container" :class="compactMode ? 'compact-mode' : ''">
    <header class="chat-header px-6 py-4">
      <div
        class="header-left"
        v-if="assetVisit.id && novaCore.hasWarehouseCheckinEnabled(warehouse)">
        Chat with
        <strong :data-testid="makeTestId('phone-number')">
          <v-icon class="ml-1" color="black" small>mdi-cellphone</v-icon>
          {{ assetVisit.phone }}
        </strong>
        <v-btn
          v-if="!isPhoneEditMode && allowPhoneEdit"
          :data-testid="makeTestId('edit-phone-btn')"
          text
          small
          @click="enablePhoneEditMode"
          class="edit-phone-btn px-1 pb-1">
          Edit
        </v-btn>
        <v-tooltip bottom v-if="!assetVisit.phone.startsWith('+1') && !isPhoneEditMode">
          <template v-slot:activator="{ on: onTooltip, attrs }">
            <span
              :data-testid="makeTestId('unsupported-number-error')"
              v-bind="attrs"
              v-on="onTooltip"
              class="font-size-x-small ml-2 country-code-warning">
              <v-icon color="error">mdi-alert-circle-outline</v-icon>
              Country code not supported
            </span>
          </template>
          <span>At this time only numbers starting with country code "+1" are supported.</span>
        </v-tooltip>
        <div class="d-flex justify-end mt-3" v-if="isPhoneEditMode">
          <phone-number-field
            ref="changePhoneNumberFieldRef"
            id="change-phone-number-field"
            :data-testid="makeTestId('change-driver-phone-field')"
            v-model="newAssetVisitPhone"
            :validator="$validator"
            dense
            outlined
            hide-details
            placeholder="Driver's Phone"
            :label="null"></phone-number-field>
          <outline-button class="ml-2" @click="isPhoneEditMode = false">Nevermind</outline-button>
          <primary-button
            class="ml-2"
            :data-testid="makeTestId('save-driver-phone-btn')"
            :disabled="!newAssetVisitPhone"
            @click="saveNewAssetVisitPhone">
            Save
          </primary-button>
        </div>
      </div>
      <div
        class="header-right"
        v-if="assetVisit.id && novaCore.hasWarehouseCheckinEnabled(warehouse)">
        <div v-if="isThreadExpired">
          <strong class="font-size-xx-small">
            This chat expired
            {{ makeChatTimestamp(messageThreadRef.expiresAt, warehouse) }}
          </strong>
        </div>
        <div
          v-else
          :data-testid="makeTestId('toggle-chat-open-status')"
          @click="handleChatStatusClick"
          class="cursor-pointer">
          <template v-if="compactMode">
            <v-tooltip bottom right>
              <template v-slot:activator="{ on }">
                <v-icon x-small class="mr-2" color="black" v-on="on">
                  mdi-{{ messageThreadRef.isOpen ? 'comment-off-outline' : 'comment-outline' }}
                </v-icon>
              </template>
              <span>{{ messageThreadRef.isOpen ? 'End this chat' : 'Resume this chat' }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <v-icon x-small class="mr-2" color="black">
              mdi-{{ messageThreadRef.isOpen ? 'comment-off-outline' : 'comment-outline' }}
            </v-icon>
            <strong class="font-size-xx-small" v-if="!compactMode">
              {{ messageThreadRef.isOpen ? 'End this chat' : 'Resume this chat' }}
            </strong>
          </template>
        </div>
      </div>
      <div v-else-if="!novaCore.hasWarehouseCheckinEnabled(warehouse)">
        <div class="font-size-x-small text--color-neutral-80">
          <v-icon class="mr-2">mdi-information-outline</v-icon>
          The Check-In feature must be enabled for this warehouse to send SMS messages.
        </div>
      </div>
      <div v-else>
        <div class="font-size-x-small text--color-neutral-80">
          <v-icon class="mr-2">mdi-information-outline</v-icon>
          Wait for the driver's check-in to enable the chat.
        </div>
      </div>
    </header>
    <div
      class="chat-stage pa-5"
      ref="chatStageRef"
      @scroll="setStagePositionValues"
      :data-testid="makeTestId('chat-stage')">
      <v-progress-linear
        v-if="loading"
        id="message-loader"
        height="25"
        color="primary"
        indeterminate
        rounded>
        <strong>Loading messages...</strong>
      </v-progress-linear>
      <div v-else>
        <div class="text-center font-size-xx-small text--color-neutral-80" v-if="assetVisit.id">
          The driver will receive and respond to your messages via SMS at the phone number provided
          during check-in.
        </div>
        <div v-for="(message, idx) in messageThreadRef.messages" :key="message.id">
          <drivers-chat-message
            :message="message"
            :idx="idx"
            :compact-mode="compactMode"
            :warehouse="warehouse"
            :message-thread="messageThreadRef"
            :assetVisit="assetVisit"></drivers-chat-message>
          <div
            v-for="event in makeNextEventItems(message)"
            :key="event.createDateTime"
            class="text-center font-size-xx-small text--color-neutral-80 mt-3 event-message">
            <strong>{{ makeChatTimestamp(event.createDateTime, warehouse) }}</strong>
            <br />
            {{ event.content }}
          </div>
        </div>
        <div
          v-if="isThreadExpired"
          class="text-center font-size-xx-small text--color-neutral-80 mt-3 event-message">
          <strong class="d-block">
            {{ makeChatTimestamp(messageThreadRef.expiresAt, warehouse) }}
          </strong>
          This chat expired. You can no longer send messages.
        </div>
      </div>
    </div>
    <footer v-if="messageThreadRef.isOpen" class="chat-footer px-6 py-5">
      <v-btn
        v-if="messageThreadRef.unreadMessagesCount && !isChatNearBottom"
        :data-testid="makeTestId('scroll-to-new-messages-btn')"
        text
        small
        class="new-messages-scroll-btn"
        @click="scrollToLatestMsg()">
        New Messages
        <v-icon small>mdi-arrow-down</v-icon>
      </v-btn>
      <div id="new-message-field">
        <v-form ref="newMessageFormRef" class="pt-0">
          <v-textarea
            v-model="newMessageInputValue"
            row-height="24"
            rows="1"
            auto-grow
            validate-on-blur
            outlined
            no-resize
            :data-testid="makeTestId('new-message-field')"
            class="mt-0"
            :counter="maxMessageLength"
            :rules="newMessageRulesRef"
            :disabled="!assetVisit || isThreadExpired"
            @keydown.enter.exact.prevent="handleMessageSubmit"
            dense
            :placeholder="isThreadExpired ? 'Message thread expired' : 'Message the driver...'">
            <template #prepend-inner>
              <v-img
                ref="attachedFileThumbRef"
                :data-testid="makeTestId('attached-file-preview')"
                v-if="attachedFile && attachedImageThumbSrc"
                id="attached-file"
                max-height="60"
                aspect-ratio="1"
                max-width="60"
                class="mt-1"
                :src="attachedImageThumbSrc"
                width="60">
                <v-icon
                  class="remove-attachment-icon"
                  @click="removeAttachedFile"
                  :data-testid="makeTestId('remove-file-icon')">
                  mdi-close-circle
                </v-icon>
              </v-img>
              <div
                v-else-if="attachedFile"
                class="attached-file-box pa-2 font-size-xx-small mt-1"
                :data-testid="makeTestId('attached-file-preview')">
                <div>
                  <generic-text-icon
                    :text="attachedFile.name.split('.').pop()"
                    class="py-2 px-1 mr-1"></generic-text-icon>
                </div>
                <div>
                  <div class="font-weight-black file-box-name mb-2">
                    {{ novaCore.truncateString(attachedFile.name, 16) }}
                  </div>
                  <div class="file-box-size">{{ Math.round(attachedFile.size / 1000) }}kb</div>
                </div>
                <v-icon
                  class="remove-attachment-icon"
                  @click="removeAttachedFile"
                  :data-testid="makeTestId('remove-file-icon')">
                  mdi-close-circle
                </v-icon>
              </div>
            </template>
            <template #append>
              <v-icon :data-testid="makeTestId('attach-file-icon')" @click="fileInputRef.click()">
                mdi-paperclip
              </v-icon>
            </template>
          </v-textarea>
        </v-form>
        <input
          type="file"
          ref="fileInputRef"
          class="d-none"
          :data-testid="makeTestId('file-input')"
          :accept="allowedFileTypes.join(',')"
          @change="handleFileChange" />
      </div>
      <v-btn
        fab
        text
        small
        :data-testid="makeTestId('send-message-btn')"
        :disabled="!newMessageInputValue && !attachedFile"
        :loading="sendingMessage"
        id="message-send-btn"
        class="ml-4"
        @click="handleMessageSubmit">
        <v-icon small>mdi-send</v-icon>
      </v-btn>
    </footer>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch, onBeforeUnmount, onBeforeMount } from 'vue';
import { useAssetVisit, useEventHub, useMessageThread, useNovaCore, useStore } from '@/composables';

export default {
  props: {
    assetVisit: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    compactMode: {
      type: Boolean,
      required: false,
      default: false
    },
    allowPhoneEdit: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['update:phone'],
  setup(props, context) {
    const assetVisit = ref(props.assetVisit);
    const {
      postLastReadMessage,
      isLastReadMessage,
      getIncomingMessages,
      sendMessage,
      makeChatTimestamp,
      isOutgoingMessage,
      toggleThreadOpenStatus,
      messageThreadRef,
      isSystemMessage,
      fetchMessageThread
    } = useMessageThread(assetVisit.value);
    const { updateAssetVisit } = useAssetVisit(assetVisit.value);
    const newMessageInputValue = ref('');
    const store = useStore();
    const novaCore = useNovaCore();
    const eventHub = useEventHub();
    const chatStageRef = ref(null);
    const isChatNearBottom = ref(false);
    const isPhoneEditMode = ref(false);
    const newAssetVisitPhone = ref(null);
    const changePhoneNumberFieldRef = ref(null);
    const fileInputRef = ref(null);
    const attachedFile = ref(null);
    const attachedFileThumbRef = ref(null);
    const attachedImageThumbSrc = ref(null);
    const allowedFileTypes = Object.values(novaCore.AllowedMessageThreadMediaMimeTypes);
    const sendingMessage = ref(false);
    const newMessageRulesRef = ref([
      v => v.length <= maxMessageLength || `Max ${maxMessageLength} characters`
    ]);
    const newMessageFormRef = ref(null);
    const maxMessageLength = novaCore.GlobalLimits.MAX_SMS_LENGTH.value;
    const isThreadExpired = ref(novaCore.isMessageThreadExpired(messageThreadRef.value));
    const messagesWithEvents = ref([]);
    const formResizeObserver = new ResizeObserver(() => {
      if (isChatNearBottom.value) {
        scrollToLatestMsg('auto');
      }
    });
    const loading = ref(true);

    const messageThreadEvents = ['create-MessageThread', 'update-MessageThread'];
    onBeforeMount(() => {
      eventHub.$on(messageThreadEvents, fetchMessageThread);
      eventHub.$on('create-MessageThreadMessage', handleMessageThreadMessageCreateEvent);
    });

    onMounted(async () => {
      if (props.assetVisit?.id) {
        await fetchMessageThread();
      }
      loading.value = false;
    });

    onBeforeUnmount(() => {
      eventHub.$off(messageThreadEvents, fetchMessageThread);
      eventHub.$off('create-MessageThreadMessage', handleMessageThreadMessageCreateEvent);
      formResizeObserver.disconnect();
    });

    const handleMessageThreadMessageCreateEvent = payload => {
      if (isSystemMessage(payload) || isOutgoingMessage(payload)) {
        fetchMessageThread();
      }
    };

    const setLastReadMessage = async () => {
      if (!messageThreadRef.value?.id) {
        return;
      }
      const incomingMessages = getIncomingMessages(messageThreadRef.value.messages);
      if (!incomingMessages?.length) {
        return;
      }
      const lastMessage = incomingMessages[incomingMessages.length - 1];
      if (
        !isLastReadMessage(
          lastMessage,
          messageThreadRef.value,
          messageThreadRef.value.lastReadMessageId
        )
      ) {
        postLastReadMessage(messageThreadRef.value, lastMessage.id);
      }
    };

    const scrollToLatestMsg = async (behavior = 'smooth') => {
      const chatStage = chatStageRef.value;
      if (!chatStage) {
        return;
      }
      await nextTick();
      setTimeout(() => {
        chatStage.scroll({
          top: chatStage.scrollHeight,
          behavior: behavior
        });
      }, 1);
    };

    const enablePhoneEditMode = async () => {
      isPhoneEditMode.value = true;
      await nextTick();
      if (changePhoneNumberFieldRef.value?.$refs?.telInput) {
        changePhoneNumberFieldRef.value.$refs.telInput.focus();
      }
    };

    const setStagePositionValues = () => {
      const scrollOffset =
        chatStageRef.value.scrollTop -
        (chatStageRef.value.scrollHeight - chatStageRef.value.offsetHeight);
      isChatNearBottom.value = scrollOffset > -500;
    };

    const makeMessagesWithEvents = () => {
      const nonReactiveMessages = JSON.parse(JSON.stringify(messageThreadRef.value.messages));
      const nonReactiveEvents = JSON.parse(JSON.stringify(messageThreadRef.value.events));
      const combinedMessages = [...nonReactiveMessages, ...nonReactiveEvents];
      messagesWithEvents.value = combinedMessages.sort(
        (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
      );
    };

    watch(
      () => messageThreadRef.value,
      async (newMessageThread, oldMessageThread) => {
        isThreadExpired.value = novaCore.isMessageThreadExpired(messageThreadRef.value);
        const oldThreadExists = oldMessageThread?.id;
        const hasNewEvents =
          oldThreadExists && newMessageThread.events?.length > oldMessageThread.events?.length;
        const hasNewMessages =
          oldThreadExists && newMessageThread.messages?.length > oldMessageThread.messages?.length;
        if (!oldThreadExists || hasNewEvents || hasNewMessages) {
          makeMessagesWithEvents();
        }
        if (!oldThreadExists) {
          await setLastReadMessage();
          await scrollToLatestMsg('auto');
        }
        if (oldThreadExists && hasNewMessages) {
          sendingMessage.value = false;
          await nextTick();
          setStagePositionValues();
          const latestMessage =
            messageThreadRef.value.messages[messageThreadRef.value.messages.length - 1];
          if (isOutgoingMessage(latestMessage)) {
            await scrollToLatestMsg();
          }
        }
        if (isChatNearBottom.value) {
          await scrollToLatestMsg();
        }
      },
      { deep: true }
    );

    watch(
      () => props.assetVisit,
      async () => {
        assetVisit.value = props.assetVisit;
        if (assetVisit.value.id) {
          fetchMessageThread();
        }
      }
    );
    const handleMessageSubmit = async () => {
      if (!newMessageFormRef.value.validate()) {
        return;
      }
      const prevMsgContent = newMessageInputValue.value;
      sendingMessage.value = true;
      const formData = new FormData();
      if (newMessageInputValue.value) {
        formData.append('content', newMessageInputValue.value);
      }
      formData.append('file', attachedFile.value);
      if (!newMessageInputValue.value && !attachedFile.value) {
        return;
      }
      newMessageInputValue.value = '';
      await sendMessage(messageThreadRef.value, formData)
        .catch(() => {
          newMessageInputValue.value = prevMsgContent;
        })
        .finally(() => {
          sendingMessage.value = false;
        });
      if (messageThreadRef.value.unreadMessagesCount > 0) {
        await setLastReadMessage();
      }
      removeAttachedFile();
    };

    const removeAttachedFile = () => {
      attachedFile.value = null;
      attachedImageThumbSrc.value = null;
      fileInputRef.value.value = '';
      fileInputRef.value.files = null;
    };

    const handleFileChange = async event => {
      const files = event.target.files;
      if (files.length > 0) {
        attachedFile.value = files[0];
        await nextTick();
        if (attachedFile.value.type.includes('image')) {
          attachedImageThumbSrc.value = URL.createObjectURL(files[0]);
        }
      }
    };

    const saveNewAssetVisitPhone = async () => {
      const newAssetVisit = await updateAssetVisit(assetVisit.value.id, {
        phone: newAssetVisitPhone.value
      });
      context.emit('update:phone', newAssetVisit.phone);
      isPhoneEditMode.value = false;
      eventHub.$emit('refresh-MessageThread');
    };

    const handleChatStatusClick = async () => {
      const text = messageThreadRef.value.isOpen
        ? "This chat will become read-only, and you'll no longer be able to send or receive messages."
        : 'This chat will be reactivated, allowing you to send and receive messages again.';
      const isConfirmed = await store.$app.$confirm(text, { color: 'warning' });
      if (isConfirmed) {
        await toggleThreadOpenStatus(messageThreadRef.value);
      }
    };

    const makeNextEventItems = (item, events = []) => {
      const itemIdx = messagesWithEvents.value.findIndex(
        i => i.createDateTime === item.createDateTime && i.content === item.content
      );
      if (itemIdx === -1 || itemIdx + 1 >= messagesWithEvents.value.length) {
        return events.length > 0 ? events : [];
      }

      const nextItem = messagesWithEvents.value[itemIdx + 1];
      if (nextItem && !nextItem.fromNumber && !events.includes(nextItem)) {
        events.push(nextItem);
        return makeNextEventItems(nextItem, events);
      } else {
        return events.length > 0 ? events : [];
      }
    };

    const makeTestId = (testId, prefix = 'drivers-chat') => {
      return `${prefix}-${testId}`;
    };

    watch(newMessageFormRef, () => {
      formResizeObserver.observe(newMessageFormRef.value.$el);
    });

    return {
      newMessageInputValue,
      handleMessageSubmit,
      chatStageRef,
      isChatNearBottom,
      scrollToLatestMsg,
      setStagePositionValues,
      isPhoneEditMode,
      newAssetVisitPhone,
      enablePhoneEditMode,
      changePhoneNumberFieldRef,
      saveNewAssetVisitPhone,
      handleFileChange,
      fileInputRef,
      attachedFile,
      attachedFileThumbRef,
      attachedImageThumbSrc,
      removeAttachedFile,
      allowedFileTypes,
      toggleThreadOpenStatus,
      sendingMessage,
      newMessageRulesRef,
      newMessageFormRef,
      maxMessageLength,
      isThreadExpired,
      makeChatTimestamp,
      novaCore,
      messagesWithEvents,
      makeNextEventItems,
      makeTestId,
      handleChatStatusClick,
      messageThreadRef,
      loading
    };
  }
};
</script>

<style scoped lang="scss">
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  .chat-header {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    background-color: $white;
    border-bottom: 1px solid $color-neutral-20;

    .edit-phone-btn {
      color: $color-text-link;
      text-transform: none;
      min-width: auto;
    }
  }

  .chat-stage {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
    align-content: end;
    background-color: $color-neutral-10;
  }

  .chat-footer {
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    position: relative;
    align-items: flex-end;
    background-color: #ffffff;
    border-top: 1px solid $color-neutral-20;
  }

  &.compact-mode {
    .chat-header {
      padding-top: 0 !important;
      padding-bottom: 12px !important;
    }
  }
}

.country-code-warning {
  vertical-align: top;
  color: $color-text-error;
}

#message-send-btn {
  background-color: $color-neutral-0;
  color: $color-neutral-90;

  &:disabled {
    background-color: $color-neutral-20;
  }
}

::v-deep #new-message-field {
  flex: 1;

  .v-input__slot {
    flex-direction: column;
  }

  #attached-file {
    border-radius: 12px;
    overflow: visible;

    .v-image__image {
      border-radius: 8px;
    }
  }

  .v-input__append-inner {
    position: absolute;
    right: 10px;
    bottom: 8px;
  }

  textarea {
    padding-right: 35px;
  }

  .v-text-field__details {
    position: absolute;
    right: 0;
    bottom: -20px;
  }
}

.remove-attachment-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  color: black;
  z-index: 1;
}

.attached-file-box {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 12px;
  background-color: $color-neutral-20;
  max-height: 60px;
  min-width: 60px;
  max-width: 144px;
  color: $color-neutral-90;
}

.new-messages-scroll-btn {
  position: absolute;
  left: 50%;
  top: -15px;
  transform: translateX(-50%);
  z-index: 1;
  background-color: $color-primary-60;
}

::v-deep #change-phone-number-field {
  max-width: 185px;
  max-height: 36px;
  border: solid 1px rgba(146, 146, 146, 0.7);
  border-radius: 4px;

  > .v-input {
    border-radius: 0 4px 4px 0;

    fieldset {
      border: none;
    }

    input {
      padding-top: 11px;
    }
  }

  .v-text-field__details {
    display: none !important;
  }

  .country-code {
    width: auto;
    position: relative;
    max-width: 30px;

    fieldset {
      border: none;
    }

    .v-input {
      width: 100%;
      min-width: 100%;
      position: relative;
      border-radius: 4px 0 0 4px;
    }

    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
</style>
