<template>
  <div :class="`appointments-page mode-${$mode}`" data-testid="appointments-page">
    <v-container fluid>
      <v-card class="appointments-page-header">
        <v-row class="ma-1 pa-1">
          <v-col sm="12" md="12" lg="3" xl="2" class="col-xs-12" xs="12">
            <v-autocomplete
              ref="warehouse-select"
              class="appointments-page-warehouse-select"
              data-testid="appointments-page-warehouse-select"
              item-value="id"
              item-text="name"
              placeholder="Select a warehouse"
              no-filter
              return-object
              auto-select-first
              flat
              solo
              hide-details
              v-model="selectedWarehouse"
              @blur="handleWarehouseBlur"
              @change="handleWarehouseSelect"
              :loading="loading.warehouses"
              :clearable="false"
              :items="warehouses"
              :search-input.sync="filters.searchStr"
              :item-disabled="isWarehouseDisabled">
              <template #selection="{ item }">
                <span
                  data-testid="appointments-page-warehouse-selected"
                  class="d-inline-block text-truncate appointments-page-warehouse-select-item"
                  style="max-width: 36ch">
                  {{ formatWarehouseItem(item) }}
                </span>
              </template>
              <template #item="{ item }">
                <span
                  class="d-inline-block text-truncate appointments-page-warehouse-select-item"
                  style="max-width: 36ch">
                  {{ formatWarehouseItem(item) }}
                </span>
              </template>
              <template #append-item>
                <div
                  v-intersect="onIntersect"
                  data-testid="appointment-page-warehouse-select-intersector"
                  :options="{ threshold: [0, 0.5, 1.0] }"></div>
                <template v-if="loading.warehouses && warehouses.length < warehouseTotal">
                  <v-divider></v-divider>

                  <v-container class="v-list-item">
                    <v-row class="fill-height" align-content="center" justify="center">
                      <v-col class="text-subtitle-2 text-center pa-0" cols="12">
                        Loading More Warehouses...
                      </v-col>
                      <v-col cols="12">
                        <v-progress-linear indeterminate class="py-0"></v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="12" md="1" lg="1" xl="2" class="d-flex align-center view-container col-xs-12">
            <div class="d-flex align-center appointments-page-filter-label">
              <span>View:</span>
            </div>
          </v-col>
          <v-col
            sm="6"
            offset-md="0"
            offset-lg="0"
            md="3"
            lg="2"
            class="d-flex align-center col-xs-12">
            <dock-select
              ref="dock-select"
              class="appointments-page-docks-select"
              data-testid="appointments-page-docks-select"
              hide-icon
              filter-mode
              multi-select
              flat
              solo
              v-model="selectedDocks"
              :selected-warehouse="selectedWarehouse"
              :class="{ 'no-items': !$selectedDocks || !$selectedDocks.length }"
              :dense="false"
              :clearable="false"
              :is-flex="false"
              :docks="warehouseDocks"
              :disabled="!selectedWarehouse?.id">
              <template #selection="{ index }">
                <div data-testid="appointments-page-docks-selection" v-if="index === 0">
                  <div v-if="allDocksSelected">
                    <span class="filter-count">All</span>
                    Docks
                  </div>
                  <div v-else>
                    <span class="filter-count">{{ selectedDocks?.length ?? 0 }}</span>
                    &nbsp;
                    {{ novaCore.pluralize(selectedDocks?.length, 'Dock') }}
                  </div>
                </div>
                <div class="d-none" v-else></div>
              </template>

              <template #label v-if="!selectedDocks || !selectedDocks.length">
                <span data-testid="appointments-page-docks-no-docks" class="filter-count">0</span>
                Docks
              </template>
            </dock-select>
          </v-col>
          <v-col sm="6" md="3" lg="2" class="d-flex align-center col-xs-12">
            <load-type-select
              ref="loadtype-select"
              class="appointments-page-loadtype-select"
              data-testid="appointments-page-loadtype-select"
              hide-icon
              solo
              flat
              v-model="selectedLoadtypes"
              :class="{ 'no-items': !selectedLoadtypes || !selectedLoadtypes.length }"
              :dense="false"
              :disabled="isLoadTypeDisabled"
              :selected-warehouse="selectedWarehouse"
              :selected-docks="warehouseDocks"
              :include-durations="false"
              :multi-select="true"
              :return-object="false"
              :select-all="true"
              :clearable="false">
              <template #label v-if="!selectedLoadtypes || !selectedLoadtypes.length">
                <span data-testid="appointments-page-loadtype-no-loadtype" class="filter-count">
                  0
                </span>
                Load Types
              </template>

              <template #selection="{ index }">
                <div data-testid="appointments-page-loadtype-selection" v-if="index === 0">
                  <div v-if="allLoadTypesSelected">
                    <span class="filter-count">All</span>
                    Load Types
                  </div>
                  <div v-else>
                    <span class="filter-count">{{ selectedLoadtypes?.length ?? 0 }}</span>
                    &nbsp;
                    {{ novaCore.pluralize(selectedLoadtypes?.length, 'Load Type') }}
                  </div>
                </div>
                <div class="d-none" v-else></div>
              </template>
            </load-type-select>
          </v-col>
          <v-col sm="6" md="3" lg="2" class="d-flex align-center col-xs-12">
            <status-select
              ref="status-select"
              class="appointments-page-status-select"
              data-testid="appointments-page-status-select"
              hide-icon
              flat
              solo
              v-model="selectedStatuses"
              :class="{ 'no-items': !selectedStatuses || !selectedStatuses.length }"
              :clearable="false"
              :dense="false"
              :extra-statuses="['Reserves']"
              :statuses-sort-comparator="statusesSortComparator">
              <template #label v-if="!selectedStatuses || !selectedStatuses.length">
                <span data-testid="appointments-page-statuses-no-status">
                  <span class="filter-count">0</span>
                  Statuses
                </span>
              </template>
              <template #selection="{ index }">
                <div data-testid="appointments-page-statuses-selection" v-if="index === 0">
                  <div v-if="allStatusesSelected">
                    <span class="filter-count">All</span>
                    Statuses
                  </div>
                  <div v-else>
                    <span class="filter-count">{{ selectedStatuses?.length ?? 0 }}</span>
                    &nbsp;
                    {{ novaCore.pluralize(selectedStatuses?.length, 'Status', 'Statuses') }}
                  </div>
                </div>
                <div class="d-none" v-else></div>
              </template>
            </status-select>
          </v-col>
          <v-col sm="6" md="2" lg="2" class="col-xs-12 d-flex justify-end">
            <feature-flag name="enable-checkin-panel-redesign">
              <template #enabled>
                <check-in-notification-panel
                  class="appointments-page-check-in-notification-panel"
                  :warehouse="selectedWarehouse"
                  :show-advertisement="shouldShowAdvertisement"
                  :org="$org"></check-in-notification-panel>
              </template>
              <template #disabled>
                <check-in-notification-panel-old
                  class="appointments-page-check-in-notification-panel"
                  :warehouse="selectedWarehouse"
                  :show-advertisement="shouldShowAdvertisement"
                  :org="$org"></check-in-notification-panel-old>
              </template>
            </feature-flag>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="appointments-page-header-calendar d-flex align-center justify-space-between">
          <div class="d-flex align-center calendar-filter-container">
            <div class="d-flex align-center calendar-date-actions">
              <v-menu offset-y internal-activator close-on-click v-if="!listMode">
                <template #activator="{ on, attrs }">
                  <v-tooltip bottom>
                    <template #activator="{ on: tooltipOn }">
                      <div class="calendar-date-interval" v-on="tooltipOn">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="calendar-gutter-btn"
                          :ripple="false">
                          <v-icon data-testid="interval-select-button-icon">
                            mdi-timeline-clock-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      {{
                        listMode
                          ? 'Grid time interval granularity selection disabled in list view'
                          : 'Select grid time interval granularity'
                      }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in intervalOptions" :key="index">
                    <button-base
                      small
                      text
                      block
                      class="text-left"
                      @click="setIntervalMinutes(item)">
                      <span class="flex-grow-1 justify-start">{{ item }} minutes</span>
                    </button-base>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn class="calendar-date-today" outlined color="#273038" @click="setToday">
                Today
              </v-btn>
              <div class="calendar-date-step" data-testid="calendar-date-step">
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="previousDate"
                  data-testid="grid-prev-period">
                  <v-icon small>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="nextDate"
                  data-testid="grid-next-period">
                  <v-icon small>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>

            <div class="calendar-label" data-testid="grid-calendar-select">
              <v-menu offset-y internal-activator :close-on-content-click="false">
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" class="calendar-date-select" :ripple="false">
                    <v-icon>mdi-calendar</v-icon>
                    <span class="calendar-date-label" data-testid="calendar-date-label">
                      {{ selectedDateLabel }}
                    </span>
                  </v-btn>
                </template>
                <v-date-picker
                  ref="date-picker"
                  color="accent"
                  type="date"
                  v-model="selectedDate"></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="d-flex align-center calendar-filter-container">
            <v-btn-toggle v-model="viewType" mandatory>
              <v-btn
                class="view-type"
                outlined
                color="darken-2"
                v-for="type in availableViewTypes"
                :data-testid="`calendar-view-type-${type}`"
                :value="type"
                :key="`calendar-${type}`">
                {{ type }}
              </v-btn>
            </v-btn-toggle>
            <div class="menu-container">
              <v-menu offset-y internal-activator close-on-click>
                <template #activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="darken-2"
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    data-testid="grid-view-mode-menu-activator">
                    <v-icon small data-testid="view-mode-activator">
                      {{ getViewModeMeta().icon }}
                    </v-icon>
                    <span>
                      {{ getViewModeMeta().label }}
                      <v-icon small class="ml-2">mdi-chevron-down</v-icon>
                    </span>
                  </v-btn>
                </template>
                <v-list class="plain-items" data-testid="view-mode-options">
                  <v-list-item
                    class="is-relative z-100"
                    v-for="(item, key) in viewModes"
                    :key="key">
                    <button-base
                      :data-testid="`${item}-view-button`"
                      text
                      plain
                      :ripple="false"
                      class="d-flex align-center justify-center"
                      @click="mode = item">
                      <v-icon small>{{ getViewModeMeta(item).icon }}</v-icon>
                      <span class="flex-grow-1 justify-start font-size-x-small">
                        {{ getViewModeMeta(item).label }}
                      </span>
                    </button-base>
                  </v-list-item>
                  <v-list-item class="is-relative z-100">
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <button-base
                          data-testid="tv-mode-button"
                          text
                          plain
                          :ripple="false"
                          class="d-flex align-center justify-center"
                          @click="viewTvMode">
                          <v-icon small>mdi-television-play</v-icon>
                          <span class="flex-grow-1 justify-start font-size-x-small">
                            Open TV Mode
                          </span>
                        </button-base>
                      </template>
                      <span>TV Mode will open a new browser window</span>
                    </v-tooltip>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    class="is-relative z-100"
                    :class="{
                      'active-highlight': $shouldShowWeekends,
                      disabled: isWeekdayOptionDisabled
                    }">
                    <button-base
                      data-testid="weekend-toggle-button"
                      text
                      plain
                      :ripple="false"
                      class="d-flex align-center justify-center"
                      @click="toggleWeekendVisibility">
                      <v-icon small>
                        {{
                          $shouldShowWeekends ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
                        }}
                      </v-icon>
                      <span class="flex-grow-1 justify-start font-size-x-small">Show weekends</span>
                    </button-base>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </v-card>
    </v-container>
    <div
      :class="`calendar-wrapper is-relative ${useLightGridTheme ? 'light-grid-theme' : ''}`"
      v-if="$mode === 'grid'">
      <appointments-loader
        data-testid="grid-loader"
        :is-loading="loading.appointments"></appointments-loader>
      <component
        v-if="isMounted"
        :are-events-ready="isMounted"
        :is="calendarComponent"
        :docks="selectedDocks"
        @is-dock-capacity-toggle="setIsDockCapacityToggle"
        @set-loader="val => (loading.appointments = val)"
        :events="warehouseEventsByDock"></component>
      <v-row class="px-6">
        <v-col>
          <v-switch
            class="mt-0"
            v-model="useLightGridTheme"
            color="secondary"
            label="Light Grid Theme"></v-switch>
        </v-col>
        <v-col>
          <calendar-legend class="text-right"></calendar-legend>
        </v-col>
      </v-row>
      <appts-missing-selection-overlay
        :is-visible="shouldShowNoSelectionOverlay"
        :warehouse="selectedWarehouse?.id"
        :docks="selectedDocks"
        :load-types="selectedLoadtypes"
        :statuses="selectedStatuses"></appts-missing-selection-overlay>
    </div>
    <div class="is-relative appointments-list-container" v-else>
      <appointments-list
        custom-header
        ref="appointments-list"
        :is-loading="loading.appointments"
        :appointments="filterWithinSelectedRange(warehouseEventsByDock)"
        :filter-locally="true">
        <template #table-header="{ filters, columns, rows, exporter }">
          <div class="d-flex align-center">
            <v-text-field
              dense
              flat
              outlined
              single-line
              hide-details
              class="appointments-list-search-input mr-3"
              placeholder="Find in this table…"
              prepend-inner-icon="mdi-magnify"
              height="24"
              v-model="filters.searchStr"></v-text-field>
            <p class="appointments-list-search-count" data-testid="appointments-list-search-count">
              <span class="filter-count">
                {{ rows.length ?? 0 }}
              </span>
              <span>
                {{ novaCore.pluralize(rows.length ?? 0, 'appointment') }}
              </span>
            </p>
          </div>
          <div class="d-flex align-center">
            <generic-multi-select
              class="appointments-list-columns mr-3"
              item-text="text"
              item-value="value"
              label="Select table columns"
              refKey="columnSelect"
              append-icon="mdi-chevron-down"
              flat
              solo
              multiple
              hide-details
              return-object
              persistent-hint
              @input="columns.update"
              :clearable="false"
              :auto-select="columns.autoSelect"
              :items="columns.available"
              :minimum-selections="1"
              :value="columns.selected"
              :visible-selection-count="3">
              <template #selection="{ index }">
                <span v-if="index === 0">
                  Select table columns ({{ columns.selected?.length ?? 0 }})
                  <v-icon small></v-icon>
                </span>
                <span v-else></span>
              </template>
            </generic-multi-select>
            <v-btn @click="exporter.printTable" text class="mr-3">
              <v-icon small class="mr-4">mdi-printer</v-icon>
              Print
            </v-btn>
            <primary-button
              data-testid="export-to-excel"
              before-icon="microsoft-excel"
              :loading="exporter.buildingExcelData"
              @click="exporter.exportExcel(rows, exporter.excelFileName)">
              Export
            </primary-button>
          </div>
        </template>
      </appointments-list>
      <appts-missing-selection-overlay
        :is-visible="shouldShowNoSelectionOverlay"
        :warehouse="selectedWarehouse?.id"
        :docks="selectedDocks"
        :load-types="selectedLoadtypes"
        :statuses="selectedStatuses"></appts-missing-selection-overlay>
    </div>
  </div>
</template>

<script lang="js">
import * as _ from 'lodash';
import moment from 'moment-timezone';

import { CalendarViewEnum } from '@/enums';
import { AppointmentStatus } from '@satellite/../nova/core';
import { viewModes } from '@/modules/calendar/store';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import calendarMixin from '@/modules/calendar/mixins/calendarMixin';
import localStorageMixin from '@satellite/components/mixins/localStorageMixin';
import appointmentsPageMixin from '@/components/mixins/appointmentsPageMixin';
import storageHelpers from '@satellite/helpers/storage';
import { isDefined } from 'class-validator';

const statusesOrder = [
  'Reserves',
  AppointmentStatus.Cancelled,
  AppointmentStatus.Requested,
  AppointmentStatus.Scheduled,
  AppointmentStatus.Arrived,
  AppointmentStatus.NoShow,
  AppointmentStatus.InProgress,
  AppointmentStatus.Completed,
]

/**
 * Appointments page
 * @displayName Appointments Page V2
 */
export default {
  mixins: [calendarMixin, localStorageMixin, appointmentsPageMixin, dataListMixin],
  async mounted() {
    await this.fetchWarehouses();

    // When the component loads, it always loads the first page (15),
    // in case the selected warehouse is not at the first page, we do inject
    // into the warehouse list, so it can be selected automatically
    // when page starts.
    if (this.$selectedWarehouse?.id) {
      const selectedWarehouseIsOnList = Boolean(this.warehouses.find(w => w.id === this.$selectedWarehouse.id));
      const hasSelection = Boolean(this.$selectedWarehouse?.id);

      if (hasSelection && !selectedWarehouseIsOnList) {
        this.warehouses.unshift(this.$selectedWarehouse);
      }
    }

    this.$nextTick(() => {
      this.initializeData();
      this.isMounted = true;
    })
  },
  data() {
    return {
      isMounted: false,
      isDockCapacityToggle: false,
      warehouses: [],
      sortBy: ['name'],
      searchFields: ['name', 'facilityNumber'],
      intervalOptions: [15, 20, 30],
      loading: {
        warehouses: false,
        appointments: false,
      },
      filters: {
        searchStr: null,
      },
      options: {
        page: 1,
        itemsPerPage: 10
      },
      warehouseTotal: 0,
      pageCount: 0,
    };
  },
  computed: {
    isLoadTypeDisabled() {
      return !this.selectedWarehouse || _.isEmpty(this.selectedWarehouse) || !this.selectedDocks?.length
    },
    allDocksSelected() {
      return this.isMounted && this.$refs['dock-select']?.allItemsSelected;
    },
    allLoadTypesSelected() {
      return this.isMounted && this.$refs['loadtype-select']?.allItemsSelected;
    },
    allStatusesSelected() {
      return this.isMounted && this.$refs['status-select']?.allItemsSelected;
    },
    hasWarehouseGeolocationSet() {
      return isDefined(this.selectedWarehouse.geolocation?.latitude) && isDefined(this.selectedWarehouse.geolocation?.longitude);
    },
    calendarDataKey() {
      return storageHelpers.makeUserBoundedKey('nova_calendar', this.$me.id);
    },
    mode: {
      get() {
        return this.$store.state.Calendar.mode;
      },
      set(value) {
        this.$store.dispatch('Calendar/setMode', { value });
      }
    },
    listMode() {
      return this.mode === viewModes.list;
    },
    gridMode() {
      return this.mode === viewModes.grid;
    },
    selectedDate: {
      get() {
        return this.$selectedDate
      },
      set(value) {
        this.$store.dispatch('Calendar/setSelectedDate', {
          value,
          getEvents: true
        });
      }
    },
    viewType: {
      get() {
        return this.$viewType;
      },
      set(type) {
        this.$store.dispatch('Calendar/setViewType', { value: type, getEvents: true });
      }
    },
    selectedDateLabel() {
      if (!this.isMounted) {
        return '';
      }

      const date = moment(this.selectedDate);

      if (this.viewType === 'WEEK') {
        const start = date.clone().startOf('week')
        const end = date.clone().endOf('week')
        const startMonth = start.format(this.novaCore.DateTimeFormats.ShortMonth);
        const endMonth = end.format(this.novaCore.DateTimeFormats.ShortMonth);
        const isSameMonth = startMonth === endMonth;
        const startYear = start.format(this.novaCore.DateTimeFormats.FullYear);
        const endYear = end.format(this.novaCore.DateTimeFormats.FullYear);
        const isSameYear = startYear === endYear;
        const startDay = start.format(this.novaCore.DateTimeFormats.FullDayOfMonth);
        const endDay = end.format(this.novaCore.DateTimeFormats.FullDayOfMonth);

        return `${startMonth} ${startDay} ${!isSameYear ? `, ${startYear}` : ''} - ${
          !isSameMonth ? endMonth : ''
        } ${endDay}, ${endYear}`;
      }

      const format = this.viewType === 'MONTH'
        ? this.novaCore.DateTimeFormats.FullMonthYear
        : 'dddd, MMMM Do, YYYY';

      return date.format(format);
    },
    availableViewTypes() {
      return Object.keys(CalendarViewEnum).map(type => {
        return type;
      });
    },
    viewModes() {
      return viewModes;
    },
    viewTypes() {
      return CalendarViewEnum;
    },
    isWeekdayOptionDisabled() {
      return (
        this.mode === this.viewModes.list || this.viewType.toLowerCase() === this.viewTypes.DAY
      );
    },
    accessToken() {
      return sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    },
    calendar() {
      return this.$calendar;
    },
    appointmentsListTotal() {
      return this.isMounted ? this.$refs['appointments-list']?.displayTotal : null;
    },
    shouldShowAdvertisement() {
      return !this.hasWarehouseGeolocationSet;
    }
  },
  methods: {
    async fetchWarehouses(shouldSetWarehouses = true) {
      const query = this.getQueryBase();
      let warehouses = [];

      if (this.$me.warehouseAccessList) {
        query.s = { ...query.s, id: { $in: this.$me.warehouseAccessList } };
      }

      this.loading.warehouses = true;
      try {
        const response = await this.services.warehouse.getWarehouses(query, {
          ...this.services.warehouse.requestOptions.grid,
          includeMetaData: true
        });
        warehouses = response?.data?.data ?? [];
        this.warehouseTotal = response.data.total;
        this.pageCount = response.data.pageCount;
      } catch (err) {
        warehouses = [];
      } finally {
        this.loading.warehouses = false;
      }
      if (shouldSetWarehouses) {
        this.warehouses = warehouses;
      }
      return warehouses;
    },
    initializeData() {
      const warehouse = this.warehouses?.find(w => w.id === this.selectedWarehouse?.id) || this.warehouses?.[0];
      if (warehouse && !_.isEmpty(warehouse)) {
        this.selectedWarehouse = warehouse
        this.filters.searchStr = null;
      }
    },
    formatWarehouseItem(warehouse) {
      if (!warehouse) {
        return null;
      }
      const dockCount = this.getWarehouseDockCount(warehouse);
      const dockNoun = dockCount !== 1 ? 'docks' : 'dock';
      const displayName = `${warehouse.name} (${dockCount > 0 ? dockCount : 'No'} ${dockNoun})${warehouse.facilityNumber ? ` #${warehouse.facilityNumber}` : ''}`;
      return displayName;
    },
    debounceSearch: _.debounce(async function() {
      await this.fetchWarehouses();
    }, 350),
    isWarehouseDisabled(warehouse) {
      if (warehouse.id === this.selectedWarehouse?.id) {
        return false;
      }
      return this.getWarehouseDockCount(warehouse) < 1;
    },
    getWarehouseDockCount(warehouse) {
      return warehouse
        ?.docks
        ?.filter
        ?.(dock => dock.isActive && !this.novaCore.isCapacityChild(dock))
        ?.length ?? 0;
    },
    statusesSortComparator(statusA, statusB) {
      return statusesOrder.indexOf(statusA) - statusesOrder.indexOf(statusB);
    },
    setIntervalMinutes(intervalMinutes) {
      this.$store.dispatch('Calendar/setIntervalMinutes', intervalMinutes);
    },
    setToday() {
      const today = this.$selectedWarehouse?.timezone
        ? moment().tz(this.$selectedWarehouse.timezone)
        : moment();
      const adjustedDate =
        this.viewType.toUpperCase() === 'WEEK' &&
        !this.$shouldShowWeekends &&
        today.isoWeekday() > 5
          ? today.add(1, 'weeks').isoWeekday(1).format(this.novaCore.DateTimeFormats.DateDashed)
          : today.format(this.novaCore.DateTimeFormats.DateDashed);
      this.$store.dispatch('Calendar/setSelectedDate', {
        value: adjustedDate,
        getEvents: true
      });
    },
    previousDate() {
      if (this.gridMode && this.calendar) {
        return this.calendar.prev();
      }
      const date = moment(this.$selectedDate).startOf(this.viewType).subtract(1, this.viewType);
      this.selectedDate = date.format(this.novaCore.DateTimeFormats.DateDashed);
    },
    nextDate() {
      if (this.gridMode && this.calendar) {
        return this.calendar.next();
      }
      const date = moment(this.$selectedDate).startOf(this.viewType).add(1, this.viewType);
      this.selectedDate = date.format(this.novaCore.DateTimeFormats.DateDashed);
    },
    getViewModeMeta(mode) {
      let icon = 'mdi-grid';
      let label = 'View';
      switch (mode ?? this.$mode) {
        case viewModes.grid:
          icon = 'mdi-grid';
          label = `Grid ${label}`;
          break;
        case viewModes.list:
          icon = 'mdi-format-list-checkbox';
          label = `List ${label}`;
          break;
      }
      return { icon, label };
    },
    toggleWeekendVisibility() {
      if (this.isWeekdayOptionDisabled) {
        return;
      }
      if (this.viewType.toUpperCase() === 'WEEK' && this.$shouldShowWeekends) {
        const selectedDate = moment(this.selectedDate);
        if (selectedDate.isoWeekday() > 5) {
          this.$store.dispatch('Calendar/setSelectedDate', {
            value: selectedDate
              .add(1, 'weeks')
              .isoWeekday(1)
              .format(this.novaCore.DateTimeFormats.DateDashed),
            getEvents: true
          });
        }
      }

      this.$store.dispatch('Calendar/setShouldShowWeekends', {
        value: !this.$shouldShowWeekends,
        getEvents: false
      });
    },
    viewTvMode() {
      if (!this.$selectedDockIds) {
        return
      }
      const routeData = this.$router.resolve({
        name: 'wormhole.loginAs',
        params: {
          accessToken: this.accessToken
        },
        query: {
          nextRoute: 'appointmentsTV',
          warehouseIdParam: this.$selectedWarehouse.id,
          dockIdsParam: this.$selectedDockIds
        }
      });
      window.open(routeData.href, '_blank');
    },
    setIsDockCapacityToggle(isDockCapacityToggle) {
      this.isDockCapacityToggle = isDockCapacityToggle;
    },
    filterWithinSelectedRange(appts) {
      // Filter locally, since the full appointments list may have
      // appointments outside of the range. The calendar grid is already handling that,
      // but list view isn't.
      const unit = this.viewType.toLowerCase();
      const warehouseTimezone = this.selectedWarehouse?.timezone;

      const momentBase = warehouseTimezone
        ? moment(this.$selectedDate).tz(warehouseTimezone, true).startOf(unit)
        : moment(this.$selectedDate).startOf(unit);

      const startDate = momentBase.clone().toISOString();
      const endDate = momentBase.clone().add(1, `${unit}s`).toISOString();

      return appts.filter(appt => {
        const apptStartMoment = moment(appt.start).tz(warehouseTimezone, true);
        return apptStartMoment.isSameOrAfter(startDate) && apptStartMoment.isSameOrBefore(endDate);
      });
    },
    async initializeLoadTypes(previousWarehouseId) {
      const selectedLoadtypes = this.getStoredCalendarData()?.selectedLoadtypes ?? [];
      const { data: loadTypeData = {} } = await axios.get('loadtype', { warehouseId: this.selectedWarehouse.id }) || {};
      const docksLoadTypes = this.selectedDocks?.flatMap(dock => dock.loadTypeIds) ?? [];
      const warehouseLoadTypes = loadTypeData?.data?.map(lt => lt.id) ?? [];
      const allLoadTypes = _.uniq(_.intersection(docksLoadTypes, warehouseLoadTypes));
      const storedLoadtypes = _.intersection(allLoadTypes, selectedLoadtypes);
      this.selectedLoadtypes = !previousWarehouseId && !_.isEmpty(storedLoadtypes)
        ? storedLoadtypes
        : allLoadTypes;
    },
    handleWarehouseSelect() {
      this.filters.searchStr = null;
    },
    handleWarehouseBlur() {
      this.filters.searchStr = null;
      this.$refs['warehouse-select']?.setValue(this.selectedWarehouse);
    },
    /**
     * Increment pagination page and fetch more warehouses
     * @returns {Promise<void>}
     */
    async loadMoreWarehouses() {
      if (this.pageCount > 0 && this.options.page < this.pageCount) {
        if (!this.options.page) {
          this.options.page = 2;
        } else {
          this.options.page++;
        }
        const moreWarehouses = await this.fetchWarehouses(false);
        moreWarehouses.forEach(w => this.warehouses.push(w));
      }
    },
    /**
     * When element placed at end of warehouse select is intersecting, fetch more warehouses if more exist
     * @param entries
     * @param observer
     * @param isIntersecting
     * @returns {Promise<void>}
     */
    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.warehouses.length < this.warehouseTotal) {
        await this.loadMoreWarehouses();
      }
    },
  },
  beforeMount() {
    this.setStoredCalendarData();
  },
  watch: {
    async 'filters.searchStr'() {
      await this.debounceSearch();
    },
    async selectedWarehouse(newValue, oldValue) {
      if (oldValue?.id && newValue.id === oldValue.id) {
        return;
      }

      await this.initializeLoadTypes(oldValue?.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
@import '../../styles/partials/variables';

.appointments-page.mode-grid {
  div.container:first-of-type {
    padding: 12px 1px;
    margin-bottom: 36px;
  }
}

.appointments-page.mode-list {
  height: 100%;

  .appointments-list-container {
    height: 100%;
    margin: 0 12px;

    :deep(.appointment-list) > .v-card__title > .row {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;

      .appointments-list-columns {
        max-width: 325px;
      }
    }
  }
}

.calendar-date-label {
  text-transform: capitalize !important;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .appointments-page-header {
    .row {
      flex-direction: row;
      gap: 8px;
    }

    .col-xs-12 {
      flex: 0 0 calc(100% - 24px);
      width: calc(100% - 24px);
    }

    .appointments-page-docks-select {
      width: 100%;
    }

    .view-container {
      margin-top: -12px;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .appointments-page-header {
    .row {
      gap: 12px;
      padding-left: 8px !important;
      margin-right: -4px !important;
    }

    .col-sm-6 {
      flex: 0 0 calc(50% - 12px);
      width: calc(50% - 12px);
      padding: 0;
    }

    .view-container > div {
      width: 100%;
      justify-content: flex-start;
      padding: 2px 0;
    }

    .appointments-page-docks-select {
      width: 100%;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .appointments-page-header {
    padding: 8px 0;

    .row {
      align-items: center;
      padding-right: 24px !important;
      gap: 0px !important;
    }

    .col {
      padding: 0 8px;
    }

    .view-container {
      div {
        justify-content: flex-start;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .appointments-page-header {
    .row {
      gap: 0px !important;
    }

    .col {
      padding: 0 8px !important;
    }

    .row {
      align-items: center;
    }

    .view-container {
      div {
        justify-content: flex-end;
      }
    }
  }
}

.appointments-page-header {
  padding: 0;

  .row,
  .v-sheet {
    margin: 0 !important;
    padding: 16px !important;
  }
}

.appointments-page-header-calendar {
  width: 100%;
  padding: 16px;

  .calendar-filter-container {
    gap: 16px;

    .calendar-date-actions {
      gap: 16px;
    }

    .calendar-gutter-btn {
      width: fit-content;
      min-width: 0 !important;
      color: #585f66;

      :deep(.v-btn__content) {
        flex-grow: initial;
        width: fit-content;
      }
    }

    .view-type {
      height: 36px !important;

      &.v-item--active {
        background: var(--accent-accent-60, #005a87);
        border: 1px solid var(--line-divider, #d8e3e8);
        color: var(--text-text-secondary-inverted, $color-background-tertiary);
      }
    }
  }

  .calendar-date-select {
    border-radius: 4px;
    color: $color-neutral-90 !important;
    padding: 12px;
    width: unset;

    :hover {
      background-color: $color-background-tertiary;
    }

    :deep(.v-btn__content) {
      display: flex;
      gap: 8px;
      text-transform: unset;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .appointments-page-header-calendar {
    .calendar-filter-container {
      width: 50%;
      flex-direction: column;

      .calendar-date-select {
        padding-left: 0;
        order: -1 !important;
      }

      &:first-of-type {
        align-items: flex-start !important;
      }

      &:last-of-type {
        align-items: flex-end !important;
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .appointments-page-header-calendar {
    flex-direction: column;

    .calendar-filter-container.calendar-filter-container {
      width: 100%;
      flex-direction: column;
      align-items: flex-start !important;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.appointments-page-header,
.appointments-page-warehouse-select-item {
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}

.appointments-page-filter-label {
  height: 100%;
  font-weight: 600;
}

:deep(.appointments-page-warehouse-select > .v-input__control > .v-input__slot) {
  padding: 0 !important;
}

.appointments-page-warehouse-select:hover,
.appointments-page-warehouse-select > .v-input__control:hover,
.appointments-page-warehouse-select > .v-input__control > .v-input__slot:hover {
  background-color: $color-background-tertiary;
  cursor: pointer;
}

.appointments-page-docks-select > :deep(.dock-select),
.appointments-page-loadtype-select > :deep(.loadtype-select),
.appointments-page-status-select > :deep(.status-select) {
  .v-label {
    height: unset;
    line-height: 1;
  }

  &,
  input {
    cursor: pointer;
  }

  .v-input__slot,
  .v-label,
  .v-icon__svg,
  .v-input__control {
    background-color: $color-background-tertiary !important;
    cursor: pointer;
  }
}

.appointments-page-docks-select.no-items > :deep(.dock-select),
.appointments-page-loadtype-select.no-items > :deep(.loadtype-select),
.appointments-page-status-select.no-items > :deep(.status-select) {
  .v-input__slot,
  .v-label,
  .v-icon__svg,
  .v-input__control {
    background-color: #ffebee !important;
    color: #d50000;
  }
}

.filter-count {
  font-weight: 600;
}

.appointments-page-loadtype-select.appointments-page-loadtype-select,
.appointments-page-status-select.appointments-page-status-select {
  margin: 0 !important;
}

.appointments-list-search-input {
  :deep(.v-input__slot) {
    height: 32px !important;
    min-height: 32px !important;
  }

  :deep(.v-text-field__slot) {
    margin-top: 0 !important;
    height: 32px;
  }

  :deep(.v-input__prepend-inner) {
    height: 100%;
    margin-top: 0px !important;

    .v-input__icon {
      height: 16px;
      width: 16px;
      min-width: 16px;
      margin: auto 0px;

      .v-icon {
        font-size: 16px;
      }
    }
  }
}

.appointments-list-search-count {
  font-size: 14px;
  font-weight: 400;
  margin: 0;

  b {
    font-weight: 600;
  }
}

.appointments-list-columns {
  margin: 0 !important;
  margin-right: 12px !important;

  :deep(.v-input__slot) {
    height: 100%;
    margin: 0 !important;

    :hover {
      background-color: $color-background-tertiary;
      cursor: pointer;
    }

    .v-label {
      border-radius: 4px;
      color: $color-neutral-90;
      font-weight: 600;
      height: 36px;
      padding: 8px !important;
      top: calc(50% - 16px);
      z-index: 2;
    }

    .v-input__icon > i.v-icon {
      color: $color-neutral-90 !important;
    }

    .v-select__slot {
      border-radius: 4px;
      padding: 8px !important;
    }
  }

  :deep(.v-input__control) {
    min-height: 0 !important;
  }
}
</style>
