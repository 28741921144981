<template>
  <div class="mt-3">
    <div id="mobile-dialog-overlay"></div>
    <v-stepper
      v-model="currentStep"
      :flat="true"
      class="status-stepper pb-0"
      id="mobile-status-timeline"
      vertical>
      <template v-for="(status, index) in visibleStatuses">
        <div
          class="is-relative step"
          :key="`status-${index}`"
          :class="{ 'is-visible': status.show }">
          <div
            class="mobile-status-divider"
            :key="`step-${index}-divider`"
            v-if="status.name !== lastVisibleStatus.name && status.show" />
          <v-stepper-step
            v-show="status.show"
            :step="status.stepNumber"
            :class="{
              'is-requested': novaCore.isRequested(appointment) && status.name === requestedStatus
            }"
            :complete-icon="getCompletedIcon(status)"
            :complete="status.stepNumber <= currentStep || currentStep === 0"
            :color="status.name.toLowerCase()">
            <div
              class="font-weight-medium status-name"
              v-if="
                !displayTime(status) ||
                (displayTime(status) && !appointment.statusTimeline[status.name])
              ">
              {{ novaCore.breakWordsAtCaps(status.name) }}
            </div>
            <template
              v-else-if="
                displayTime(status) && status.name === novaCore.AppointmentStatus.Cancelled
              ">
              <div class="font-weight-medium status-name">
                {{ novaCore.breakWordsAtCaps(status.name) }}
              </div>
              <div class="d-flex flex-wrap-nowrap font-size-x-small font-weight-light">
                <template v-if="appointment.statusTimeline[status.name]">
                  <div class="font-size-x-small">
                    {{
                      formatDateTime(
                        appointment.statusTimeline[status.name],
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
                      )
                    }}
                  </div>
                </template>
              </div>
              <div class="caption text-uppercase" v-html="timeDiffInfo(status.name)"></div>
            </template>

            <v-btn
              v-if="
                displayTime(status) &&
                appointment.statusTimeline[status.name] &&
                status.name !== novaCore.AppointmentStatus.Cancelled
              "
              :ripple="false"
              @click="openEditor(status.name)"
              class="column-btn align-left btn-plain white-bg pa-0 color-inherit no-hover mobile-timeline-edit-btn"
              elevation="0">
              <div class="font-weight-medium status-name black--text">
                {{ novaCore.breakWordsAtCaps(status.name) }}
              </div>
              <div class="d-flex flex-wrap-nowrap font-size-x-small font-weight-light">
                <template v-if="appointment.statusTimeline[status.name]">
                  <div class="font-size-x-small">
                    {{
                      formatDateTime(
                        appointment.statusTimeline[status.name],
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
                      )
                    }}
                  </div>
                  <v-icon class="ml-3 mobile-icon-btn">mdi-pencil</v-icon>
                </template>
              </div>
              <div class="caption text-uppercase" v-html="timeDiffInfo(status.name)"></div>
            </v-btn>
          </v-stepper-step>
        </div>
      </template>

      <template v-if="lastVisibleStatus.name !== appointment.status">
        <div v-if="!areAllStatusesVisible" class="d-flex flex-row align-center v-stepper__step">
          <v-icon color="secondary">mdi-chevron-down</v-icon>
          <v-btn
            @click="toggleCollapse"
            plain
            small
            class="view-all-btn"
            data-testid="view-all-toggle-button">
            View All
          </v-btn>
        </div>
        <div v-else class="d-flex flex-row align-center v-stepper__step">
          <v-icon color="secondary">mdi-chevron-up</v-icon>
          <v-btn
            @click="toggleCollapse"
            plain
            small
            class="view-all-btn"
            data-testid="view-all-toggle-button">
            Collapse
          </v-btn>
        </div>
      </template>

      <v-stepper-items id="mobile-status-timeline-actions" ref="timelineactions">
        <template v-if="!isCancelled">
          <template v-for="(status, index) in statuses">
            <v-stepper-content
              :step="status.stepNumber"
              :key="`status-${index}`"
              class="pa-0"
              transition="fab-transition">
              <v-card class="card-content" elevation="6" outlined>
                <v-card-text>
                  <v-container>
                    <v-row
                      class="justify-center"
                      v-if="$rolePermissions.canUpdateAppointmentStatus && !readOnly">
                      <secondary-button
                        block
                        :loading="btnHappy_loading"
                        class="status-proceed-button mb-2"
                        @click="btnHappy_onClick(status)"
                        v-if="shouldShowNextHPButton(status)">
                        {{ getButtonLabel(statusMap[getNextHappyPathStatus(status)]) }}
                      </secondary-button>
                      <v-btn
                        block
                        outlined
                        v-if="checkForTriggerOnStatus(status.name)"
                        class="mb-2"
                        @click="activeTrigger = checkForTriggerOnStatus(status.name)">
                        <v-icon small class="mr-2">mdi-text-box-edit-outline</v-icon>
                        {{ checkForTriggerOnStatus(status.name).flow.name }}
                      </v-btn>
                      <v-btn
                        block
                        :loading="btnUndo_loading"
                        class="status-undo-button"
                        text
                        v-if="status.canUndo"
                        @click="btnUndo_onClick()">
                        <v-icon small class="mr-2">mdi-undo</v-icon>
                        {{ `Undo ${novaCore.breakWordsAtCaps(status.name)} Status` }}
                      </v-btn>
                      <v-btn
                        :loading="btnNonHappy_loading"
                        text
                        v-if="getNextNonHappyPathStatus(status) && !status.isEndState"
                        class="status-undo-button"
                        @click="btnNonHappy_onClick(status)">
                        <v-icon
                          v-if="getStatusIcon(statusMap[getNextNonHappyPathStatus(status)])"
                          small
                          class="mr-2">
                          {{ getStatusIcon(statusMap[getNextNonHappyPathStatus(status)]) }}
                        </v-icon>
                        {{ getButtonLabel(statusMap[getNextNonHappyPathStatus(status)]) }}
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </template>
        </template>

        <div v-else class="is-cancelled">
          <v-card class="card-content" elevation="6" outlined>
            <v-card-text>
              <v-container>
                <v-row
                  class="justify-center"
                  v-if="$rolePermissions.canUpdateAppointmentStatus && !readOnly">
                  This appointment was cancelled
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-stepper-items>
    </v-stepper>

    <status-timeline-edit-dialog-mobile
      content-class="status-timeline-edit-dialog-mobile"
      fullscreen
      transition="dialog-bottom-transition"
      external-activator
      :show-dialog="showEditDialog"
      :status-to-edit="statusToEdit"
      :appointment="appointment"
      @appointment-updated="$emit('appointment-updated')"
      @close="showEditDialog = false" />

    <custom-forms-flow-dialog
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      :status-change="pendingStatusChange"
      stack-actions
      fullscreen
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="submitStatusWithCustomFormsData"></custom-forms-flow-dialog>
  </div>
</template>

<script>
import StatusManagerBase from '@satellite/modules/appointments/components/StatusManagerBase.vue';

/**
 * @displayName Status Timeline Mobile
 */
export default {
  name: 'StatusTimelineMobile',
  extends: StatusManagerBase,
  computed: {
    collapsedStateStatuses() {
      const statuses = [];
      let shouldHideNext = false;
      this.statuses.forEach(status => {
        if (!shouldHideNext) {
          statuses.push(status);
        }
        if (status.name === this.appointment.status) {
          shouldHideNext = true;
        }
      });
      return statuses;
    },
    visibleStatuses() {
      return this.isCollapsed ? this.collapsedStateStatuses : this.statuses;
    },
    showStatusDivider() {
      return this.status.name !== this.lastVisibleStatus.name && this.status.show;
    },
    areAllStatusesVisible() {
      return this.collapsedStateStatuses.length === this.visibleStatuses || !this.isCollapsed;
    },
    warehouse() {
      // Used by statusManagerMixin
      return this.appointment?.dock?.warehouse;
    }
  },
  data() {
    return {
      isCollapsed: true,
      resizeObserver: null,
      initialActionHeight: 0,
      mobileDialogEl: null,
      headerHeight: 61,
      showEditDialog: false
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    openEditor(status) {
      this.showEditDialog = true;
      this.statusToEdit = status;
    }
  },
  mounted() {
    this.mobileDialogEl = document.querySelector('.details-dialog-mobile');
    this.$eventHub.$on('action-field-submitted', this.handleActionFieldTriggered);
    this.resizeObserver = new ResizeObserver(entries => {
      // this will get called whenever div dimension changes
      entries.forEach(entry => {
        this.mobileDialogEl.style.height = `calc(100% - ${this.headerHeight}px - ${entry.contentRect.height}px`;
      });
    });
    this.resizeObserver.observe(this.$refs.timelineactions);
  },
  beforeDestroy() {
    this.$eventHub.$off('action-field-submitted', this.handleActionFieldTriggered);
    this.resizeObserver.unobserve(this.$refs.timelineactions);
    this.mobileDialogEl.style.height = `calc(100% - ${this.headerHeight}px`;
  }
};
</script>
