<template>
  <div class="mb-4 full-width d-flex flex-row align-center">
    <h3 v-if="label" :class="disabled ? 'text--disabled' : ''" class="mr-4">
      {{ label }}:
      <help-icon-tooltip v-if="tooltipText">
        {{ tooltipText }}
      </help-icon-tooltip>
    </h3>
    <v-autocomplete
      v-bind="[$attrs, $props]"
      :required="required"
      :rules="rules"
      :prepend-icon="!hideIcon ? 'mdi-truck' : ''"
      class="pt-0"
      hide-details="auto"
      @input="updateValue"
      :search-input.sync="filters.searchStr"
      :value="value"
      :items="groupedCarriers"
      item-value="id"
      no-filter
      ref="carrierSelect"
      :label="value ? null : placeholder"
      :loading="isLoading"
      return-object
      :multiple="multiple"
      :disabled="disabled">
      <template v-if="showWarning" v-slot:prepend-item>
        <v-alert color="chalkorange" class="mx-1 mb-0">
          <p class="mb-1">
            <v-icon small color="primary" class="mr-2">mdi-information</v-icon>
            <strong>Appointment notifications will be sent out to your carrier if enabled.</strong>
          </p>

          <p class="mt-1 mb-0 ml-7">
            To schedule a test appointment, please select an email ending in “@example.com”.
          </p>
        </v-alert>
      </template>
      <template v-slot:selection="{ item }">
        {{ getSelectedCarrierText(item, truncateLength, truncateAsWhole) }}
      </template>
      <template v-slot:item="{ item }">
        <template>
          <div class="full-width">
            <v-list-item :input-value="isCarrierFavorite(item)" class="px-0">
              <v-list-item-content>
                <div class="item-wrapper">
                  <div class="name-and-info">
                    <span :class="{ 'carrier-name': isCarrierFavorite(item) }">
                      {{ getCarrierName(item) }}
                    </span>
                    <span class="company-info">
                      <v-icon size="14px" class="mr-1">mdi-truck-outline</v-icon>
                      <strong>{{ item.company.name }}</strong>
                      {{ item.company.scac || '' }}
                    </span>
                  </div>
                  <div class="email-and-subscribers">
                    <span class="carrier-email">
                      {{ item.email }}
                    </span>
                    <span class="subscribers" v-if="getCarrierEmailCCs(item).length > 0">
                      <v-icon size="14px">mdi-email-fast-outline</v-icon>
                      +{{ getCarrierEmailCCs(item).length }}
                      subscribers
                    </span>
                  </div>
                  <div class="favorite">
                    <v-icon color="primary" v-if="isCarrierFavorite(item)">mdi-star</v-icon>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </div>
        </template>
      </template>

      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            {{
              filters.searchStr && !carriers.length
                ? 'No matching carrier contacts found...'
                : placeholder
            }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append-item v-if="!isLoading && showCarrierCreateButton">
        <v-list-item>
          <v-list-item-title>
            <create-carrier-dialog
              @save="handleNewCarrier"
              entry-point="Carrier Contacts Select List">
              <template v-slot:activator="slotProps">
                <span>
                  Didn't find your carrier contact?
                  <a href="#" v-bind="slotProps.attrs" v-on="slotProps.on">Create new</a>
                </span>
              </template>
            </create-carrier-dialog>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template #label v-if="required">
        {{ placeholder }}
        <span class="red--text"><strong>*</strong></span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import carrierSelectMixin from '@/components/mixins/carrierSelectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import selectMixin from '@satellite/components/mixins/selectMixin';

export default {
  name: 'carrier-select',
  mixins: [selectMixin, dataListMixin, carrierSelectMixin],
  props: {
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showWarning: {
      type: Boolean,
      required: false,
      default: false
    },
    showCarrierCreateButton: {
      type: Boolean,
      required: false,
      default: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    hideIcon: {
      type: Boolean,
      required: false
    },
    warehouse: {
      type: Object,
      required: false
    },
    /*
     * Mixpanel entry point
     */
    entryPoint: {
      type: String,
      required: true
    },
    truncateLength: {
      type: Number,
      required: false,
      default: 40
    },
    truncateAsWhole: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      carriers: [],
      filters: {
        searchStr: null
      },
      searchFields: ['firstName', 'lastName', 'email', 'company.name'],
      isLoading: false,
      sortBy: ['firstName'],
      placeholder: 'Start typing to find a carrier contact'
    };
  },
  methods: {
    async updateValue(carrier) {
      await this.$emit('input', carrier);
    },
    handleNewCarrier(carrier) {
      this.carriers.push(carrier);
      this.updateValue(carrier);
      this.$refs.carrierSelect.isMenuActive = false;
    }
  },
  computed: {
    groupedCarriers() {
      const whFavorites = [];
      const orgFavorites = [];
      const notFavorites = [];

      this.carriers.forEach(carrier => {
        if (!carrier.orgCarrierSettings?.[0]) {
          notFavorites.push(carrier);
          notFavorites.push({ divider: true });
        } else if (carrier.orgCarrierSettings[0].favoriteWarehouseIds === null) {
          orgFavorites.push(carrier);
          orgFavorites.push({ divider: true });
        } else if (
          this.warehouse?.id &&
          carrier.orgCarrierSettings[0].favoriteWarehouseIds.includes(this.warehouse.id)
        ) {
          whFavorites.push(carrier);
          whFavorites.push({ divider: true });
        } else {
          notFavorites.push(carrier);
          notFavorites.push({ divider: true });
        }
      });

      const grouped = [];
      if (whFavorites.length > 0) {
        grouped.push({ header: `${this.warehouse.name} favorites` });
        grouped.push(...whFavorites);
      }
      if (orgFavorites.length > 0) {
        grouped.push({ header: `${this.$org.name} favorites` });
        grouped.push(...orgFavorites);
      }
      if (notFavorites.length > 0) {
        grouped.push({ header: 'other' });
        grouped.push(...notFavorites);
      }

      return grouped;
    }
  },
  async mounted() {
    if (this.value?.id) {
      this.carriers.push(this.value);
    } else {
      await this.getData();
    }
  }
};
</script>

<style scoped lang="scss">
.item-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name-and-info {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.email-and-subscribers {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.favorite {
  display: flex;
  justify-content: center;
}

.carrier-name {
  color: var(--v-primary-base);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.carrier-email {
  color: $color-neutral-90;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.company-info {
  color: #585f66;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.06px;
}

.subscribers {
  color: $color-neutral-90;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.06px;
}

::v-deep .v-subheader {
  color: #838d96;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.06px;
  text-transform: uppercase;
  height: inherit;
  padding-bottom: 8px;
  padding-top: 12px;
}
</style>
