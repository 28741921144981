import NotAuthorized from './components/NotAuthorized';

export default [
  {
    path: '/auth/401',
    name: 'notAuthorized',
    props: false,
    component: NotAuthorized,
    meta: {
      title: '401 Not Authorized',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: true
    }
  }
];
