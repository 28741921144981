<script>
import RescheduleAppointmentFormBase from '@satellite/modules/appointments/components/reschedule/RescheduleAppointmentFormBase.vue';

export default {
  name: 'RescheduleAppointmentFormBase',
  extends: RescheduleAppointmentFormBase,
  data() {
    return {
      allowDockSelect: true
    };
  }
};
</script>
