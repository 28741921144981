var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { justify: "space-between" } },
    [
      _c(
        "v-col",
        { attrs: { sm: "6" } },
        [
          _c("warehouse-select", {
            attrs: {
              "data-testid": "yard-warehouse-select",
              "multi-select": false,
              "show-create-dock-button": false,
              "should-disable-if-no-docks": false,
              "hide-icon": "",
              clearable: false,
              "restricted-warehouse-ids": _vm.$me.warehouseAccessList,
              "auto-select-by-id": _vm.defaultWarehouseId,
            },
            model: {
              value: _vm.selectedWarehouse,
              callback: function ($$v) {
                _vm.selectedWarehouse = $$v
              },
              expression: "selectedWarehouse",
            },
          }),
        ],
        1
      ),
      _vm.selectedWarehouse?.id
        ? _c("v-col", { attrs: { sm: "3" } }, [_c("yard-view-area-select")], 1)
        : _vm._e(),
      _vm.selectedWarehouse?.id && !_vm.isReadOnly
        ? _c(
            "v-col",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { "data-testid": "yard-subheader-add-area", text: "" },
                  on: { click: _vm.addSpotArea },
                },
                [_vm._v("+ Area")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }