var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    attrs: {
      disabled: !_vm.selectedWarehouse,
      items: _vm.spotAreas,
      "item-text": "name",
      "item-value": "id",
      placeholder: "Select areas",
      "return-object": false,
      multiple: "",
      dense: "",
      clearable: false,
      "hide-details": "",
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item, index }) {
          return [
            index === 0
              ? _c(
                  "small",
                  {
                    class: {
                      "font-weight-bold":
                        _vm.selectedAreas.length < _vm.spotAreas.length,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.selectedAreas.length) +
                        " of " +
                        _vm._s(_vm.spotAreas.length) +
                        " selected "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "prepend-item",
        fn: function () {
          return [
            _vm.selectedAreas.length !== _vm.spotAreas.length
              ? _c(
                  "v-btn",
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.selectAll($event)
                      },
                    },
                  },
                  [_vm._v(" Select all ")]
                )
              : _vm._e(),
            _c("v-divider"),
          ]
        },
        proxy: true,
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "v-list-item-content",
              [
                _c(
                  "v-list-item-title",
                  [
                    _vm._v(" " + _vm._s(item.name) + " "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "text--disabled",
                        attrs: { text: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.selectOnly($event, item)
                          },
                        },
                      },
                      [_c("small", [_vm._v("only")])]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedAreas,
      callback: function ($$v) {
        _vm.selectedAreas = $$v
      },
      expression: "selectedAreas",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }