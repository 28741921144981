var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "mb-4 pa-1 cursor-pointer draggable-card",
      class: { dragging: _vm.isDragging },
      attrs: {
        draggable: true,
        title: "Drag to assign to a spot",
        "data-testid": `yard-asset-visit-${_vm.assetVisit.id}`,
        id: _vm.assetVisit.id,
      },
      on: {
        dragstart: _vm.onDragStart,
        dragend: _vm.onDragEnd,
        drop: _vm.onDrop,
        dragover: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("v-card-title", [
        _c(
          "div",
          { staticClass: "d-flex flex-row" },
          [
            _c("v-icon", { staticClass: "mr-4" }, [
              _vm._v("mdi-drag-vertical"),
            ]),
            _c("svg-truck", {
              attrs: { theme: _vm.truckThemeColor, scale: 1.2 },
            }),
          ],
          1
        ),
      ]),
      _c("v-card-text", [
        _c("p", { staticClass: "font-size-medium font-weight-bold" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.assetVisit.company?.name ||
                  _vm.assetVisit.companyHint ||
                  "Unknown Company"
              ) +
              " "
          ),
        ]),
        _c("p", { staticClass: "mb-0 mt-0" }, [
          _c(
            "strong",
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-map-marker-outline"),
              ]),
              _vm._v(" Arrived: " + _vm._s(_vm.arrivedTimeFormatted) + " "),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }