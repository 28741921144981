//VUE
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';

// APP
import App from './App.vue';
import config from './config/config';
import Routes from '@/routes.js';
import store from './store';

// Mixins
import localStorageMixin from '@satellite/components/mixins/localStorageMixin';
import mappedGetters from '@/components/mixins/mappedGetters';
import mappedStates from '@/components/mixins/mappedStates';
import mixpanelMixin from '@/components/mixins/mixpanelMixin';
import utilityMixin from '@/components/mixins/utilityMixin';
import settingsMixin from '@/components/mixins/settingsMixin';

// Plugins
import AxiosPlugin from '@satellite/plugins/AxiosPlugin';
import ConfirmDialogPlugin from '@satellite/plugins/ConfirmDialog/ConfirmDialogPlugin';
import SocketPlugin from '@satellite/plugins/SocketPlugin';
import util from '@satellite/plugins/util';
import validation from '@satellite/plugins/Validator';
import initSentry from '@satellite/plugins/sentry';
import vuetify from './plugins/vuetifyPlugin';
import Hotjar from 'vue-hotjar';
import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import featureFlagPlugin from './plugins/featureFlagPlugin';

// Deps
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import * as Core from '@satellite/../nova/core';
import VueApexCharts from 'vue-apexcharts';
import VueMask from 'v-mask';
import moment from 'moment-timezone';

// Helpers
import vueHelpers from '@/helpers/vue';

import { RenderModeEnum } from '@/enums';
import warehouseService from '@satellite/services/warehouse-service';
import userService from '@satellite/services/user-service';
import dockService from '@satellite/services/dock-service';
import appointmentService from '@satellite/services/appointment-service';
import warehouseGroupService from '@satellite/services/warehouse-group-service';

// Init Plugins
window.momentjs = moment;
AxiosPlugin.init('/login', 'Helios');
ConfirmDialogPlugin.init(Vue);

// MODULES
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueMask);
Vue.use(VueApexCharts);
Vue.use(Notifications, { velocity });
Vue.use(featureFlagPlugin);

// Vue Prototypes
Vue.prototype.util = util;
Vue.prototype.$eventHub = new Vue(); // Global event bus
Vue.prototype.novaCore = Core;
Vue.prototype.$validator = new Vue(validation);
Vue.prototype.config = config;

// Services
Vue.prototype.services = {
  warehouse: warehouseService,
  warehouseGroup: warehouseGroupService,
  user: userService,
  dock: dockService,
  appointment: appointmentService
};

// Vue Global Mixins
Vue.mixin(mappedStates);
Vue.mixin(mappedGetters);
Vue.mixin(utilityMixin);
Vue.mixin(mixpanelMixin);
Vue.mixin(localStorageMixin);
Vue.mixin(settingsMixin);

// Vue Global Components
vueHelpers.registerComponents();

// Hotjar
if (config.hotjar_site_id) {
  Vue.use(Hotjar, {
    id: config.hotjar_site_id
  });
}

initSentry(Vue, Routes, config);

let main = new Vue({
  router: Routes,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    const localSettings = this.$loadObjectFromLocalStorage('local_settings');
    this.$store.commit('Settings/setLocalSettings', localSettings);
    if (localSettings.logSubspace) {
      this.addSubspaceLoggingEventListener();
    }
  },
  created() {
    this.ENUMS = {
      RenderModeEnum
    };
  }
}).$mount('#app');

store.$app = main;
Vue.prototype.sockets = new SocketPlugin(main);

// Mixpanel
main.mixpanel.init();

export default main;
