var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.spotAreas.length
        ? _c("yard-view-no-areas")
        : _c(
            "v-expansion-panels",
            { attrs: { accordion: "", multiple: "" } },
            _vm._l(_vm.spotAreas, function (spotArea) {
              return _c(
                "v-expansion-panel",
                {
                  key: spotArea.id,
                  staticClass: "mb-4",
                  attrs: { "data-testid": `yard-main-area-${spotArea.id}` },
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    [
                      _c("yard-view-spot-area-header", {
                        attrs: {
                          "spot-area": spotArea,
                          "data-testid": `yard-main-area-header-${spotArea.id}`,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("yard-view-spot-area", {
                        staticClass: "mt-6",
                        attrs: {
                          editable: false,
                          "spot-area": spotArea,
                          "data-testid": `yard-main-area-spots-${spotArea.id}`,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
      _vm.spotAreas.length > 0
        ? _c(
            "div",
            { staticClass: "d-flex flex-row justify-end font-size-small" },
            [
              _c(
                "span",
                { staticClass: "ml-4" },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(_vm._s(_vm.getSpotIcon(_vm.spotType.DOCKING))),
                  ]),
                  _vm._v(" Docking spot "),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "ml-4" },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(_vm._s(_vm.getSpotIcon(_vm.spotType.PARKING))),
                  ]),
                  _vm._v(" Parking spot "),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }