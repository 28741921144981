var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-stepper",
        {
          staticClass: "status-stepper",
          class: { "has-time-display": _vm.hasTimeDisplay },
          attrs: { "alt-labels": true, flat: true },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          !_vm.compact
            ? _c(
                "v-stepper-header",
                { class: `${_vm.appointment.status.toLowerCase()}-status` },
                [
                  _vm._l(_vm.statuses, function (status, index) {
                    return [
                      _c(
                        "v-stepper-step",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: status.show,
                              expression: "status.show",
                            },
                          ],
                          key: `status-${index}`,
                          class: _vm.getStepClasses(status),
                          attrs: {
                            step: status.stepNumber,
                            "complete-icon": _vm.getCompletedIcon(status),
                            complete:
                              status.stepNumber <= _vm.currentStep ||
                              _vm.currentStep === 0,
                            color: _vm.appointment.status.toLowerCase(),
                            "alt-labels": true,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleStepClick(status)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "font-weight-medium status-name",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.novaCore.breakWordsAtCaps(
                                          status.name
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.displayTime(status)
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "time-display-container" },
                                      [
                                        _vm.appointment.statusTimeline[
                                          status.name
                                        ]
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-size-x-small full-width text-no-wrap",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDateTime(
                                                        _vm.appointment
                                                          .statusTimeline[
                                                          status.name
                                                        ],
                                                        _vm.novaCore
                                                          .LuxonDateTimeFormats
                                                          .MonthDayYearSlashedTimeAMPMCompact,
                                                        _vm.novaCore
                                                          .LuxonDateTimeFormats
                                                          .MonthDayYearSlashedTime24
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", {
                                          staticClass:
                                            "font-size-x-small font-weight-bold",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.timeDiffInfo(status.name)
                                            ),
                                          },
                                        }),
                                        _vm.hasCheckinStatus
                                          ? [
                                              status.name ===
                                                _vm.arrivedStatus &&
                                              _vm.appointment
                                                .isCheckedInByCarrier
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "status-info",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Checked in by carrier"
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              status.name ===
                                                _vm.arrivedStatus &&
                                              _vm.isUnplannedAppointment
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "status-info",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Unplanned"),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              status.name ===
                                                _vm.completedStatus &&
                                              _vm.isAssetDeparted
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "status-info",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Checked Out"),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-stepper-items",
            [
              _vm._l(_vm.statuses, function (status, index) {
                return [
                  _c(
                    "v-stepper-content",
                    {
                      key: `status-${index}`,
                      staticClass: "pa-0",
                      attrs: {
                        step: status.stepNumber,
                        transition: "fab-transition",
                      },
                    },
                    [
                      !_vm.compact
                        ? _c(
                            "v-card",
                            {
                              staticClass: "card-content py-5",
                              attrs: { elevation: "6", outlined: "" },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "status-button-container" },
                                    [
                                      _c(
                                        "v-btn-toggle",
                                        { staticClass: "button-group" },
                                        [
                                          status.canUndo
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    height: "36",
                                                    loading:
                                                      _vm.btnUndo_loading,
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.btnUndo_onClick()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "mr-2 button-icon",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-arrow-u-left-top"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" Undo Status "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.getNextNonHappyPathStatus(
                                            status
                                          ) && !status.isEndState
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    height: "36",
                                                    loading:
                                                      _vm.btnNonHappy_loading,
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.btnNonHappy_onClick(
                                                        status
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getButtonLabel(
                                                          _vm.statusMap[
                                                            _vm.getNextNonHappyPathStatus(
                                                              status
                                                            )
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.shouldShowNextHPButton(status)
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "status-proceed-button color-secondary-60 text--color-text-inverted",
                                                  attrs: {
                                                    height: "36",
                                                    loading:
                                                      _vm.btnHappy_loading,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.btnHappy_onClick(
                                                        status
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getButtonLabel(
                                                          _vm.statusMap[
                                                            _vm.getNextHappyPathStatus(
                                                              status
                                                            )
                                                          ]
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "ml-2 button-icon text--color-text-inverted",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-arrow-right "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.checkForTriggerOnStatus(
                                            status.name
                                          )
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    height: "36",
                                                    outlined: "",
                                                    "data-testid":
                                                      "appointment-details-dialog-trigger-button",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.activeTrigger =
                                                        _vm.checkForTriggerOnStatus(
                                                          status.name
                                                        )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "mr-2 button-icon",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-text-box-edit-outline"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.checkForTriggerOnStatus(
                                                          status.name
                                                        ).flow.name
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "timeEditPanel",
          staticClass: "pa-4 time-edit-panel",
          class: [
            {
              [`edit-${_vm.statusToEdit?.toLowerCase()}`]: _vm.statusToEdit,
              "is-visible": _vm.statusToEdit,
            },
          ],
          style: _vm.statusTimelineEditStyles,
        },
        [
          _c(
            "header",
            { staticClass: "text--color-text-secondary text-center" },
            [_vm._v(" " + _vm._s(_vm.statusTimelineEditHeader) + " ")]
          ),
          _c("status-timeline-edit-form", {
            key: `${_vm.statusToEdit}-editor`,
            attrs: {
              appointment: _vm.appointment,
              "status-to-edit": _vm.statusToEdit,
            },
            on: {
              close: function ($event) {
                _vm.statusToEdit = null
              },
            },
          }),
        ],
        1
      ),
      _c("edit-custom-form-dialog", {
        attrs: {
          "external-activator": true,
          warehouse: _vm.warehouse,
          "show-dialog": Boolean(_vm.hasActiveTrigger),
          "object-id": _vm.appointment.id,
          trigger: _vm.activeTrigger,
          "status-change": _vm.pendingStatusChange,
        },
        on: {
          close: _vm.cleanUpCustomFormsData,
          update: _vm.updateCustomFormsData,
          create: _vm.submitStatusWithCustomFormsData,
        },
      }),
      _vm.showUnlinkDialog
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.showUnlinkDialog,
              "is-manual-mode": "",
              persistent: "",
              icon: "mdi-alert-circle-outline",
              color: "white",
              title: "Undo Arrived Status",
              width: 550,
              "allow-close": "",
              "dark-theme": false,
              loading: _vm.unlinkLoading,
              "button-false-text": "unlink check-in",
            },
            on: {
              result: _vm.handleUnlinkResult,
              close: function ($event) {
                _vm.showUnlinkDialog = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function () {
                    return [
                      _c("div", [
                        _vm._v(
                          "This appointment has been self-checked in by the driver."
                        ),
                      ]),
                      _c("div", { staticClass: "mt-4 mb-6" }, [
                        _vm._v(" Do you want to "),
                        _c("strong", [
                          _vm._v(
                            "unlink this arrival to allow a new check-in,"
                          ),
                        ]),
                        _vm._v(" or "),
                        _c("strong", [_vm._v("keep it linked")]),
                        _vm._v(" to this appointment? "),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "custom-actions",
                  fn: function ({ choose }) {
                    return [
                      _c("v-spacer"),
                      _c(
                        "outline-button",
                        {
                          staticClass: "my-3",
                          attrs: {
                            loading: _vm.unlinkLoading,
                            disabled: _vm.unlinkLoading,
                          },
                          on: {
                            click: function ($event) {
                              return choose(true)
                            },
                          },
                        },
                        [_vm._v(" Unlink Check-in ")]
                      ),
                      _c(
                        "primary-button",
                        {
                          staticClass: "my-3",
                          attrs: { disabled: _vm.unlinkLoading },
                          on: {
                            click: function ($event) {
                              return choose(false)
                            },
                          },
                        },
                        [_vm._v(" Keep Check-in ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              519578375
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }