<template>
  <div class="custom-field-timestamp">
    <div class="component-item">
      <date-picker
        class="timestamp-date-picker"
        v-model="customDate"
        placeholder="Select date"
        compact-display
        outlined
        no-margins
        :display-field-label="false"
        :display-field-icon="false"
        :required="field.required"></date-picker>
    </div>
    <div class="component-item">
      <time-entry
        outlined
        :date="dateTime"
        :hide-timezone="hideTimezone"
        :warehouse="warehouse"
        v-model="customTime"
        :fontSizeLarge="false"
        :required="field.required"
        :compact="isMobile()"></time-entry>
    </div>
  </div>
</template>

<script>
import customFieldTypeMixin from '@satellite/modules/custom_fields/mixins/customFieldTypeMixin';
import { isMobile } from '@satellite/plugins/util';
import { DateTime } from 'luxon';

export default {
  name: 'custom-field-timestamp',
  mixins: [customFieldTypeMixin],
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    hideTimezone: {
      type: Boolean,
      required: false,
      default: false
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      customTime: {
        amPm: '',
        time: ''
      },
      customDate: ''
    };
  },
  computed: {
    dateTime() {
      const newDateTimeString = [this.customDate, this.customTime.time, this.customTime.amPm].join(
        ' '
      );
      const newDateTime = DateTime.fromFormat(
        newDateTimeString,
        `${this.novaCore.LuxonDateTimeFormats.DateDashed} ${this.novaCore.LuxonDateTimeFormats.Extended12HrTimePadding} a`
      ).setZone(this.warehouse.timezone, { keepLocalTime: true });
      return newDateTime?.isValid ? newDateTime : null;
    }
  },
  methods: {
    isMobile,
    emitValueUpdate(value) {
      if (this.field.required && !value) {
        return null;
      }

      if (this.returnObject) {
        this.$emit('input', {
          date: this.customDate,
          time: this.customTime.time,
          amPm: this.customTime.amPm
        });
      } else {
        this.$emit('input', this.dateTime);
      }
    }
  },
  beforeMount() {
    if (this.value) {
      const currentDate = DateTime.fromISO(this.value).setZone(this.warehouse.timezone);

      if (currentDate?.isValid) {
        this.customDate = currentDate.toFormat(this.novaCore.LuxonDateTimeFormats.DateDashed);
        this.customTime.time = currentDate.toFormat(
          this.novaCore.LuxonDateTimeFormats.Extended12HrTimePadding
        );
        this.customTime.amPm = currentDate
          .toFormat(this.novaCore.LuxonDateTimeFormats.AMPM)
          .toLowerCase();
      }
    }
  },
  watch: {
    customDate(value) {
      this.emitValueUpdate(value);
    },
    customTime(value) {
      this.emitValueUpdate(value);
    }
  }
};
</script>

<style scoped lang="scss">
.custom-field-timestamp {
  display: flex;
  gap: 8px;
}
::v-deep .time-entry .v-input:not(.v-autocomplete):not(.v-select):not([class*='mt-']) {
  margin-top: 0;
}

.is-mobile {
  .custom-field-timestamp {
    display: block;
    .component-item {
      margin-bottom: 5px !important;
      .v-input,
      .v-input-control {
        margin: 0 !important;
        padding-top: 5px !important;
        align-items: flex-start !important;
      }
    }
  }
}
</style>
