<template>
  <div>
    <div class="appointment-details">
      <status-manager
        class="mb-4"
        :read-only="readOnly"
        :appointment="appointment"
        :warehouse-triggers="warehouseTriggers"
        :should-edit-status="shouldEditStatus"></status-manager>

      <section class="sidebar-content">
        <div class="sidebar">
          <details-content-box
            header="Carrier Contact"
            data-testid="appointment-details-carrier-box">
            <div class="d-flex align-center mb-4">
              <favorite-button
                :org="org"
                :carrier="appointment.user"
                @toggle-favorite="toggleFavoriteCarrier"
                class="mr-2"></favorite-button>
              <span class="font-weight-bold text--color-text-tertiary">
                {{ appointment.user.company.name }}
              </span>
            </div>
            <div>{{ appointment.user.firstName }} {{ appointment.user.lastName }}</div>
            <div>{{ appointment.user.email }}</div>
            <div v-if="appointment.user.phone">{{ appointment.user.phone }}</div>
            <div v-if="appointment.user.company.scac">
              {{ `SCAC: ${appointment.user.company.scac}` }}
            </div>
            <div class="mt-4">
              <outline-button
                v-if="!fieldsInEditMode.includes(EditableDetailsFields.carrier)"
                small
                inline
                class="align-self-start"
                @click="handleEditClick(EditableDetailsFields.carrier)">
                Change Carrier
              </outline-button>
              <template v-else>
                <carrier-select
                  outlined
                  v-model="selectedCarrier"
                  hide-icon
                  dense
                  data-testid="appointment-details-carrier-select"
                  truncate-as-whole
                  entry-point="Appointment Details Modal"></carrier-select>
                <div class="d-flex align-center">
                  <v-spacer></v-spacer>
                  <outline-button
                    small
                    class="mr-2"
                    @click="handleNevermindClick(EditableDetailsFields.carrier)">
                    Nevermind
                  </outline-button>
                  <primary-button
                    small
                    @click="
                      saveField(EditableDetailsFields.carrier, selectedCarrier?.id, 'userId')
                    ">
                    Save
                  </primary-button>
                </div>
              </template>
            </div>
          </details-content-box>
          <details-content-box header="Appointment Info" class="mb-0 full-height">
            <div class="data">
              <div class="data-group">
                <span class="data-header">Dock</span>
                <span>{{ appointment.dock.name }}</span>
              </div>
              <div class="data-group action-group">
                <div class="d-flex flex-column full-width">
                  <span class="data-header">Load Type</span>
                  <span v-if="!fieldsInEditMode.includes(EditableDetailsFields.loadType)">
                    {{ appointment.loadType.name }}
                  </span>
                  <template v-else>
                    <load-type-select
                      class="mt-1"
                      outlined
                      small
                      :clearable="false"
                      hide-icon
                      :selected-warehouse="appointment.warehouse"
                      :selected-docks="[appointment.dock]"
                      :docks="[appointment.dock]"
                      show-only-assigned-load-types
                      :value="loadTypeId"
                      @input="value => (loadTypeId = value.id)" />
                    <div class="d-flex justify-end">
                      <outline-button
                        small
                        class="mr-2"
                        @click="handleNevermindClick(EditableDetailsFields.loadType)">
                        Nevermind
                      </outline-button>
                      <primary-button
                        :disabled="loadTypeId === appointment.loadTypeId"
                        small
                        :loading="fieldsInLoadingState.includes(EditableDetailsFields.loadType)"
                        @click="saveField(EditableDetailsFields.loadType, loadTypeId)">
                        Save
                      </primary-button>
                    </div>
                  </template>
                </div>
                <v-btn
                  icon
                  @click="handleEditClick(EditableDetailsFields.loadType)"
                  v-if="!fieldsInEditMode.includes(EditableDetailsFields.loadType)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div class="data-group">
                <span class="data-header">Warehouse</span>
                <span>{{ appointment.warehouse.name }}</span>
              </div>
              <div class="data-group action-group">
                <div class="d-flex flex-column">
                  <span class="data-header">Confirmation Number</span>
                  <span>{{ appointment.confirmationNumber }}</span>
                </div>
                <copy-content
                  :content="appointment.confirmationNumber"
                  label="Click to copy confirmation number"></copy-content>
              </div>
              <div class="data-group action-group">
                <div class="d-flex flex-column">
                  <span class="data-header">Appointment ID</span>
                  <span>{{ appointment.id }}</span>
                </div>
                <copy-content
                  test-id="appointment-details-copy-appointment-id"
                  :content="appointment.id"
                  label="Click to copy confirmation number"></copy-content>
              </div>
            </div>
          </details-content-box>
        </div>
        <div class="content flex-1">
          <details-content-box
            is-dense
            :is-editable="!fieldsInEditMode.includes(EditableDetailsFields.eta)"
            :field="EditableDetailsFields.eta"
            header="Carrier ETA"
            v-if="appointment.status === novaCore.AppointmentStatus.Scheduled">
            <template #edit-button="{ field }">
              <outline-button small @click="handleEditClick(field)">Update ETA</outline-button>
            </template>

            <template v-if="!fieldsInEditMode.includes(EditableDetailsFields.eta)">
              <span class="text--color-text-tertiary" v-if="!appointment.eta">
                No ETA provided by the carrier
              </span>
              <template v-else>
                <div class="d-flex align-center">
                  <span>{{ computedEta }}</span>
                  <v-chip
                    v-if="appointment.eta"
                    small
                    :color="getEtaConditionMeta(etaCondition).color"
                    :class="getEtaConditionMeta(etaCondition).classes"
                    class="ml-2">
                    <span class="font-size-x-small">
                      {{ novaCore.breakWordsAtCaps(etaCondition) }}
                    </span>
                  </v-chip>
                </div>
              </template>
            </template>
            <template v-else>
              <set-eta-form
                ref="setEtaForm"
                :appointment="appointment"
                @cancel="handleNevermindClick"
                @condition-updated="updateEtaCondition"></set-eta-form>
            </template>
          </details-content-box>
          <details-content-box header="Appointment tags" is-dense>
            <tag-manager
              outlined
              v-model="tags"
              :outlined-chips="true"
              small-chips
              :should-enable-custom-tags="true"
              :read-only="!$rolePermissions.canUpdateAppointmentTags || readOnly"
              placeholder="Add tags"
              hide-instructions
              :allowTagDelete="!lockTagEditing" />
          </details-content-box>

          <details-content-box
            :header="$refNumSettings(appointment.warehouse).displayName"
            :is-editable="!fieldsInEditMode.includes(EditableDetailsFields.refNumber)"
            :field="EditableDetailsFields.refNumber"
            is-dense
            @click="handleEditClick">
            <span v-if="!fieldsInEditMode.includes(EditableDetailsFields.refNumber)">
              {{ appointment.refNumber || '&#8212;' }}
            </span>
            <template v-else>
              <text-field
                outlined
                v-model="refNumber"
                @keydown.enter="saveField(EditableDetailsFields.refNumber, refNumber)"></text-field>
              <div class="d-flex justify-end">
                <outline-button
                  small
                  class="mr-2"
                  @click="handleNevermindClick(EditableDetailsFields.refNumber)">
                  Nevermind
                </outline-button>
                <primary-button
                  :disabled="
                    refNumber === appointment.refNumber ||
                    fieldsInLoadingState.includes(EditableDetailsFields.refNumber)
                  "
                  small
                  :loading="fieldsInLoadingState.includes(EditableDetailsFields.refNumber)"
                  @click="saveField(EditableDetailsFields.refNumber, refNumber)">
                  Save
                </primary-button>
              </div>
            </template>
          </details-content-box>

          <details-content-box
            header="Notes"
            :is-dense="!appointment.notes"
            :is-editable="!fieldsInEditMode.includes(EditableDetailsFields.notes)"
            :field="EditableDetailsFields.notes"
            @click="handleEditClick">
            <div
              v-if="!fieldsInEditMode.includes(EditableDetailsFields.notes)"
              class="html-content"
              v-html="appointment.notes || '&#8212;'"></div>
            <template v-else>
              <rich-text-input
                class="flex-fill"
                :editor-config="{
                  plugins: ['Essentials', 'Paragraph'],
                  toolbar: { items: [] }
                }"
                v-model="appointmentDataRefs[EditableDetailsFields.notes].value"></rich-text-input>
              <div class="d-flex justify-end mt-6">
                <outline-button
                  small
                  class="mr-2"
                  @click="handleNevermindClick(EditableDetailsFields.notes)">
                  Nevermind
                </outline-button>
                <primary-button
                  :disabled="
                    appointmentDataRefs[EditableDetailsFields.notes] === appointment.notes ||
                    fieldsInLoadingState.includes(EditableDetailsFields.notes)
                  "
                  small
                  :loading="fieldsInLoadingState.includes(EditableDetailsFields.notes)"
                  @click="
                    saveField(
                      EditableDetailsFields.notes,
                      appointmentDataRefs[EditableDetailsFields.notes].value
                    )
                  ">
                  Save
                </primary-button>
              </div>
            </template>
          </details-content-box>

          <details-content-box
            header="Booking details"
            :is-dense="Object.keys(customFields).length === 0"
            is-editable
            :field-name="EditableDetailsFields.customFields"
            @click="handleEditClick(EditableDetailsFields.customFields)">
            <span v-if="!Object.keys(customFields).length">&#8212;</span>
            <div class="data" v-else>
              <div
                class="data-group no-divider"
                v-for="customField in Object.values(customFields)"
                :key="customField.key">
                <span class="data-header">{{ customField.label }}</span>
                <span v-html="getCustomFieldValue(customField)"></span>
              </div>
            </div>
          </details-content-box>

          <details-content-box header="Email subscribers" is-dense class="mb-0">
            <email-list-field outlined small-chips v-model="ccEmails" ref="emailFieldList" />
          </details-content-box>
        </div>
      </section>
    </div>

    <edit-custom-fields-dialog
      external-activator
      v-if="fieldsInEditMode.includes(EditableDetailsFields.customFields)"
      @close="novaCore.removeArrayItem(fieldsInEditMode, EditableDetailsFields.customFields)"
      :appointment="appointment"
      :show-dialog="fieldsInEditMode.includes(EditableDetailsFields.customFields)"
      @save="data => saveField(EditableDetailsFields.customFields, data)" />
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';
import DetailsContentBox from '@/modules/appointments/components/details/DetailsContentBox.vue';
import { computed, ref, watch } from 'vue';
import { isEqual } from 'lodash';
import { EditableDetailsFields } from '@/enums';
import appointmentService from '@satellite/services/appointment-service';
import EditCustomFieldsDialog from '@/modules/appointments/components/details/EditCustomFieldsDialog.vue';
import { useNovaCore, useStore, useOrg, useEta } from '@/composables';
import MixpanelEvents from '@/enums/MixpanelEvents';

export default {
  components: { EditCustomFieldsDialog, DetailsContentBox },
  extends: AppointmentDetailsBase,
  setup(props) {
    const novaCore = useNovaCore();
    const store = useStore();
    const { orgRef: org } = useOrg();
    const loadTypeId = ref(props.appointment.loadTypeId);
    const refNumber = ref(props.appointment.refNumber);
    const notes = ref(props.appointment.notes);
    const customFields = ref(props.appointment.customFields);
    const selectedCarrier = ref(props.appointment.user);

    const appointmentDataRefs = {
      // Map refs using the EditableDetailsFields enum keys
      [EditableDetailsFields.loadType]: loadTypeId,
      [EditableDetailsFields.refNumber]: refNumber,
      [EditableDetailsFields.notes]: notes,
      [EditableDetailsFields.customFields]: customFields
    };

    const fieldsInEditMode = ref([]);
    const fieldsInLoadingState = ref([]);
    function handleEditClick(fieldName) {
      fieldsInEditMode.value.push(fieldName);
    }

    const ccEmails = ref(props.appointment.ccEmails);

    async function updateCCEmails(oldVal) {
      try {
        await axios.patch(`appointment/${props.appointment.id}`, {
          ccEmails: ccEmails.value
        });
      } catch (e) {
        ccEmails.value = oldVal;
      }
    }

    function handleNevermindClick(fieldName) {
      novaCore.removeArrayItem(fieldsInEditMode.value, fieldName);
      const refToUpdate = appointmentDataRefs[fieldName];
      if (refToUpdate) {
        refToUpdate.value = props.appointment[fieldName];
      }
    }

    async function saveField(fieldName, data, fieldKey = null) {
      if (fieldsInLoadingState.value.includes(fieldName)) return;
      fieldsInLoadingState.value.push(fieldName);
      try {
        const response = await appointmentService.updateAppointment(
          props.appointment.id,
          {
            [fieldKey ?? fieldName]: data ?? appointmentDataRefs[fieldName].value
          },
          { includeMetaData: true }
        );
        this.$store.dispatch('Appointments/triggerSocketEvent', {
          response,
          appointment: this.appointment
        });
        novaCore.removeArrayItem(fieldsInEditMode.value, fieldName);
      } finally {
        novaCore.removeArrayItem(fieldsInLoadingState.value, fieldName);
      }
    }

    const { etaCondition, getEtaConditionMeta } = useEta();

    function updateEtaCondition(condition) {
      etaCondition.value = condition;
    }

    const isMilitaryTimeEnabled = computed(() =>
      store.getters['Settings/isMilitaryTimeEnabled']({
        ...props.appointment?.dock?.warehouse,
        org
      })
    );

    const computedEta = computed(() =>
      novaCore.formatDateTimeWithMilitarySupport(
        props.appointment.eta,
        props.appointment.dock.warehouse.timezone,
        novaCore.LuxonDateTimeFormats.ShortMonthDayTimeAMPM,
        isMilitaryTimeEnabled.value,
        novaCore.LuxonDateTimeFormats.ShortMonthDayTime24
      )
    );

    const toggleFavoriteCarrier = async carrier => {
      if (org.value) {
        const favoritesList = org.value.favoriteCarrierIds || [];
        const index = favoritesList.findIndex(fav => fav === carrier.id);
        const isFavorite = index === -1;

        if (isFavorite) {
          favoritesList.push(carrier.id);
        } else {
          favoritesList.splice(index, 1);
        }
        org.value.favoriteCarrierIds = favoritesList;

        await axios
          .patch(`/org/${org.value.id}/favorite-carriers`, { favoriteCarrierIds: favoritesList })
          .then(async () => {
            this.notify(`Carrier ${isFavorite ? 'added to favorites' : 'removed from favorites'}`);

            const mixpanelData = {
              'Entry Point': 'Appointment Details Modal',
              'Carrier Contact Name': novaCore.namesToFullName(carrier.firstName, carrier.lastName),
              'Carrier Contact Email': carrier.email,
              'Carrier Company Name': carrier.company?.name,
              'Carrier Company ID': carrier.company?.id,
              'Org ID': org.value.id,
              'Org Name': org.value.name
            };

            this.mixpanel.track(
              MixpanelEvents.MODULE.CARRIER.FAVORITES[`${isFavorite ? 'ADDED' : 'REMOVED'}`],
              mixpanelData
            );
          });
      }
    };

    watch(ccEmails, async (newVal, oldVal) => {
      if (isEqual(newVal, oldVal) || isEqual(props.appointment.ccEmails, newVal)) return;
      await updateCCEmails(oldVal);
    });

    watch(
      () => props.appointment,
      () => {
        etaCondition.value = novaCore.getEtaCondition(
          props.appointment.start,
          props.appointment.eta,
          props.appointment.dock.warehouse.timezone
        );
      },
      { immediate: true }
    );

    return {
      EditableDetailsFields,
      handleEditClick,
      ccEmails,
      fieldsInEditMode,
      loadTypeId,
      saveField,
      fieldsInLoadingState,
      refNumber,
      handleNevermindClick,
      appointmentDataRefs,
      novaCore,
      computedEta,
      etaCondition,
      updateEtaCondition,
      org,
      getEtaConditionMeta,
      selectedCarrier,
      toggleFavoriteCarrier
    };
  }
};
</script>

<style scoped lang="scss">
.appointment-details {
  background-color: $color-background-primary;
}

.sidebar-content {
  display: flex;
  gap: 16px;
  background-color: $color-background-primary;
}

.sidebar {
  width: 317px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep .html-content p:last-child {
  margin-bottom: 0;
}

.data-group {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  gap: 4px;

  &.action-group:not(.is-in-edit-mode) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 20px !important;
    }
  }

  &:not(:first-child):not(.no-divider) {
    border-top: 1px solid $color-line-divider;
    padding-top: 12px;
  }

  .data-header {
    font-size: 14px;
    font-weight: 600;
    color: $color-text-tertiary;
  }
}

::v-deep .copy-btn-wrapper i {
  font-size: 20px !important;
}

::v-deep .v-autocomplete.v-select.v-input--is-focused input {
  min-width: 30px !important;
}
</style>
