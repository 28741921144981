import { createFeatureFlagPlugin, useClient, useContext } from '@loadsmart/vue-feature-flags';

import config from '@/config/config';

const targetEvents = ['Auth/setMe', 'Auth/logout'];

/**
 * @param {import("vuex").Store} store
 */
export function synchronizeFeatureFlagsContext(store) {
  async function updateFeatureFlagsContext({ type, payload = null } = {}) {
    if (!targetEvents.includes(type)) {
      return;
    }

    const { updateContext } = useContext();
    const { client } = useClient();
    const context = {
      userId: payload?.id ?? null,
      orgId: payload?.orgId ?? null,
      role: payload?.role ?? null,
      companyId: payload?.companyId ?? null,
      sessionId: payload?.id ?? null
    };

    for (const [key, value] of Object.entries(context)) {
      client?.setContextField(key, value);
    }

    try {
      client?.stop?.();
      await client?.start?.();
      await updateContext(context);
    } catch (error) {
      console.log('Failed to update context: ', error);
    }
  }

  store.subscribe(updateFeatureFlagsContext);
  store.subscribeAction({ after: updateFeatureFlagsContext });
}

export default createFeatureFlagPlugin({
  config: {
    url: config.unleash_url,
    appName: config.unleash_app_name,
    clientKey: config.unleash_client_key,
    environment: config.server_env === 'production' ? 'production' : 'development'
  },
  disableAutoStart: true
});
