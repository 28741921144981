<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h1 class="mb-8">Live Yard View</h1>
        <div class="d-flex justify-space-between">
          <h3>
            <v-btn icon small rounded @click="back" data-testid="yard-area-form-back">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ selectedWarehouse?.name }}
          </h3>
        </div>

        <v-card elevation="1" class="mt-4">
          <div class="pa-6">
            <yard-view-spot-area-form
              v-if="isEditing || !defaultSpotAreaId"
              :spot-area="spotArea" />
            <template v-else>
              <v-row>
                <v-col cols="8">
                  <h4 class="py-1 pt-2" data-testid="yard-spot-area-area-name">
                    <strong>{{ spotArea.name }}</strong>

                    <v-btn
                      title="Update area name"
                      small
                      icon
                      data-testid="yard-spot-area-edit-button"
                      @click="isEditing = true"
                      class="ml-2">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </h4>
                </v-col>
              </v-row>
            </template>
          </div>

          <v-divider></v-divider>

          <yard-view-spot-area
            v-if="defaultSpotAreaId"
            class="pa-5"
            :editable="true"
            :spot-area="spotArea"></yard-view-spot-area>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-navigation-drawer app right permanent>
        <transition name="slide-fade" mode="in-out">
          <v-col cols="auto" class="px-1 left-border-box" v-if="selectedSpot?.id">
            <yard-view-spot-form class="pl-4" :spot="selectedSpot" />
          </v-col>
        </transition>
      </v-navigation-drawer>
    </v-row>
    <yard-view-loading v-if="isLoading" />
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { useRouter } from 'vue-router/composables';
import warehouseService from '@satellite/services/warehouse-service';
import yardSpotAreaService from '@satellite/services/yardSpotArea-service';
import YardViewSpotArea from '@/modules/yard/components/SpotArea/YardViewSpotArea.vue';
import { useEventHub, useSockets } from '@/composables';
import YardViewLoading from '@/modules/yard/components/Overlays/YardViewLoading.vue';
import YardViewSpotForm from '@/modules/yard/components/Spot/YardViewSpotForm.vue';

export default defineComponent({
  name: 'YardViewSpotAreaPage',
  components: {
    YardViewSpotForm,
    YardViewLoading,
    YardViewSpotArea
  },
  setup() {
    const router = useRouter();
    const yardStore = useYard();
    const sockets = useSockets();
    const eventHub = useEventHub();

    const selectedWarehouse = yardStore.selectedWarehouseRef.value;
    const defaultWarehouseId = yardStore.defaultWarehouseIdRef.value;
    const defaultSpotAreaId = yardStore.defaultSpotAreaIdRef.value;

    const isEditingRef = ref(false);

    const spotAreaRef = ref({ name: '' });

    const fetchDataUponReload = () => {
      // Fetch spot area when reloading url
      if (defaultWarehouseId && defaultSpotAreaId) {
        yardSpotAreaService.getOneById(defaultSpotAreaId, {}).then(spotArea => {
          spotAreaRef.value = spotArea;
        });
      }

      // Fetch warehouse when reloading url
      if (!selectedWarehouse && defaultWarehouseId) {
        warehouseService.getWarehouseById(defaultWarehouseId, {}).then(warehouse => {
          yardStore.selectedWarehouseRef.value = warehouse;
        });
      }

      yardStore.selectedSpotRef.value = null;
    };

    const back = () =>
      router.push({
        name: 'yard-warehouse',
        params: { warehouseId: defaultWarehouseId }
      });

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
      });
      fetchDataUponReload();
      eventHub.$on('yard-spot-area-form-close', () => {
        isEditingRef.value = false;
      });
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
      });
      eventHub.$off('yard-spot-form-close');
      yardStore.selectedSpotRef.value = null;
    });

    return {
      enableYardView: yardStore.enableYardViewRef,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      defaultWarehouseId: yardStore.defaultWarehouseIdRef,
      defaultSpotAreaId: yardStore.defaultSpotAreaIdRef,
      selectedSpot: yardStore.selectedSpotRef,
      isLoading: yardStore.isLoadingSpotsRef,
      isReadOnly: yardStore.isReadOnlyRef,
      spotArea: spotAreaRef,
      isEditing: isEditingRef,
      back,
      onBeforeMount,
      onBeforeUnmount
    };
  },

  beforeMount() {
    // TODO: Remove this when the ff enable-yard-view-helios is removed
    // Redirect to appointments page if yard view is disabled
    if (!this.enableYardView) {
      this.$router.replace({ name: 'appointments' });
    }
  }
});
</script>
<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
