var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 d-flex justify-center align-center mt-10" },
    [
      _c(
        "div",
        {
          staticClass: "overlay-contents",
          attrs: { "data-testid": "yard-no-areas-message" },
        },
        [
          _c("svg-spot-area"),
          _c("h3", { staticClass: "align-center" }, [_vm._v("No Yard Areas")]),
          _c("p", [
            _vm._v(
              "Add areas to the warehouse yard view and start assigning assets to spots."
            ),
          ]),
          _c(
            "primary-button",
            {
              staticClass: "mt-4",
              on: {
                click: () => _vm.$router.push({ name: "yard-add-spot-area" }),
              },
            },
            [_vm._v(" Add Area ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }