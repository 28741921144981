var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `appointments-page mode-${_vm.$mode}`,
      attrs: { "data-testid": "appointments-page" },
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { staticClass: "appointments-page-header" },
            [
              _c(
                "v-row",
                { staticClass: "ma-1 pa-1" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "col-xs-12",
                      attrs: { sm: "12", md: "12", lg: "3", xl: "2", xs: "12" },
                    },
                    [
                      _c("v-autocomplete", {
                        ref: "warehouse-select",
                        staticClass: "appointments-page-warehouse-select",
                        attrs: {
                          "data-testid": "appointments-page-warehouse-select",
                          "item-value": "id",
                          "item-text": "name",
                          placeholder: "Select a warehouse",
                          "no-filter": "",
                          "return-object": "",
                          "auto-select-first": "",
                          flat: "",
                          solo: "",
                          "hide-details": "",
                          loading: _vm.loading.warehouses,
                          clearable: false,
                          items: _vm.warehouses,
                          "search-input": _vm.filters.searchStr,
                          "item-disabled": _vm.isWarehouseDisabled,
                        },
                        on: {
                          blur: _vm.handleWarehouseBlur,
                          change: _vm.handleWarehouseSelect,
                          "update:searchInput": function ($event) {
                            return _vm.$set(_vm.filters, "searchStr", $event)
                          },
                          "update:search-input": function ($event) {
                            return _vm.$set(_vm.filters, "searchStr", $event)
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-inline-block text-truncate appointments-page-warehouse-select-item",
                                    staticStyle: { "max-width": "36ch" },
                                    attrs: {
                                      "data-testid":
                                        "appointments-page-warehouse-selected",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formatWarehouseItem(item)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-inline-block text-truncate appointments-page-warehouse-select-item",
                                    staticStyle: { "max-width": "36ch" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formatWarehouseItem(item)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "append-item",
                            fn: function () {
                              return [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "intersect",
                                      rawName: "v-intersect",
                                      value: _vm.onIntersect,
                                      expression: "onIntersect",
                                    },
                                  ],
                                  attrs: {
                                    "data-testid":
                                      "appointment-page-warehouse-select-intersector",
                                    options: { threshold: [0, 0.5, 1.0] },
                                  },
                                }),
                                _vm.loading.warehouses &&
                                _vm.warehouses.length < _vm.warehouseTotal
                                  ? [
                                      _c("v-divider"),
                                      _c(
                                        "v-container",
                                        { staticClass: "v-list-item" },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "fill-height",
                                              attrs: {
                                                "align-content": "center",
                                                justify: "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 text-center pa-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _vm._v(
                                                    " Loading More Warehouses... "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-progress-linear", {
                                                    staticClass: "py-0",
                                                    attrs: {
                                                      indeterminate: "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.selectedWarehouse,
                          callback: function ($$v) {
                            _vm.selectedWarehouse = $$v
                          },
                          expression: "selectedWarehouse",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex align-center view-container col-xs-12",
                      attrs: { sm: "12", md: "1", lg: "1", xl: "2" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center appointments-page-filter-label",
                        },
                        [_c("span", [_vm._v("View:")])]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center col-xs-12",
                      attrs: {
                        sm: "6",
                        "offset-md": "0",
                        "offset-lg": "0",
                        md: "3",
                        lg: "2",
                      },
                    },
                    [
                      _c("dock-select", {
                        ref: "dock-select",
                        staticClass: "appointments-page-docks-select",
                        class: {
                          "no-items":
                            !_vm.$selectedDocks || !_vm.$selectedDocks.length,
                        },
                        attrs: {
                          "data-testid": "appointments-page-docks-select",
                          "hide-icon": "",
                          "filter-mode": "",
                          "multi-select": "",
                          flat: "",
                          solo: "",
                          "selected-warehouse": _vm.selectedWarehouse,
                          dense: false,
                          clearable: false,
                          "is-flex": false,
                          docks: _vm.warehouseDocks,
                          disabled: !_vm.selectedWarehouse?.id,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function ({ index }) {
                                return [
                                  index === 0
                                    ? _c(
                                        "div",
                                        {
                                          attrs: {
                                            "data-testid":
                                              "appointments-page-docks-selection",
                                          },
                                        },
                                        [
                                          _vm.allDocksSelected
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "filter-count",
                                                  },
                                                  [_vm._v("All")]
                                                ),
                                                _vm._v(" Docks "),
                                              ])
                                            : _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "filter-count",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectedDocks
                                                          ?.length ?? 0
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "   " +
                                                    _vm._s(
                                                      _vm.novaCore.pluralize(
                                                        _vm.selectedDocks
                                                          ?.length,
                                                        "Dock"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      )
                                    : _c("div", { staticClass: "d-none" }),
                                ]
                              },
                            },
                            !_vm.selectedDocks || !_vm.selectedDocks.length
                              ? {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "filter-count",
                                          attrs: {
                                            "data-testid":
                                              "appointments-page-docks-no-docks",
                                          },
                                        },
                                        [_vm._v("0")]
                                      ),
                                      _vm._v(" Docks "),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedDocks,
                          callback: function ($$v) {
                            _vm.selectedDocks = $$v
                          },
                          expression: "selectedDocks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center col-xs-12",
                      attrs: { sm: "6", md: "3", lg: "2" },
                    },
                    [
                      _c("load-type-select", {
                        ref: "loadtype-select",
                        staticClass: "appointments-page-loadtype-select",
                        class: {
                          "no-items":
                            !_vm.selectedLoadtypes ||
                            !_vm.selectedLoadtypes.length,
                        },
                        attrs: {
                          "data-testid": "appointments-page-loadtype-select",
                          "hide-icon": "",
                          solo: "",
                          flat: "",
                          dense: false,
                          disabled: _vm.isLoadTypeDisabled,
                          "selected-warehouse": _vm.selectedWarehouse,
                          "selected-docks": _vm.warehouseDocks,
                          "include-durations": false,
                          "multi-select": true,
                          "return-object": false,
                          "select-all": true,
                          clearable: false,
                        },
                        scopedSlots: _vm._u(
                          [
                            !_vm.selectedLoadtypes ||
                            !_vm.selectedLoadtypes.length
                              ? {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "filter-count",
                                          attrs: {
                                            "data-testid":
                                              "appointments-page-loadtype-no-loadtype",
                                          },
                                        },
                                        [_vm._v(" 0 ")]
                                      ),
                                      _vm._v(" Load Types "),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                            {
                              key: "selection",
                              fn: function ({ index }) {
                                return [
                                  index === 0
                                    ? _c(
                                        "div",
                                        {
                                          attrs: {
                                            "data-testid":
                                              "appointments-page-loadtype-selection",
                                          },
                                        },
                                        [
                                          _vm.allLoadTypesSelected
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "filter-count",
                                                  },
                                                  [_vm._v("All")]
                                                ),
                                                _vm._v(" Load Types "),
                                              ])
                                            : _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "filter-count",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectedLoadtypes
                                                          ?.length ?? 0
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "   " +
                                                    _vm._s(
                                                      _vm.novaCore.pluralize(
                                                        _vm.selectedLoadtypes
                                                          ?.length,
                                                        "Load Type"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      )
                                    : _c("div", { staticClass: "d-none" }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedLoadtypes,
                          callback: function ($$v) {
                            _vm.selectedLoadtypes = $$v
                          },
                          expression: "selectedLoadtypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center col-xs-12",
                      attrs: { sm: "6", md: "3", lg: "2" },
                    },
                    [
                      _c("status-select", {
                        ref: "status-select",
                        staticClass: "appointments-page-status-select",
                        class: {
                          "no-items":
                            !_vm.selectedStatuses ||
                            !_vm.selectedStatuses.length,
                        },
                        attrs: {
                          "data-testid": "appointments-page-status-select",
                          "hide-icon": "",
                          flat: "",
                          solo: "",
                          clearable: false,
                          dense: false,
                          "extra-statuses": ["Reserves"],
                          "statuses-sort-comparator":
                            _vm.statusesSortComparator,
                        },
                        scopedSlots: _vm._u(
                          [
                            !_vm.selectedStatuses ||
                            !_vm.selectedStatuses.length
                              ? {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-testid":
                                              "appointments-page-statuses-no-status",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "filter-count" },
                                            [_vm._v("0")]
                                          ),
                                          _vm._v(" Statuses "),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                            {
                              key: "selection",
                              fn: function ({ index }) {
                                return [
                                  index === 0
                                    ? _c(
                                        "div",
                                        {
                                          attrs: {
                                            "data-testid":
                                              "appointments-page-statuses-selection",
                                          },
                                        },
                                        [
                                          _vm.allStatusesSelected
                                            ? _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "filter-count",
                                                  },
                                                  [_vm._v("All")]
                                                ),
                                                _vm._v(" Statuses "),
                                              ])
                                            : _c("div", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "filter-count",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectedStatuses
                                                          ?.length ?? 0
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "   " +
                                                    _vm._s(
                                                      _vm.novaCore.pluralize(
                                                        _vm.selectedStatuses
                                                          ?.length,
                                                        "Status",
                                                        "Statuses"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                        ]
                                      )
                                    : _c("div", { staticClass: "d-none" }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selectedStatuses,
                          callback: function ($$v) {
                            _vm.selectedStatuses = $$v
                          },
                          expression: "selectedStatuses",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "col-xs-12 d-flex justify-end",
                      attrs: { sm: "6", md: "2", lg: "2" },
                    },
                    [
                      _c("feature-flag", {
                        attrs: { name: "enable-checkin-panel-redesign" },
                        scopedSlots: _vm._u([
                          {
                            key: "enabled",
                            fn: function () {
                              return [
                                _c("check-in-notification-panel", {
                                  staticClass:
                                    "appointments-page-check-in-notification-panel",
                                  attrs: {
                                    warehouse: _vm.selectedWarehouse,
                                    "show-advertisement":
                                      _vm.shouldShowAdvertisement,
                                    org: _vm.$org,
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "disabled",
                            fn: function () {
                              return [
                                _c("check-in-notification-panel-old", {
                                  staticClass:
                                    "appointments-page-check-in-notification-panel",
                                  attrs: {
                                    warehouse: _vm.selectedWarehouse,
                                    "show-advertisement":
                                      _vm.shouldShowAdvertisement,
                                    org: _vm.$org,
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "div",
                {
                  staticClass:
                    "appointments-page-header-calendar d-flex align-center justify-space-between",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center calendar-filter-container",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center calendar-date-actions",
                        },
                        [
                          !_vm.listMode
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    "internal-activator": "",
                                    "close-on-click": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on: tooltipOn,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "calendar-date-interval",
                                                              },
                                                              tooltipOn
                                                            ),
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "calendar-gutter-btn",
                                                                      attrs: {
                                                                        icon: "",
                                                                        ripple: false,
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "data-testid":
                                                                          "interval-select-button-icon",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " mdi-timeline-clock-outline "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.listMode
                                                          ? "Grid time interval granularity selection disabled in list view"
                                                          : "Select grid time interval granularity"
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    633027017
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.intervalOptions,
                                      function (item, index) {
                                        return _c(
                                          "v-list-item",
                                          { key: index },
                                          [
                                            _c(
                                              "button-base",
                                              {
                                                staticClass: "text-left",
                                                attrs: {
                                                  small: "",
                                                  text: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setIntervalMinutes(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex-grow-1 justify-start",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item) + " minutes"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "calendar-date-today",
                              attrs: { outlined: "", color: "#273038" },
                              on: { click: _vm.setToday },
                            },
                            [_vm._v(" Today ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "calendar-date-step",
                              attrs: { "data-testid": "calendar-date-step" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    text: "",
                                    small: "",
                                    color: "grey darken-2",
                                    "data-testid": "grid-prev-period",
                                  },
                                  on: { click: _vm.previousDate },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-chevron-left"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    text: "",
                                    small: "",
                                    color: "grey darken-2",
                                    "data-testid": "grid-next-period",
                                  },
                                  on: { click: _vm.nextDate },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-chevron-right"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "calendar-label",
                          attrs: { "data-testid": "grid-calendar-select" },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "internal-activator": "",
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "calendar-date-select",
                                              attrs: {
                                                icon: "",
                                                ripple: false,
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-calendar"),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "calendar-date-label",
                                              attrs: {
                                                "data-testid":
                                                  "calendar-date-label",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedDateLabel
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("v-date-picker", {
                                ref: "date-picker",
                                attrs: { color: "accent", type: "date" },
                                model: {
                                  value: _vm.selectedDate,
                                  callback: function ($$v) {
                                    _vm.selectedDate = $$v
                                  },
                                  expression: "selectedDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center calendar-filter-container",
                    },
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          attrs: { mandatory: "" },
                          model: {
                            value: _vm.viewType,
                            callback: function ($$v) {
                              _vm.viewType = $$v
                            },
                            expression: "viewType",
                          },
                        },
                        _vm._l(_vm.availableViewTypes, function (type) {
                          return _c(
                            "v-btn",
                            {
                              key: `calendar-${type}`,
                              staticClass: "view-type",
                              attrs: {
                                outlined: "",
                                color: "darken-2",
                                "data-testid": `calendar-view-type-${type}`,
                                value: type,
                              },
                            },
                            [_vm._v(" " + _vm._s(type) + " ")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "menu-container" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "internal-activator": "",
                                "close-on-click": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "darken-2",
                                                ripple: false,
                                                "data-testid":
                                                  "grid-view-mode-menu-activator",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                "data-testid":
                                                  "view-mode-activator",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getViewModeMeta().icon
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getViewModeMeta().label
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v("mdi-chevron-down")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "plain-items",
                                  attrs: { "data-testid": "view-mode-options" },
                                },
                                [
                                  _vm._l(_vm.viewModes, function (item, key) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: key,
                                        staticClass: "is-relative z-100",
                                      },
                                      [
                                        _c(
                                          "button-base",
                                          {
                                            staticClass:
                                              "d-flex align-center justify-center",
                                            attrs: {
                                              "data-testid": `${item}-view-button`,
                                              text: "",
                                              plain: "",
                                              ripple: false,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.mode = item
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getViewModeMeta(item)
                                                      .icon
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex-grow-1 justify-start font-size-x-small",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getViewModeMeta(item)
                                                        .label
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-list-item",
                                    { staticClass: "is-relative z-100" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "button-base",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center justify-center",
                                                      attrs: {
                                                        "data-testid":
                                                          "tv-mode-button",
                                                        text: "",
                                                        plain: "",
                                                        ripple: false,
                                                      },
                                                      on: {
                                                        click: _vm.viewTvMode,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-television-play"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "flex-grow-1 justify-start font-size-x-small",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Open TV Mode "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "TV Mode will open a new browser window"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "is-relative z-100",
                                      class: {
                                        "active-highlight":
                                          _vm.$shouldShowWeekends,
                                        disabled: _vm.isWeekdayOptionDisabled,
                                      },
                                    },
                                    [
                                      _c(
                                        "button-base",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          attrs: {
                                            "data-testid":
                                              "weekend-toggle-button",
                                            text: "",
                                            plain: "",
                                            ripple: false,
                                          },
                                          on: {
                                            click: _vm.toggleWeekendVisibility,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$shouldShowWeekends
                                                      ? "mdi-checkbox-marked"
                                                      : "mdi-checkbox-blank-outline"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-grow-1 justify-start font-size-x-small",
                                            },
                                            [_vm._v("Show weekends")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$mode === "grid"
        ? _c(
            "div",
            {
              class: `calendar-wrapper is-relative ${
                _vm.useLightGridTheme ? "light-grid-theme" : ""
              }`,
            },
            [
              _c("appointments-loader", {
                attrs: {
                  "data-testid": "grid-loader",
                  "is-loading": _vm.loading.appointments,
                },
              }),
              _vm.isMounted
                ? _c(_vm.calendarComponent, {
                    tag: "component",
                    attrs: {
                      "are-events-ready": _vm.isMounted,
                      docks: _vm.selectedDocks,
                      events: _vm.warehouseEventsByDock,
                    },
                    on: {
                      "is-dock-capacity-toggle": _vm.setIsDockCapacityToggle,
                      "set-loader": (val) => (_vm.loading.appointments = val),
                    },
                  })
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "px-6" },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-switch", {
                        staticClass: "mt-0",
                        attrs: {
                          color: "secondary",
                          label: "Light Grid Theme",
                        },
                        model: {
                          value: _vm.useLightGridTheme,
                          callback: function ($$v) {
                            _vm.useLightGridTheme = $$v
                          },
                          expression: "useLightGridTheme",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [_c("calendar-legend", { staticClass: "text-right" })],
                    1
                  ),
                ],
                1
              ),
              _c("appts-missing-selection-overlay", {
                attrs: {
                  "is-visible": _vm.shouldShowNoSelectionOverlay,
                  warehouse: _vm.selectedWarehouse?.id,
                  docks: _vm.selectedDocks,
                  "load-types": _vm.selectedLoadtypes,
                  statuses: _vm.selectedStatuses,
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "is-relative appointments-list-container" },
            [
              _c("appointments-list", {
                ref: "appointments-list",
                attrs: {
                  "custom-header": "",
                  "is-loading": _vm.loading.appointments,
                  appointments: _vm.filterWithinSelectedRange(
                    _vm.warehouseEventsByDock
                  ),
                  "filter-locally": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "table-header",
                    fn: function ({ filters, columns, rows, exporter }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("v-text-field", {
                              staticClass:
                                "appointments-list-search-input mr-3",
                              attrs: {
                                dense: "",
                                flat: "",
                                outlined: "",
                                "single-line": "",
                                "hide-details": "",
                                placeholder: "Find in this table…",
                                "prepend-inner-icon": "mdi-magnify",
                                height: "24",
                              },
                              model: {
                                value: filters.searchStr,
                                callback: function ($$v) {
                                  _vm.$set(filters, "searchStr", $$v)
                                },
                                expression: "filters.searchStr",
                              },
                            }),
                            _c(
                              "p",
                              {
                                staticClass: "appointments-list-search-count",
                                attrs: {
                                  "data-testid":
                                    "appointments-list-search-count",
                                },
                              },
                              [
                                _c("span", { staticClass: "filter-count" }, [
                                  _vm._v(" " + _vm._s(rows.length ?? 0) + " "),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.novaCore.pluralize(
                                          rows.length ?? 0,
                                          "appointment"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex align-center" },
                          [
                            _c("generic-multi-select", {
                              staticClass: "appointments-list-columns mr-3",
                              attrs: {
                                "item-text": "text",
                                "item-value": "value",
                                label: "Select table columns",
                                refKey: "columnSelect",
                                "append-icon": "mdi-chevron-down",
                                flat: "",
                                solo: "",
                                multiple: "",
                                "hide-details": "",
                                "return-object": "",
                                "persistent-hint": "",
                                clearable: false,
                                "auto-select": columns.autoSelect,
                                items: columns.available,
                                "minimum-selections": 1,
                                value: columns.selected,
                                "visible-selection-count": 3,
                              },
                              on: { input: columns.update },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function ({ index }) {
                                      return [
                                        index === 0
                                          ? _c(
                                              "span",
                                              [
                                                _vm._v(
                                                  " Select table columns (" +
                                                    _vm._s(
                                                      columns.selected
                                                        ?.length ?? 0
                                                    ) +
                                                    ") "
                                                ),
                                                _c("v-icon", {
                                                  attrs: { small: "" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span"),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-3",
                                attrs: { text: "" },
                                on: { click: exporter.printTable },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-4", attrs: { small: "" } },
                                  [_vm._v("mdi-printer")]
                                ),
                                _vm._v(" Print "),
                              ],
                              1
                            ),
                            _c(
                              "primary-button",
                              {
                                attrs: {
                                  "data-testid": "export-to-excel",
                                  "before-icon": "microsoft-excel",
                                  loading: exporter.buildingExcelData,
                                },
                                on: {
                                  click: function ($event) {
                                    return exporter.exportExcel(
                                      rows,
                                      exporter.excelFileName
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Export ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("appts-missing-selection-overlay", {
                attrs: {
                  "is-visible": _vm.shouldShowNoSelectionOverlay,
                  warehouse: _vm.selectedWarehouse?.id,
                  docks: _vm.selectedDocks,
                  "load-types": _vm.selectedLoadtypes,
                  statuses: _vm.selectedStatuses,
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }