<template>
  <div :data-testid="`yard-spot-assigned-${spot.code}`">
    <svg-truck :rotation="90" :scale="1.2" :theme="truckThemeColor" />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import SvgTruck from '@/components/svgs/SvgTruck.vue';

export default defineComponent({
  name: 'YardViewSpotAssigned',
  components: { SvgTruck },
  props: {
    spot: {
      type: Object,
      required: true
    },
    appointment: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const yardStore = useYard();

    const truckThemeColor = computed(() => yardStore.getTruckThemeColor(props.appointment));

    return {
      getSpotIcon: yardStore.getSpotIcon,
      getSpotStatus: yardStore.getSpotStatus,
      truckThemeColor
    };
  }
});
</script>

<style scoped lang="scss"></style>
