import notificationHelpers from '@satellite/helpers/notifications';

/**
 * Global utility mixin
 * @mixin
 * @displayName Utility Mixin
 */
export default {
  data() {
    return {
      $globalLoading: false
    };
  },
  methods: {
    waitForElementRender(documentMethod, selector, vueContext, callback) {
      const interval = setInterval(() => {
        const el = document[documentMethod](`${selector}`);
        if (el) {
          callback(el, vueContext);
          clearInterval(interval);
        }
      }, 10);
    },
    route: function (name, query, params) {
      let routeObj = { name: name };

      if (query) {
        routeObj.query = query;
      }

      if (params) {
        routeObj.params = params;
      }

      return this.$router.resolve(routeObj).href;
    },
    notify(message, type = 'success', options = {}) {
      options = {
        ...{
          duration: 5000,
          group: 'bottom-notifications',
          clean: false,
          actionText: null,
          actionFn: null,
          title: null
        },
        ...options
      };

      this.$notify({
        group: options.group,
        title: this.novaCore.sanitizeInput(this.novaCore.upperFirst(options.title ?? type)),
        text: this.novaCore.sanitizeInput(message),
        duration: options.duration,
        data: { ...notificationHelpers.getNotificationConfig(type), ...options },
        clean: options.clean
      });
    },
    clearNotifications(group = 'app-notifications') {
      this.notify('', '', 0, group, true);
    },
    getValidations(error) {
      if (!error.response || !error.response.data || !error.response.data.errors) {
        return {};
      }

      return error.response.data.errors;
    },
    navigate(name, params) {
      this.$router.push({ name, params });
    },
    // Shake an element to bring attention to it
    shakeElement(refKey = '') {
      let ref = this.$refs[refKey];

      if (!ref) {
        console.log('shakeElement: Ref element does not exist');
        return;
      }

      ref.$el.classList.add('shake-element');
      setTimeout(() => {
        ref.$el.classList.remove('shake-element');
      }, 1000);
    },
    handleAppointmentQueryString(currentPath, appointmentId, routeAction = 'push') {
      if (currentPath === '/appointments') {
        this.$router[routeAction]({ query: { appointmentId } });
      } else {
        this.$router[routeAction]({ path: '/appointments', query: { appointmentId } });
      }
    },
    setDirtyStatus(isDirty, msg = null) {
      this.$route.meta.isDirty = isDirty;
      window.onbeforeunload = () =>
        isDirty
          ? msg || 'Are you sure you want to leave? You may have unsaved changes.'
          : undefined;
    },
    async checkDirtyConfirmation(msg = null) {
      let shouldLeave = await this.$confirm(
        msg || `You may have unsaved changes, are you sure you want to leave this page?`,
        {
          color: 'warning',
          buttonTrueText: 'Leave Without Saving'
        }
      ).then(confirmed => confirmed);

      if (shouldLeave) {
        this.setDirtyStatus(false);
      }

      return shouldLeave;
    },
    copyValueToClipboard(value) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value);
        this.notify('Copied to Clipboard!');
      } else {
        this.notify('Browser does not support copy function', 'error');
      }
    },
    makeAppointmentCreatorType(user) {
      if (!user) {
        return 'Unknown user';
      }
      return this.novaCore.isCarrierUser(user) ? 'Carrier' : 'Warehouse';
    },
    async $copyContent(contentToCopy, successMessage = 'Copied to clipboard', successCallback) {
      const hasCopyPermission = await navigator?.permissions?.query({ name: 'clipboard-write' });
      if (hasCopyPermission?.state === 'granted') {
        await navigator.clipboard.writeText(contentToCopy);
        this.notify(successMessage, 'info');
        if (successCallback) {
          successCallback();
        }
      } else {
        this.notify(
          'Permission to access clipboard was denied. To enable access, update your browser settings.',
          'error'
        );
      }
    }
  }
};
