var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "v-card",
            { staticClass: "text-center my-4", attrs: { width: "270" } },
            [
              _c(
                "v-card-text",
                [
                  _c("v-progress-circular", {
                    attrs: { color: "primary", indeterminate: "", size: "22" },
                  }),
                  _c("p", { staticClass: "small mt-4 font-weight-bold" }, [
                    _vm._v("Loading Asset Visits data..."),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            { attrs: { elevation: "0", width: "250" } },
            [
              _c("v-card-title", { staticClass: "d-flex" }, [
                _c(
                  "p",
                  { staticClass: "font-size-large font-weight-bold" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v("mdi-clock-outline"),
                    ]),
                    _vm._v(" Not assigned "),
                    _c("br"),
                    _c(
                      "v-chip",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          "data-testid": "yard-unassigned-list-carrier-count",
                        },
                      },
                      [
                        _c("span", { staticClass: "font-size-medium pa-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.assetVisits.length) +
                              " carrier" +
                              _vm._s(_vm.assetVisits.length !== 1 ? "s" : "") +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticClass: "font-size-medium font-weight-regular" },
                  [_vm._v(" Drag and drop carriers to assign them a spot. ")]
                ),
              ]),
              _c(
                "v-card-text",
                { staticClass: "pr-2 pl-4" },
                [
                  _c("v-divider", { staticClass: "mb-6" }),
                  _vm.assetVisits.length
                    ? _c(
                        "div",
                        _vm._l(_vm.assetVisits, function (assetVisit) {
                          return _c("yard-view-asset-visit", {
                            key: assetVisit.id,
                            attrs: { "asset-visit": assetVisit },
                          })
                        }),
                        1
                      )
                    : _c("div", [
                        _c("p", [_vm._v("All good, no drivers waiting.")]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }