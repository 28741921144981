<script>
import DateTimePickerBaseOld from '@satellite/modules/appointments/components/DateTimePickerBaseOld.vue';

/**
 * Date Time picker that uses availability from API based on existing appointment or create appointment form data
 * @displayName Date Time Picker
 */
export default {
  name: 'DateTimePickerOld',
  extends: DateTimePickerBaseOld,
  computed: {
    docks() {
      return this.selectedWarehouse.docks ?? [];
    }
  },
  watch: {
    selectedDock(newVal) {
      if (newVal && newVal.id !== this.appointment?.dockId) {
        this.selectedTime = null;
        this.$emit('input', this.selectedTime);
      }
    }
  }
};
</script>
