var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "asset-item-panel",
      attrs: {
        text: "",
        ripple: false,
        "data-testid": `asset-visit-panel-${_vm.visit.id}`,
        block: "",
      },
      on: { click: _vm.handleClick },
    },
    [
      _c("div", { staticClass: "flex-1 text-left" }, [
        _c(
          "div",
          { staticClass: "asset-item-title font-weight-bold" },
          [
            _vm._t(
              "title",
              function () {
                return [
                  !_vm.visit.checkInAcknowledged && _vm.allowAcknowledgement
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "pr-2",
                                              attrs: {
                                                disabled:
                                                  _vm.acknowledgementInProcess,
                                                "data-testid": `checkin-panel-acknowledge-button-${_vm.visit.id}`,
                                                size: "12px",
                                                color: "#FF9A1A",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.acknowledgeAssetVisit(
                                                    _vm.visit
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v(" mdi-circle ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1609060860
                              ),
                            },
                            [_vm._v(" Mark as read ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-bold font--poppins text--color-text-secondary font-size-x-small",
                    },
                    [_vm._v(" " + _vm._s(_vm.companyName) + " ")]
                  ),
                ]
              },
              { visit: _vm.visit }
            ),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "asset-item-meta d-flex" },
          [
            _vm.isPlanned
              ? [
                  _vm.isPlanned
                    ? _c("v-chip", { attrs: { "x-small": "" } }, [
                        _vm._v("Planned"),
                      ])
                    : _vm._e(),
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-1",
                      class: _vm.statusBgColorClass,
                      attrs: { "x-small": "" },
                    },
                    [_vm._v(_vm._s(_vm.statusLabel))]
                  ),
                ]
              : [
                  _c("v-chip", { attrs: { "x-small": "" } }, [
                    _vm._v("Unplanned"),
                  ]),
                  !_vm.isPlanned
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "ml-1",
                          class: { "disabled-chip": _vm.isPendingUpdate },
                          attrs: { "x-small": "" },
                        },
                        [
                          _vm.isRejected
                            ? _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-cancel"),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.unplannedUpdateTag) + " "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
            _c(
              "div",
              {
                staticClass:
                  "ml-2 latest-event font-size-x-small text--color-text-secondary",
                attrs: {
                  "data-testid": `asset-visit-latest-event-${_vm.visit.id}`,
                },
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1 text--color-neutral-60",
                    attrs: { small: "" },
                  },
                  [_vm._v("mdi-map-marker-outline")]
                ),
                _c("strong", { staticClass: "mr-1" }, [
                  _vm._v(_vm._s(_vm.latestEventType) + " at:"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.latestEventTime))]),
              ],
              1
            ),
          ],
          2
        ),
      ]),
      _vm.lastDriverMessageTimestamp
        ? _c(
            "div",
            {
              staticClass:
                "text--color-text-secondary font-size-x-small d-flex align-end flex-column mr-4",
              class:
                _vm.unreadMessagesCount > 0
                  ? "font-weight-bold"
                  : "font-weight-light",
              attrs: { "data-testid": `unread-message-count-${_vm.visit.id}` },
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", [_vm._v("mdi-comment-text-outline")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.makeLastDriverMessageTimestamp(
                          _vm.lastDriverMessageTimestamp
                        )
                      ) +
                      " "
                  ),
                ],
                1
              ),
              _vm.unreadMessagesCount
                ? _c(
                    "v-chip",
                    {
                      staticClass: "mt-1 unread-message-count-chip",
                      attrs: { "x-small": "", color: "primary" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.unreadMessagesCount < 100
                              ? _vm.unreadMessagesCount
                              : "99+"
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c("v-icon", { staticClass: "text--color-neutral-80" }, [
            _vm._v("mdi-chevron-right"),
          ]),
        ],
        1
      ),
      _vm.shouldShowDetails
        ? _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "asset-details-dialog",
                transition: "slide-left",
              },
              on: { "click:outside": _vm.handleCloseDialog },
              model: {
                value: _vm.shouldShowDetails,
                callback: function ($$v) {
                  _vm.shouldShowDetails = $$v
                },
                expression: "shouldShowDetails",
              },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-title align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.shouldShowDetails = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                  _c("span", { staticClass: "pl-3 headline" }, [
                    _vm._v("Arrival Details"),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.handleCloseDialog },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("div", { staticClass: "sidebar-content" }, [
                  _c(
                    "aside",
                    { staticClass: "sidebar" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-start text--color-text-secondary font-weight-bold",
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2 mt-1", attrs: { small: "" } },
                            [_vm._v("mdi-truck-outline")]
                          ),
                          _c("span", { staticClass: "font--poppins" }, [
                            _vm._v(" " + _vm._s(_vm.companyName) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 latest-event font-size-x-small text--color-text-secondary",
                          attrs: {
                            "data-testid": "asset-visit-details-latest-event",
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1 text--color-neutral-60",
                              attrs: { small: "" },
                            },
                            [_vm._v("mdi-map-marker-outline")]
                          ),
                          _c("strong", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.latestEventType) + " at:"),
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.latestEventTime))]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "asset-item-meta d-flex mt-2" },
                        [
                          _vm.isPlanned
                            ? _c("v-chip", { attrs: { "x-small": "" } }, [
                                _vm._v("Planned"),
                              ])
                            : [
                                _c("v-chip", { attrs: { "x-small": "" } }, [
                                  _vm._v("Unplanned"),
                                ]),
                                !_vm.isPlanned
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "ml-1",
                                        class: {
                                          "disabled-chip": _vm.isPendingUpdate,
                                        },
                                        attrs: { "x-small": "" },
                                      },
                                      [
                                        _vm.isRejected
                                          ? _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("mdi-cancel")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.unplannedUpdateTag) +
                                            " "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      ),
                      _vm.isPlanned ||
                      (!_vm.isPlanned && _vm.isAppointmentCreated)
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "internal-activator": true,
                                "close-on-click": true,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "outline-button",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  small: "",
                                                  "data-testid":
                                                    "asset-visit-details-actions-trigger",
                                                },
                                              },
                                              "outline-button",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(" Appointment "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("mdi-chevron-down")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1052051781
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "plain-items" },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "button-base",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          attrs: {
                                            text: "",
                                            plain: "",
                                            small: "",
                                            block: "",
                                            to: {
                                              name: "appointments",
                                              query: {
                                                appointmentId:
                                                  _vm.visit.appointmentId,
                                              },
                                            },
                                            ripple: false,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("mdi-eye")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "flex-1" },
                                            [_vm._v("View appointment")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  [
                                    _vm.novaCore.AppointmentStatus.Scheduled,
                                    _vm.novaCore.AppointmentStatus.Arrived,
                                  ].includes(_vm.visit.appointment.status)
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "button-base",
                                            {
                                              staticClass: "error--text",
                                              attrs: {
                                                text: "",
                                                plain: "",
                                                small: "",
                                                block: "",
                                                "data-testid": `asset-visit-panel-unlink-button`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleUnlinkConfirmDialog(
                                                    _vm.visit.id,
                                                    _vm.visit.appointmentId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-link-variant-off")]
                                              ),
                                              _vm._v(
                                                " Unlink From Appointment "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isPendingUpdate
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "internal-activator": true,
                                "close-on-click": true,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "primary-button",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mt-4",
                                                attrs: {
                                                  small: "",
                                                  "data-testid":
                                                    "asset-visit-details-actions-trigger",
                                                },
                                              },
                                              "primary-button",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(" Update Arrival "),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("mdi-chevron-down")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1556528456
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "plain-items" },
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "button-base",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          attrs: {
                                            "data-testid":
                                              "asset-visit-details-create-appointment-button",
                                            text: "",
                                            plain: "",
                                            ripple: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showCreateAppointmentDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-calendar-outline")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-grow-1 justify-start font-size-x-small",
                                            },
                                            [_vm._v(" Create appointment ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "button-base",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          attrs: {
                                            "data-testid":
                                              "asset-visit-details-link-appointment-button",
                                            text: "",
                                            plain: "",
                                            ripple: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "show-appointment-list-dialog"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-link-variant")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-grow-1 justify-start font-size-x-small",
                                            },
                                            [_vm._v(" Link to appointment ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "button-base",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-center",
                                          attrs: {
                                            "data-testid":
                                              "asset-visit-details-reject-load-button",
                                            text: "",
                                            plain: "",
                                            ripple: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.showRejectConfirm = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-cancel")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "flex-grow-1 justify-start font-size-x-small",
                                            },
                                            [_vm._v("Reject load")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-weight-bold font--poppins text--color-text-secondary mb-2 mt-8",
                        },
                        [_vm._v(" Asset Details ")]
                      ),
                      _c("asset-visit-form-data", {
                        attrs: {
                          "asset-visit": _vm.visit,
                          "is-list": "",
                          "is-inline": false,
                          "is-divided": "",
                          "bold-labels": false,
                          "allow-phone-edit": true,
                          "phone-title": "phone",
                          company: _vm.visit.company || {
                            name: _vm.visit.companyHint,
                          },
                          "company-title": "Company",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "content" },
                    [
                      _vm.warehouse?.id
                        ? _c("drivers-chat-window", {
                            attrs: {
                              "compact-mode": "",
                              "allow-phone-edit": false,
                              warehouse: _vm.warehouse,
                              "asset-visit": _vm.visit,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("create-appointment-dialog", {
        attrs: {
          "disable-activator": _vm.lockActions,
          "show-dialog": _vm.showCreateAppointmentDialog,
          "button-label": "Create appointment",
          "asset-visit": _vm.visit,
          "external-activator": "",
          context: { selectedWarehouse: _vm.warehouse },
        },
        on: {
          close: function ($event) {
            _vm.showCreateAppointmentDialog = false
          },
          scheduled: (appointment) =>
            _vm.handleNewAppointment(appointment, _vm.visit.id),
        },
      }),
      _vm.showRejectConfirm
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.showRejectConfirm,
              "is-manual-mode": "",
              persistent: "",
              title: "Reject load?",
              width: 650,
              "button-true-color": "primary",
              color: "warning",
            },
            on: { result: _vm.handleRejectResult },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "my-7",
                              attrs: {
                                text: "",
                                prominent: "",
                                border: "left",
                                color: "warning",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "#955800" },
                                },
                                [_vm._v("mdi-alert")]
                              ),
                              _c("span", { staticClass: "text--primary" }, [
                                _vm._v("This action cannot be undone"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "true-action",
                  fn: function ({ choose }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.rejectingLoader,
                          },
                          on: {
                            click: function ($event) {
                              return choose(true)
                            },
                          },
                        },
                        [_vm._v("Reject load")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3644109547
            ),
          })
        : _vm._e(),
      _vm.showUnlinkConfirm
        ? _c("confirm", {
            attrs: {
              "should-show": _vm.showUnlinkConfirm,
              "is-manual-mode": "",
              persistent: "",
              title: "Unlink from appointment?",
              width: 650,
              "button-true-color": "primary",
              color: "warning",
            },
            on: { result: _vm.handleUnlinkResult },
            scopedSlots: _vm._u(
              [
                {
                  key: "message",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "my-7",
                              attrs: {
                                text: "",
                                prominent: "",
                                border: "left",
                                color: "warning",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-2",
                                  attrs: { color: "#955800" },
                                },
                                [_vm._v("mdi-alert")]
                              ),
                              _c("span", { staticClass: "text--primary" }, [
                                _vm._v("This action cannot be undone"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "true-action",
                  fn: function ({ choose }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.unlinkingLoader,
                          },
                          on: {
                            click: function ($event) {
                              return choose(true)
                            },
                          },
                        },
                        [_vm._v(" Unlink from appointment ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              844610196
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }