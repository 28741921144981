var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-forms-data-panels" },
    [
      _vm.loadingDateTimes
        ? [_c("generic-loader", { staticClass: "pt-8" })]
        : [
            _c(
              "v-expansion-panels",
              { attrs: { flat: "", multiple: "" } },
              [
                _vm._l(_vm.renderedTriggers, function (trigger) {
                  return [
                    _vm.isLocked || trigger.isActive
                      ? _c(
                          "v-expansion-panel",
                          {
                            key: trigger.id,
                            class: {
                              "is-disabled":
                                !_vm.triggerDataLastChangedDateTimes[
                                  trigger.id
                                ] &&
                                !_vm.$scopedSlots[
                                  `additional-form-data-${trigger.id}`
                                ],
                            },
                          },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "actions",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2 icon",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("mdi-chevron-down")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", { staticClass: "header-content" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.novaCore.CustomFormsFeaturesData[
                                          trigger.feature
                                        ].title
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("v-spacer", { staticClass: "spacer" }),
                                _c(
                                  "div",
                                  { staticClass: "header-right" },
                                  [
                                    _vm.isLocked
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-lock")]
                                        )
                                      : _vm.editable
                                      ? [
                                          !_vm.triggerDataLastChangedDateTimes[
                                            trigger.id
                                          ]
                                            ? _c(
                                                "outline-button",
                                                {
                                                  staticClass: "form-button",
                                                  attrs: { small: "" },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      _vm.activeTrigger =
                                                        trigger
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Submit Form ")]
                                              )
                                            : [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "disclaimer",
                                                      },
                                                      [
                                                        _vm
                                                          .lastChangedDateTimesInTimezone[
                                                          trigger.id
                                                        ]
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  "x-small": "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " mdi-update "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .lastChangedDateTimesInTimezone[
                                                                trigger.id
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-4",
                                                        attrs: { icon: "" },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.activeTrigger =
                                                              trigger
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-pencil"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                        ]
                                      : _c(
                                          "span",
                                          { staticClass: "disclaimer" },
                                          [
                                            _vm.lastChangedDateTimesInTimezone[
                                              trigger.id
                                            ]
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: { "x-small": "" },
                                                  },
                                                  [_vm._v(" mdi-update ")]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm
                                                    .lastChangedDateTimesInTimezone[
                                                    trigger.id
                                                  ]
                                                ) +
                                                " "
                                            ),
                                            _vm._t(
                                              "additional-disclaimer",
                                              null,
                                              {
                                                trigger: trigger,
                                                lastChangedDateTimesInTimezone:
                                                  _vm.lastChangedDateTimesInTimezone,
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-expansion-panel-content",
                              [
                                _vm._t(`additional-form-data-${trigger.id}`),
                                _vm.triggerDataLastChangedDateTimes[trigger.id]
                                  ? _c("custom-forms-data", {
                                      key: trigger.id,
                                      attrs: {
                                        flat: "",
                                        "prepended-data":
                                          _vm.getStaticAssetDetailsFields(
                                            trigger
                                          ),
                                        trigger: trigger,
                                        "object-id": _vm.getObjectId(
                                          trigger.dataEntityName
                                        ),
                                        timezone: _vm.timezone,
                                        "military-time-enabled":
                                          _vm.militaryTimeEnabled,
                                      },
                                      on: {
                                        "customformdata-updated":
                                          _vm.updateTimestamp,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _c("edit-custom-form-dialog", {
              attrs: {
                "external-activator": true,
                warehouse: _vm.warehouse,
                "show-dialog": Boolean(_vm.activeTrigger),
                "object-id": _vm.getObjectId(_vm.activeTrigger?.dataEntityName),
                trigger: _vm.activeTrigger,
              },
              on: {
                close: _vm.cleanUpCustomFormsData,
                update: _vm.handleFormUpdate,
                create: _vm.handleFormCreate,
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }