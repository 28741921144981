<template>
  <div>
    <yard-view-no-areas v-if="!spotAreas.length" />

    <v-expansion-panels accordion multiple v-else>
      <v-expansion-panel
        v-for="spotArea of spotAreas"
        :key="spotArea.id"
        class="mb-4"
        :data-testid="`yard-main-area-${spotArea.id}`">
        <v-expansion-panel-header>
          <yard-view-spot-area-header
            :spot-area="spotArea"
            :data-testid="`yard-main-area-header-${spotArea.id}`" />
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <yard-view-spot-area
            :editable="false"
            :spot-area="spotArea"
            class="mt-6"
            :data-testid="`yard-main-area-spots-${spotArea.id}`" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="d-flex flex-row justify-end font-size-small" v-if="spotAreas.length > 0">
      <span class="ml-4">
        <v-icon small>{{ getSpotIcon(spotType.DOCKING) }}</v-icon>
        Docking spot
      </span>
      <span class="ml-4">
        <v-icon small>{{ getSpotIcon(spotType.PARKING) }}</v-icon>
        Parking spot
      </span>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import YardViewSpotArea from '@/modules/yard/components/SpotArea/YardViewSpotArea.vue';
import YardViewNoAreas from '@/modules/yard/components/Overlays/YardViewNoAreas.vue';
import { useRouter } from 'vue-router/composables';
import YardViewSpotAreaHeader from '@/modules/yard/components/SpotArea/YardViewSpotAreaHeader.vue';
import { useEventHub, useSockets } from '@/composables';

export default defineComponent({
  name: 'YardViewYard',
  components: { YardViewSpotAreaHeader, YardViewNoAreas, YardViewSpotArea },
  props: {},
  setup() {
    const yardStore = useYard();
    const router = useRouter();
    const sockets = useSockets();
    const eventHub = useEventHub();

    const selectedWarehouse = yardStore.selectedWarehouseRef;

    const spotAreas = computed(() =>
      yardStore.spotAreasRef.value.filter(spotArea =>
        yardStore.selectedAreasRef.value.includes(spotArea.id)
      )
    );

    if (selectedWarehouse.value?.id) {
      router.push({ name: 'yard-warehouse', params: { warehouseId: selectedWarehouse.value.id } });
    }

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-SpotArea`, yardStore.handleSpotAreaSubspaceEvent);
        eventHub.$on(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
      });
      yardStore.selectedSpotRef.value = null;
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-SpotArea`, yardStore.handleSpotAreaSubspaceEvent);
        eventHub.$off(`${operation}-Spot`, yardStore.handleSpotSubspaceEvent);
      });
    });

    return {
      onBeforeMount,
      onBeforeUnmount,
      selectedWarehouse,
      spotAreas,
      spotType: yardStore.spotType,
      getSpotIcon: yardStore.getSpotIcon,
      selectedSpot: yardStore.selectedSpotRef
    };
  }
});
</script>
