import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';

export default {
  async getOneById(spotId, params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`yard/spot/${spotId}?${queryString}`, {
      params: {
        ...params,
        join: [
          'spotEvents',
          'spotEvents.createdBy||firstName,lastName,email',
          'spotAssignments',
          'spotAssignments.assetVisit',
          'spotAssignments.assetVisit.assetVisitEvents||eventType,createDateTime,id'
        ]
      }
    });
    return getResponse(response, options);
  },
  async getManyByWarehouseId(warehouseId, params = {}, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`yard/spot?${queryString}`, {
      params: { ...params, warehouseId }
    });

    return getResponse(response, options);
  },
  async create(data, options = {}) {
    const response = await axios.post('yard/spot', data);
    return getResponse(response, options);
  },
  async createMany(data, options = {}) {
    const response = await axios.post('yard/spot/generate', data);
    return getResponse(response, options);
  },
  async close(spotId, data, options = {}) {
    const response = await axios.patch(`yard/spot/${spotId}/close`, data);
    return getResponse(response, options);
  },
  async open(spotId, data, options = {}) {
    const response = await axios.patch(`yard/spot/${spotId}/open`, data);
    return getResponse(response, options);
  },
  async delete(spotId, options = {}) {
    const response = await axios.delete(`yard/spot/${spotId}`);
    return getResponse(response, options);
  },
  async update(spotId, data, options = {}) {
    const response = await axios.patch(`yard/spot/${spotId}`, data);
    return getResponse(response, options);
  },
  async createSpotAssignment(data, options = {}) {
    const response = await axios.post('yard/spot-assignment', data);
    return getResponse(response, options);
  },
  async deleteSpotAssignment(spotAssignmentId, options = {}) {
    const response = await axios.delete(`yard/spot-assignment/${spotAssignmentId}`);
    return getResponse(response, options);
  },
  async departSpotAssignment(assetVisitId, options = {}) {
    const response = await axios.patch(`yard/spot-assignment/${assetVisitId}/depart`);
    return getResponse(response, options);
  }
};
