var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.selectedSpot?.id
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { attrs: { elevation: "0", width: "270" } },
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex flex-row pa-3 pb-0" },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "font-weight-bold text-uppercase",
                      attrs: { "data-testid": "yard-spot-detail-title" },
                    },
                    [_vm._v(" " + _vm._s(_vm.spot.code) + " ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        "data-testid": "yard-spot-detail-close-button",
                      },
                      on: { click: _vm.closeDetail },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "pr-2 pl-4 pt-3",
                  attrs: { "data-testid": "yard-spot-detail-content" },
                },
                [
                  !_vm.isSpotAssigned(_vm.spot)
                    ? _c("h2", { staticClass: "text-capitalize" }, [
                        _vm._v(" " + _vm._s(_vm.getSpotStatus(_vm.spot)) + " "),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _vm.isSpotOpen(_vm.spot)
                        ? _c("yard-view-spot-detail-open")
                        : _vm._e(),
                      _vm.isSpotClosed(_vm.spot)
                        ? _c("yard-view-spot-detail-closed")
                        : _vm._e(),
                      _vm.isSpotAssigned(_vm.spot)
                        ? _c("yard-view-spot-detail-assigned", {
                            attrs: { "spot-assignment": _vm.spotAssignment },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "card-actions",
                  attrs: { "data-testid": "yard-spot-detail-actions" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column full-width" },
                    [
                      _vm.isSpotClosed(_vm.spot)
                        ? _c(
                            "v-btn",
                            {
                              attrs: { block: "", color: "primary" },
                              on: { click: _vm.openSpot },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-check")]
                              ),
                              _vm._v(" Open This Spot "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSpotOpen(_vm.spot)
                        ? _c(
                            "v-btn",
                            {
                              attrs: { block: "", outlined: "" },
                              on: { click: _vm.closeSpot },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("mdi-close")]
                              ),
                              _vm._v(" Close this Spot "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSpotAssigned(_vm.spot)
                        ? [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-4",
                                attrs: {
                                  disabled: "",
                                  block: "",
                                  color: "primary",
                                },
                                on: { click: _vm.departAssetVisit },
                              },
                              [_vm._v(" Reassign Spot ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-4",
                                attrs: { block: "", outlined: "" },
                                on: { click: _vm.departAssetVisit },
                              },
                              [_vm._v("Truck Departed")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", small: "" },
                                on: { click: _vm.deleteSpotAssignment },
                              },
                              [_vm._v("Unassign")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _c("yard-view-spot-status-dialog", {
            attrs: { "status-type": _vm.dialogStatusOpen, spot: _vm.spot },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }