var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "chat-container",
      class: _vm.compactMode ? "compact-mode" : "",
    },
    [
      _c("header", { staticClass: "chat-header px-6 py-4" }, [
        _vm.assetVisit.id &&
        _vm.novaCore.hasWarehouseCheckinEnabled(_vm.warehouse)
          ? _c(
              "div",
              { staticClass: "header-left" },
              [
                _vm._v(" Chat with "),
                _c(
                  "strong",
                  { attrs: { "data-testid": _vm.makeTestId("phone-number") } },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-1",
                        attrs: { color: "black", small: "" },
                      },
                      [_vm._v("mdi-cellphone")]
                    ),
                    _vm._v(" " + _vm._s(_vm.assetVisit.phone) + " "),
                  ],
                  1
                ),
                !_vm.isPhoneEditMode && _vm.allowPhoneEdit
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "edit-phone-btn px-1 pb-1",
                        attrs: {
                          "data-testid": _vm.makeTestId("edit-phone-btn"),
                          text: "",
                          small: "",
                        },
                        on: { click: _vm.enablePhoneEditMode },
                      },
                      [_vm._v(" Edit ")]
                    )
                  : _vm._e(),
                !_vm.assetVisit.phone.startsWith("+1") && !_vm.isPhoneEditMode
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on: onTooltip, attrs }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "font-size-x-small ml-2 country-code-warning",
                                          attrs: {
                                            "data-testid": _vm.makeTestId(
                                              "unsupported-number-error"
                                            ),
                                          },
                                        },
                                        "span",
                                        attrs,
                                        false
                                      ),
                                      onTooltip
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "error" } },
                                        [_vm._v("mdi-alert-circle-outline")]
                                      ),
                                      _vm._v(" Country code not supported "),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1813737479
                        ),
                      },
                      [
                        _c("span", [
                          _vm._v(
                            'At this time only numbers starting with country code "+1" are supported.'
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.isPhoneEditMode
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-end mt-3" },
                      [
                        _c("phone-number-field", {
                          ref: "changePhoneNumberFieldRef",
                          attrs: {
                            id: "change-phone-number-field",
                            "data-testid": _vm.makeTestId(
                              "change-driver-phone-field"
                            ),
                            validator: _vm.$validator,
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            placeholder: "Driver's Phone",
                            label: null,
                          },
                          model: {
                            value: _vm.newAssetVisitPhone,
                            callback: function ($$v) {
                              _vm.newAssetVisitPhone = $$v
                            },
                            expression: "newAssetVisitPhone",
                          },
                        }),
                        _c(
                          "outline-button",
                          {
                            staticClass: "ml-2",
                            on: {
                              click: function ($event) {
                                _vm.isPhoneEditMode = false
                              },
                            },
                          },
                          [_vm._v("Nevermind")]
                        ),
                        _c(
                          "primary-button",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "data-testid": _vm.makeTestId(
                                "save-driver-phone-btn"
                              ),
                              disabled: !_vm.newAssetVisitPhone,
                            },
                            on: { click: _vm.saveNewAssetVisitPhone },
                          },
                          [_vm._v(" Save ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.assetVisit.id &&
        _vm.novaCore.hasWarehouseCheckinEnabled(_vm.warehouse)
          ? _c("div", { staticClass: "header-right" }, [
              _vm.isThreadExpired
                ? _c("div", [
                    _c("strong", { staticClass: "font-size-xx-small" }, [
                      _vm._v(
                        " This chat expired " +
                          _vm._s(
                            _vm.makeChatTimestamp(
                              _vm.messageThreadRef.expiresAt,
                              _vm.warehouse
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _c(
                    "div",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        "data-testid": _vm.makeTestId(
                          "toggle-chat-open-status"
                        ),
                      },
                      on: { click: _vm.handleChatStatusClick },
                    },
                    [
                      _vm.compactMode
                        ? [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", right: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  "x-small": "",
                                                  color: "black",
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " mdi-" +
                                                  _vm._s(
                                                    _vm.messageThreadRef.isOpen
                                                      ? "comment-off-outline"
                                                      : "comment-outline"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3535177181
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.messageThreadRef.isOpen
                                        ? "End this chat"
                                        : "Resume this chat"
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { "x-small": "", color: "black" },
                              },
                              [
                                _vm._v(
                                  " mdi-" +
                                    _vm._s(
                                      _vm.messageThreadRef.isOpen
                                        ? "comment-off-outline"
                                        : "comment-outline"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            !_vm.compactMode
                              ? _c(
                                  "strong",
                                  { staticClass: "font-size-xx-small" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.messageThreadRef.isOpen
                                            ? "End this chat"
                                            : "Resume this chat"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
            ])
          : !_vm.novaCore.hasWarehouseCheckinEnabled(_vm.warehouse)
          ? _c("div", [
              _c(
                "div",
                { staticClass: "font-size-x-small text--color-neutral-80" },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-information-outline"),
                  ]),
                  _vm._v(
                    " The Check-In feature must be enabled for this warehouse to send SMS messages. "
                  ),
                ],
                1
              ),
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "font-size-x-small text--color-neutral-80" },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-information-outline"),
                  ]),
                  _vm._v(
                    " Wait for the driver's check-in to enable the chat. "
                  ),
                ],
                1
              ),
            ]),
      ]),
      _c(
        "div",
        {
          ref: "chatStageRef",
          staticClass: "chat-stage pa-5",
          attrs: { "data-testid": _vm.makeTestId("chat-stage") },
          on: { scroll: _vm.setStagePositionValues },
        },
        [
          _vm.loading
            ? _c(
                "v-progress-linear",
                {
                  attrs: {
                    id: "message-loader",
                    height: "25",
                    color: "primary",
                    indeterminate: "",
                    rounded: "",
                  },
                },
                [_c("strong", [_vm._v("Loading messages...")])]
              )
            : _c(
                "div",
                [
                  _vm.assetVisit.id
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-center font-size-xx-small text--color-neutral-80",
                        },
                        [
                          _vm._v(
                            " The driver will receive and respond to your messages via SMS at the phone number provided during check-in. "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(
                    _vm.messageThreadRef.messages,
                    function (message, idx) {
                      return _c(
                        "div",
                        { key: message.id },
                        [
                          _c("drivers-chat-message", {
                            attrs: {
                              message: message,
                              idx: idx,
                              "compact-mode": _vm.compactMode,
                              warehouse: _vm.warehouse,
                              "message-thread": _vm.messageThreadRef,
                              assetVisit: _vm.assetVisit,
                            },
                          }),
                          _vm._l(
                            _vm.makeNextEventItems(message),
                            function (event) {
                              return _c(
                                "div",
                                {
                                  key: event.createDateTime,
                                  staticClass:
                                    "text-center font-size-xx-small text--color-neutral-80 mt-3 event-message",
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.makeChatTimestamp(
                                          event.createDateTime,
                                          _vm.warehouse
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(event.content) + " "),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    }
                  ),
                  _vm.isThreadExpired
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-center font-size-xx-small text--color-neutral-80 mt-3 event-message",
                        },
                        [
                          _c("strong", { staticClass: "d-block" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.makeChatTimestamp(
                                    _vm.messageThreadRef.expiresAt,
                                    _vm.warehouse
                                  )
                                ) +
                                " "
                            ),
                          ]),
                          _vm._v(
                            " This chat expired. You can no longer send messages. "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
        ],
        1
      ),
      _vm.messageThreadRef.isOpen
        ? _c(
            "footer",
            { staticClass: "chat-footer px-6 py-5" },
            [
              _vm.messageThreadRef.unreadMessagesCount && !_vm.isChatNearBottom
                ? _c(
                    "v-btn",
                    {
                      staticClass: "new-messages-scroll-btn",
                      attrs: {
                        "data-testid": _vm.makeTestId(
                          "scroll-to-new-messages-btn"
                        ),
                        text: "",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.scrollToLatestMsg()
                        },
                      },
                    },
                    [
                      _vm._v(" New Messages "),
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-arrow-down"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { attrs: { id: "new-message-field" } },
                [
                  _c(
                    "v-form",
                    { ref: "newMessageFormRef", staticClass: "pt-0" },
                    [
                      _c("v-textarea", {
                        staticClass: "mt-0",
                        attrs: {
                          "row-height": "24",
                          rows: "1",
                          "auto-grow": "",
                          "validate-on-blur": "",
                          outlined: "",
                          "no-resize": "",
                          "data-testid": _vm.makeTestId("new-message-field"),
                          counter: _vm.maxMessageLength,
                          rules: _vm.newMessageRulesRef,
                          disabled: !_vm.assetVisit || _vm.isThreadExpired,
                          dense: "",
                          placeholder: _vm.isThreadExpired
                            ? "Message thread expired"
                            : "Message the driver...",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            )
                              return null
                            $event.preventDefault()
                            return _vm.handleMessageSubmit.apply(
                              null,
                              arguments
                            )
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-inner",
                              fn: function () {
                                return [
                                  _vm.attachedFile && _vm.attachedImageThumbSrc
                                    ? _c(
                                        "v-img",
                                        {
                                          ref: "attachedFileThumbRef",
                                          staticClass: "mt-1",
                                          attrs: {
                                            "data-testid": _vm.makeTestId(
                                              "attached-file-preview"
                                            ),
                                            id: "attached-file",
                                            "max-height": "60",
                                            "aspect-ratio": "1",
                                            "max-width": "60",
                                            src: _vm.attachedImageThumbSrc,
                                            width: "60",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "remove-attachment-icon",
                                              attrs: {
                                                "data-testid":
                                                  _vm.makeTestId(
                                                    "remove-file-icon"
                                                  ),
                                              },
                                              on: {
                                                click: _vm.removeAttachedFile,
                                              },
                                            },
                                            [_vm._v(" mdi-close-circle ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.attachedFile
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "attached-file-box pa-2 font-size-xx-small mt-1",
                                          attrs: {
                                            "data-testid": _vm.makeTestId(
                                              "attached-file-preview"
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("generic-text-icon", {
                                                staticClass: "py-2 px-1 mr-1",
                                                attrs: {
                                                  text: _vm.attachedFile.name
                                                    .split(".")
                                                    .pop(),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-black file-box-name mb-2",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.novaCore.truncateString(
                                                        _vm.attachedFile.name,
                                                        16
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "file-box-size" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    Math.round(
                                                      _vm.attachedFile.size /
                                                        1000
                                                    )
                                                  ) + "kb"
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "remove-attachment-icon",
                                              attrs: {
                                                "data-testid":
                                                  _vm.makeTestId(
                                                    "remove-file-icon"
                                                  ),
                                              },
                                              on: {
                                                click: _vm.removeAttachedFile,
                                              },
                                            },
                                            [_vm._v(" mdi-close-circle ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "append",
                              fn: function () {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        "data-testid":
                                          _vm.makeTestId("attach-file-icon"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.fileInputRef.click()
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-paperclip ")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2826874431
                        ),
                        model: {
                          value: _vm.newMessageInputValue,
                          callback: function ($$v) {
                            _vm.newMessageInputValue = $$v
                          },
                          expression: "newMessageInputValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("input", {
                    ref: "fileInputRef",
                    staticClass: "d-none",
                    attrs: {
                      type: "file",
                      "data-testid": _vm.makeTestId("file-input"),
                      accept: _vm.allowedFileTypes.join(","),
                    },
                    on: { change: _vm.handleFileChange },
                  }),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: {
                    fab: "",
                    text: "",
                    small: "",
                    "data-testid": _vm.makeTestId("send-message-btn"),
                    disabled: !_vm.newMessageInputValue && !_vm.attachedFile,
                    loading: _vm.sendingMessage,
                    id: "message-send-btn",
                  },
                  on: { click: _vm.handleMessageSubmit },
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-send")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }