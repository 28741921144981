<template>
  <div v-if="selectedSpot?.id">
    <v-card elevation="0" width="270">
      <v-card-title class="d-flex flex-row pa-3 pb-0">
        <h1 class="font-weight-bold text-uppercase" data-testid="yard-spot-detail-title">
          {{ spot.code }}
        </h1>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDetail" data-testid="yard-spot-detail-close-button">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pr-2 pl-4 pt-3" data-testid="yard-spot-detail-content">
        <h2 class="text-capitalize" v-if="!isSpotAssigned(spot)">
          {{ getSpotStatus(spot) }}
        </h2>

        <div class="mt-4">
          <yard-view-spot-detail-open v-if="isSpotOpen(spot)" />
          <yard-view-spot-detail-closed v-if="isSpotClosed(spot)" />
          <yard-view-spot-detail-assigned
            :spot-assignment="spotAssignment"
            v-if="isSpotAssigned(spot)" />
        </div>
      </v-card-text>

      <v-card-actions class="card-actions" data-testid="yard-spot-detail-actions">
        <div class="d-flex flex-column full-width">
          <v-btn block color="primary" @click="openSpot" v-if="isSpotClosed(spot)">
            <v-icon small class="mr-2">mdi-check</v-icon>
            Open This Spot
          </v-btn>
          <v-btn block outlined @click="closeSpot" v-if="isSpotOpen(spot)">
            <v-icon small class="mr-2">mdi-close</v-icon>
            Close this Spot
          </v-btn>
          <template v-if="isSpotAssigned(spot)">
            <v-btn disabled block color="primary" @click="departAssetVisit" class="mb-4">
              Reassign Spot
            </v-btn>
            <v-btn block outlined @click="departAssetVisit" class="mb-4">Truck Departed</v-btn>
            <v-btn text small @click="deleteSpotAssignment">Unassign</v-btn>
          </template>
        </div>
      </v-card-actions>
    </v-card>
    <yard-view-spot-status-dialog :status-type="dialogStatusOpen" :spot="spot" />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import YardViewSpotStatusDialog from '@/modules/yard/components/Spot/YardViewSpotStatusDialog.vue';
import { useEventHub } from '@/composables';
import YardViewSpotDetailOpen from '@/modules/yard/components/Spot/Detail/YardViewSpotDetailOpen.vue';
import YardViewSpotDetailClosed from '@/modules/yard/components/Spot/Detail/YardViewSpotDetailClosed.vue';
import YardViewSpotDetailAssigned from '@/modules/yard/components/Spot/Detail/YardViewSpotDetailAssigned.vue';

export default defineComponent({
  name: 'YardViewSpotDetail',
  components: {
    YardViewSpotDetailAssigned,
    YardViewSpotDetailClosed,
    YardViewSpotDetailOpen,
    YardViewSpotStatusDialog
  },
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  setup() {
    const yardStore = useYard();
    const eventHub = useEventHub();
    const selectedWarehouse = computed(() => yardStore.selectedWarehouse);
    const spotAssignment = computed(() => yardStore.selectedSpotRef.value.spotAssignments[0]);
    const dialogStatusOpen = ref('');

    const closeSpot = () => {
      dialogStatusOpen.value = 'close';
    };

    const openSpot = () => {
      dialogStatusOpen.value = 'open';
    };

    const departAssetVisit = () => {
      yardStore.departSpotAssignment(spotAssignment.value.id);
    };

    const deleteSpotAssignment = () => {
      yardStore.deleteSpotAssignment(spotAssignment.value.id);
    };

    const closeDetail = () => {
      yardStore.selectedSpotRef.value = null;
    };

    const getSpotStatus = spot => {
      return yardStore.getSpotStatus(spot);
    };

    onMounted(() => {
      eventHub.$on('close-spot-status-dialog', () => {
        dialogStatusOpen.value = '';
      });
    });

    onBeforeUnmount(() => {
      eventHub.$off('close-spot-status-dialog');
    });

    return {
      selectedWarehouse,
      openSpot,
      closeSpot,
      closeDetail,
      getSpotStatus,
      deleteSpotAssignment,
      departAssetVisit,
      isSpotAssigned: yardStore.isSpotAssigned,
      isSpotClosed: yardStore.isSpotClosed,
      isSpotOpen: yardStore.isSpotOpen,
      spotAssignment,
      dialogStatusOpen,
      latestClosedEvent: yardStore.latestClosedEventRef,
      selectedSpot: yardStore.selectedSpotRef,
      spotStatus: yardStore.spotStatus
    };
  }
});
</script>
<style lang="scss" scoped>
.card-actions {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $white;
  z-index: 1000;
}
</style>
