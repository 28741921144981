<template>
  <div class="d-flex flex-row align-center mb-4 full-width" data-testid="grid-warehouse-select">
    <h3 v-if="label" :class="disabled ? 'text--disabled' : ''" class="mr-4">
      {{ label }}:
      <help-icon-tooltip v-if="tooltipText">
        {{ tooltipText }}
      </help-icon-tooltip>
    </h3>
    <v-autocomplete
      :data-testid="$attrs.dataTestid ?? 'warehouse-select'"
      dense
      height="25px"
      :prepend-icon="!hideIcon ? 'mdi-warehouse' : ''"
      class="pt-0 warehouse-select"
      :value="value"
      @input="updateValue"
      :items="warehouses"
      :item-disabled="isItemDisabled"
      :search-input.sync="filters.searchStr"
      @update:search-input="options.page = 1"
      no-filter
      :loading="loading || externalLoading"
      del="search"
      item-value="id"
      :multiple="multiSelect"
      :label="customPersistentLabel ?? placeholder"
      return-object
      :clearable="clearable"
      @click:clear="clearSelection"
      :disabled="disabled"
      hide-details="auto"
      :single-line="!customPersistentLabel">
      <template #label v-if="required">
        {{ placeholder }}
        <span class="red--text"><strong>*</strong></span>
      </template>

      <template v-slot:item="{ item }">
        {{ getWarehouseName(item) }}
      </template>

      <template v-slot:selection="{ item, index }">
        <template v-if="multiSelect">
          <span v-if="index === 0">{{ item.name }}</span>
          <span v-if="index === 1" class="grey--text text-caption ml-2">
            (+{{ value.length - 1 }} others)
          </span>
        </template>
        <template v-else>
          <section v-if="!!value" class="text-truncate">
            {{ item.name }}
          </section>
        </template>
      </template>

      <template v-slot:no-data>
        <v-list-item v-if="filters.searchStr && !filters.searchStr.length">
          <v-list-item-title>
            <span class="d-block pt-2">
              Looks like your org doesn't have a warehouse set up yet ...
            </span>
            <router-link
              :to="{
                name: 'warehouses',
                props: true,
                params: { shouldShowCreateWarehouseDialog: true }
              }"
              class="full-width v-btn primary v-size--default mt-2"
              @click.native="handleCreateWarehouseClick">
              Let's Create a warehouse
            </router-link>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-title>
            <span class="d-block pt-2">No warehouses found matching this criteria...</span>
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append-item>
        <template v-if="warehouseDoesNotHaveDocks && showCreateDockButton && !orgId">
          <v-list-item>
            <v-list-item-title>
              <span class="d-block pt-2">
                Looks like your warehouse doesn't have a dock set up yet ...
              </span>
              <router-link
                v-if="warehouses.length"
                :to="{
                  name: 'warehouses.details.docks',
                  props: true,
                  params: { warehouseId: warehouses[0].id, shouldShowCreateDockForm: true }
                }"
                class="full-width v-btn primary v-size--default mt-2"
                @click.native="handleCreateDockClick">
                Let's Create a Dock
              </router-link>
            </v-list-item-title>
          </v-list-item>
        </template>

        <div v-intersect="onIntersect" :options="{ threshold: [0, 0.5, 1.0] }"></div>

        <template v-if="loading && warehouses.length < warehouses.total">
          <v-divider></v-divider>

          <v-container class="v-list-item">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-subtitle-2 text-center pa-0" cols="12">
                Loading More Warehouses...
              </v-col>
              <v-col cols="12">
                <v-progress-linear indeterminate class="py-0"></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import { debounce } from 'debounce';
import { isUUID } from 'class-validator';

export default {
  mixins: [selectMixin, dataListMixin],
  props: {
    /**
     * @model
     */
    value: {
      type: [Object, Array],
      required: false,
      default: null
    },
    /**
     * Disable the select
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Show the wh store / facility number in the dropdown
     */
    showStoreNumber: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * An array of WH IDs that should be disabled
     */
    disabledWarehouseIds: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Array of Warehouse IDs that a user can access if they are a restricted user
     */
    restrictedWarehouseIds: {
      type: Array,
      required: false,
      default: null
    },
    /**
     * If items should be disabled if they have no docks
     */
    shouldDisableIfNoDocks: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * If items should be disabled if they have no docks
     */
    showCreateDockButton: {
      type: Boolean,
      required: false,
      default: true
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    shouldAutoSelectIfSingleItem: {
      type: Boolean,
      required: false,
      default: false
    },
    shouldAutoSelectFirst: {
      type: Boolean,
      required: false,
      default: false
    },
    multiSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    orgId: {
      type: String,
      required: false
    },
    customPersistentLabel: {
      type: String,
      required: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    // Pass an uuid for the component to select
    // from the list, if found
    autoSelectById: {
      type: String,
      required: false
    },
    externalLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    warehouseDoesNotHaveDocks() {
      return this.warehouses.length === 1 && !this.getActiveDocks(this.warehouses[0]).length;
    }
  },
  data() {
    return {
      loading: false,
      placeholder: 'Search Warehouses',
      warehousesLoaded: false,
      warehouses: [],
      warehouseTotal: 0,
      pageCount: 0,
      searchFields: ['name', 'facilityNumber'],
      options: {
        page: 1,
        itemsPerPage: 10
      },
      filters: {
        // v-autocomplete automatically sets it to null, so initializing it the same way avoids hitting the API unnecessarily as a side-effect
        searchStr: null
      },
      sortBy: ['name'],
      mounted: false,
      shouldUseDefaultFilterWatcher: false
    };
  },
  methods: {
    /**
     * v-model
     */
    updateValue(val) {
      this.$emit('input', val || {});
    },
    getActiveDocks(warehouse) {
      if (!warehouse.docks) {
        return [];
      }

      return warehouse.docks.filter(dock => dock.isActive);
    },
    /**
     * returns dock count of warehouse
     * @public
     * @property {object} warehouse
     */
    getWarehouseDockCount(warehouse) {
      return this.getActiveDocks(warehouse).filter(dock => !this.novaCore.isCapacityChild(dock))
        .length;
    },
    /**
     * Returns warehouse name with dock count
     */
    getWarehouseName(warehouse) {
      const dockCount = this.getWarehouseDockCount(warehouse);
      const dockNoun = dockCount !== 1 ? 'docks' : 'dock';
      let displayName =
        dockCount > 0
          ? `${warehouse.name} (${dockCount} ${dockNoun})`
          : `${warehouse.name} (No ${dockNoun})`;

      if (this.showStoreNumber && warehouse.facilityNumber) {
        displayName = `${displayName} #${warehouse.facilityNumber}`;
      }

      return displayName;
    },
    /**
     * Returns if item should be disabled based on props
     * @public
     */
    isItemDisabled(warehouse) {
      const disabledForDockCount =
        this.shouldDisableIfNoDocks && this.getWarehouseDockCount(warehouse) <= 0;
      const disabledById = this.disabledWarehouseIds.includes(warehouse.id);

      return disabledForDockCount || disabledById;
    },
    /**
     * Get warehouse data
     * @public
     * @property {boolean} setWarehouses This is used on initial fetch. We don't want to reset the warehouses when loading more, but instead want to add to the moreWarehouses array
     */
    async getData(setWarehouses = false) {
      this.loading = true;
      try {
        const response = await this.services.warehouse.getWarehouses(this.getQuery(), {
          ...this.services.warehouse.requestOptions.grid,
          includeMetaData: true
        });

        if (response?.data) {
          if (setWarehouses) {
            this.warehouses = response.data?.data;
          }
          this.pageCount = response.data.pageCount;
          this.warehouseTotal = response.data.total;
          return response.data?.data;
        }
      } finally {
        this.loading = false;
      }
    },
    getQuery() {
      const query = this.getQueryBase();
      // Restrict to current user's Warehouse Access List
      if (this.restrictedWarehouseIds) {
        query.s = {
          ...query.s,
          id: { $in: this.restrictedWarehouseIds }
        };
      }

      if (isUUID(this.orgId)) {
        query.s = {
          ...query.s,
          orgId: { $eq: this.orgId }
        };
      }

      return query;
    },
    clearSelection() {
      this.filters.searchStr = '';
      this.options.page = 1;
      this.updateValue({});
      this.$emit('clear-warehouse');
    },
    /**
     * Increment pagination page and fetch more warehouses
     * @returns {Promise<void>}
     */
    async loadMoreWarehouses() {
      if (this.pageCount > 0 && this.options.page < this.pageCount) {
        if (!this.options.page) {
          this.options.page = 2;
        } else {
          this.options.page++;
        }
        const moreWarehouses = await this.getData();
        moreWarehouses.forEach(w => this.warehouses.push(w));
      }
    },
    /**
     * When element placed at end of warehouse select is intersecting, fetch more warehouses if more exist
     * @param entries
     * @param observer
     * @param isIntersecting
     * @returns {Promise<void>}
     */
    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.warehouses.length < this.warehouseTotal) {
        await this.loadMoreWarehouses();
      }
    },
    async handleDataRefresh(val) {
      // It is safer to restore the initial behavior when a change happens,
      // because we don't know what changed and the user can start again.
      this.filters.searchStr = '';
      this.options.page = 1;
      this.updateValue(val);
      await this.getData(true);
    },
    handleCreateWarehouseClick() {
      this.$emit('close');
      if (this.$route.name === 'warehouses') {
        this.$eventHub.$emit('show-create-warehouse-dialog');
      }
    },
    handleCreateDockClick() {
      this.$emit('close');
      if (this.$route.name === 'warehouses.details.docks') {
        this.$eventHub.$emit('show-create-dock-dialog');
      }
    },
    selectDefault() {
      if (this.warehouses?.length === 1 && !this.value?.id) {
        this.updateValue(this.warehouses[0]);
      }
    },
    shouldGetData() {
      return this.mounted;
    },
    debounceInput: debounce(async function (setWarehouses = false) {
      await this.getData(setWarehouses);
    }, 350)
  },
  async mounted() {
    if (
      !this.$warehouses.length ||
      !this.$warehouses.some(w => w.schedule && w.docks?.length > 0)
    ) {
      // If the list is already filled, say, by other select, do not load it again initially.
      await this.getData(true);
    }

    // When the component loads, it always loads the first page (15),
    // in case the selected warehouse is not at the first page, we do inject
    // into the warehouse list, so it can be selected automatically
    // when page starts.
    if (this.value && Object.keys(this.value).length > 0) {
      const selectedWarehouseIsOnList = Boolean(this.warehouses.find(w => w.id === this.value.id));
      const hasSelection = Boolean(this.$selectedWarehouse?.id);

      if (hasSelection && !selectedWarehouseIsOnList) {
        this.warehouses.unshift(this.$selectedWarehouse);
      }
    }

    if (this.shouldAutoSelectIfSingleItem) {
      this.selectDefault();
    }

    if (this.shouldAutoSelectFirst && !this.value?.id) {
      this.updateValue(this.warehouses[0]);
    }

    if (this.autoSelectById && !this.value?.id) {
      const autoSelectedWarehouse = this.warehouses.find(w => w.id === this.autoSelectById);
      if (autoSelectedWarehouse?.id) {
        this.updateValue(autoSelectedWarehouse);
      }
    }

    this.$nextTick(() => {
      this.mounted = true;
    });

    Object.entries(this.sockets.actions).map(entry => {
      let action = entry[0];
      this.$eventHub.$on(`${action}-Warehouse`, async payload => {
        let warehouse = this.value;
        if (action !== 'delete' && this.value?.id === payload?.id) {
          warehouse = await this.$store.dispatch('Warehouses/getWarehouseById', payload.id);
        }
        await this.handleDataRefresh(warehouse);
      });
    });
  },
  beforeDestroy() {
    Object.entries(this.sockets.actions).map(entry => {
      let action = entry[0];
      this.$eventHub.$off(`${action}-Warehouse`);
    });
  },
  watch: {
    async 'filters.searchStr'() {
      await this.debounceInput(true);
    },
    warehouses() {
      if (!this.warehouses?.length && !this.filters?.searchStr) {
        this.updateValue({});
      }
    },
    value(newVal, oldVal) {
      const isValueBeingNulled = oldVal && !newVal;
      const isValueObjBeingCleared =
        oldVal &&
        newVal &&
        Object.keys(oldVal).length > 0 &&
        newVal &&
        Object.keys(newVal).length === 0;

      if (isValueBeingNulled || isValueObjBeingCleared) {
        this.clearSelection();
      }
    },
    orgId(newVal, oldVal) {
      // From Lucas: This one is used on the log in as a carrier feature.
      // Log in as internal user ~> Log in as carrier action ~> Select org A ~> Select org B ~> See if changes the warehouse list
      if (isUUID(newVal) && newVal !== oldVal) {
        this.clearSelection();
        this.getData(true);
        this.warehouses = this.$warehouses;
      }
    }
  }
};
</script>
