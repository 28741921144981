var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "spot-title",
      class: {
        "drag-active": _vm.isDragActive,
        "drag-disabled": _vm.isDragDisabled,
        "bg--color-success-20": _vm.isSpotOpen(_vm.spot),
        "disabled-background": _vm.isSpotClosed(_vm.spot),
        "drop-zone": _vm.isSpotOpen(_vm.spot),
        "spot-selected": _vm.isSpotSelected,
      },
      attrs: {
        width: _vm.cardWidth,
        height: _vm.cardHeight,
        elevation: "1",
        "data-testid": `yard-spot-${_vm.spot.code}`,
        id: _vm.spot.id,
      },
      on: {
        dragenter: _vm.handleDragEnter,
        dragleave: _vm.handleDragLeave,
        dragover: _vm.handleDragEnter,
        click: _vm.handleSpotClick,
      },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "pa-1",
          class: {
            "bg--color-neutral-20": !_vm.selectedSpot || !_vm.isSpotSelected,
            "bg--color-primary-60": _vm.isSpotSelected,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "font-size-small text-center full-width text-uppercase spot-card-code",
              attrs: { "data-testid": `yard-spot-title-${_vm.spot.code}` },
            },
            [_c("strong", [_vm._v(_vm._s(_vm.spot.code))])]
          ),
        ]
      ),
      _c(
        "v-card-text",
        { staticClass: "card-content" },
        [
          _c(
            "v-card",
            {
              staticClass:
                "d-flex flex-column align-center justify-center text-center bg--color-background-transparent",
              attrs: { elevation: "0", height: _vm.cardContentHeight },
            },
            [
              !_vm.isSpotAssigned(_vm.spot)
                ? _c("yard-view-spot-unassigned", { attrs: { spot: _vm.spot } })
                : _c("yard-view-spot-assigned", {
                    attrs: { spot: _vm.spot, appointment: _vm.appointment },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }