var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _vm._v("Forms required for the drivers arrival and departure.."),
      ]),
      _c(
        "div",
        { staticClass: "disclaimer font-size-x-small" },
        [
          _vm.isGateManagementEnabled
            ? [
                _vm._v(" Customize your forms at the warehouse "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "warehouses.details.forms",
                        params: {
                          warehouseId: _vm.appointment.dock.warehouseId,
                        },
                      },
                    },
                  },
                  [_vm._v(" Fields and Forms ")]
                ),
                _vm._v(" section. "),
              ]
            : [
                _vm._v(
                  " Check-in and Asset Details are part of our Gate Management add-on solution. "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      rel: "noopener",
                      href: _vm.novaCore.GATE_MANAGEMENT_SALES_URL,
                    },
                  },
                  [_vm._v(" Contact Sales ")]
                ),
                _vm._v(" to learn more. "),
              ],
        ],
        2
      ),
      _c("custom-forms-data-panels", {
        staticClass: "mt-4",
        attrs: {
          editable: false,
          "is-locked": !_vm.isGateManagementEnabled,
          "object-ids": {
            appointmentId: _vm.appointment.id,
            assetvisitId: _vm.appointment.assetVisit?.id,
          },
          appointment: _vm.appointment,
          triggers: _vm.staticTriggers,
          warehouse: _vm.appointment.warehouse,
          timezone: _vm.appointment.warehouse.timezone,
          "military-time-enabled": _vm.$isMilitaryTimeEnabled(
            _vm.appointment.warehouse
          ),
        },
        scopedSlots: _vm._u(
          [
            {
              key: "additional-disclaimer",
              fn: function ({ trigger, lastChangedDateTimesInTimezone }) {
                return [
                  !lastChangedDateTimesInTimezone[trigger?.id] &&
                  !_vm.appointment.assetVisit?.id
                    ? _c("span", { staticClass: "text--color-text-disabled" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.novaCore.CustomFormsFeaturesData[
                                trigger?.feature
                              ]?.title?.split(":")[0]
                            ) +
                            " pending "
                        ),
                      ])
                    : !lastChangedDateTimesInTimezone[trigger?.id] &&
                      _vm.appointment.assetVisit?.id &&
                      trigger?.id !== _vm.assetDetailsTrigger?.id
                    ? _c("span", { staticClass: "text--color-text-disabled" }, [
                        _vm._v(" No appointment details provided "),
                      ])
                    : _vm._e(),
                ]
              },
            },
            _vm.appointment.assetVisit?.id
              ? {
                  key: `additional-form-data-${_vm.assetDetailsTrigger?.id}`,
                  fn: function () {
                    return [
                      _c("p", { staticClass: "mb-0" }, [
                        _c("strong", { staticClass: "mr-2" }, [
                          _vm._v("Phone:"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.appointment.assetVisit.phone)),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c("custom-forms-data-panels", {
        ref: "editableFormsPanel",
        attrs: {
          editable: _vm.isCheckoutEditable,
          "object-ids": {
            appointmentId: _vm.appointment.id,
            assetvisitId: _vm.appointment.assetVisit?.id,
          },
          appointment: _vm.appointment,
          triggers: _vm.editableTriggers,
          warehouse: _vm.appointment.warehouse,
          timezone: _vm.appointment.warehouse.timezone,
          "military-time-enabled": _vm.$isMilitaryTimeEnabled(
            _vm.appointment.warehouse
          ),
        },
        scopedSlots: _vm._u([
          {
            key: "additional-disclaimer",
            fn: function ({ trigger, lastChangedDateTimesInTimezone }) {
              return [
                !lastChangedDateTimesInTimezone[trigger?.id]
                  ? _c("span", { staticClass: "text--color-text-disabled" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.novaCore.CustomFormsFeaturesData[
                              trigger?.feature
                            ]?.title?.split(":")[0]
                          ) +
                          " pending "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      !_vm.hasActiveTriggers
        ? _c(
            "v-alert",
            {
              staticClass: "mt-4",
              attrs: { border: "left", "colored-border": "", color: "warning" },
            },
            [_vm._v(" No Gate Management forms have been enabled. ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }