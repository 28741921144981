var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      key: _vm.renderKey,
      attrs: {
        transition: "slide-left",
        "content-class": `checkin-notification-dialog ${
          _vm.showAdvertisement ? "is-advertisement" : ""
        }`,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass: "checkin-activator",
                    attrs: {
                      "data-testid": "grid-header-checkin-activator-button",
                    },
                  },
                  on
                ),
                [
                  _c(
                    "div",
                    { staticClass: "checkin-header" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2 text--color-neutral-90",
                          attrs: { small: "" },
                        },
                        [_vm._v("mdi-bell")]
                      ),
                      _c("span", [_vm._v("Arrivals")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "checkin-footer" },
                    [
                      _vm.isLoading
                        ? [
                            _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                size: "20",
                                width: "1",
                              },
                            }),
                          ]
                        : [
                            _vm.totalNewAssetVisitCount > 0
                              ? [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        block: "",
                                        "x-small": "",
                                        text: "",
                                      },
                                    },
                                    [
                                      _vm.totalNewAssetVisitCount > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sonar-badge is-relative",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "badge badge-primary",
                                              }),
                                              _c("div", {
                                                staticClass:
                                                  "badge ping ping-1",
                                              }),
                                              _c("div", {
                                                staticClass:
                                                  "badge ping ping-2",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.totalNewAssetVisitCount) +
                                            " New"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [_vm._v("No new arrivals")],
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.shouldShowDialog,
        callback: function ($$v) {
          _vm.shouldShowDialog = $$v
        },
        expression: "shouldShowDialog",
      },
    },
    [
      _vm.shouldShowInfo
        ? _c(
            "v-card",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-title align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.shouldShowInfo = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                  _c("span", { staticClass: "pl-3" }, [
                    _vm._v("About arrivals"),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.shouldShowInfo = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("span", [
                  _vm._v(
                    " This list shows arrivals from drivers who checked-in on Opendock. Arrivals will be shown in the following statuses: "
                  ),
                ]),
                _c("p", { staticClass: "mt-6" }, [
                  _c("strong", [_vm._v("Pending")]),
                  _c("span", { staticClass: "pt-2 d-block" }, [
                    _vm._v(
                      " These are unplanned arrivals that are pending an action. You can either create a new appointment for this asset, link it to an existing appointment, or reject the load. You will see all Pending arrivals that were checked-in within the last 24 hours. "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "mt-6" }, [
                  _c("strong", [_vm._v("Planned")]),
                  _c("span", { staticClass: "pt-2 d-block" }, [
                    _vm._v(
                      " These are arrivals that already had an appointment at the moment of check-in, or someone linked a Pending arrival to an existing appointment. You will see all Planned arrivals that were checked-in within the last 24 hours. "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "mt-6" }, [
                  _c("strong", [_vm._v("Resolved")]),
                  _c("span", { staticClass: "pt-2 d-block" }, [
                    _vm._v(
                      " These are unplanned arrivals that have already been acted upon. They either already have an appointment created or the load was rejected. You will see all Resolved arrivals that were checked-in within the last 24 hours. "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _c(
            "v-card",
            { staticClass: "d-flex flex-column" },
            [
              _vm.showAdvertisement
                ? [
                    _c(
                      "v-card-title",
                      { staticClass: "dialog-title" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowDialog = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("check-in-advertisement", {
                      attrs: { warehouse: _vm.warehouse },
                    }),
                  ]
                : [
                    _c(
                      "v-card-title",
                      { staticClass: "dialog-title" },
                      [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Arrivals in " + _vm._s(_vm.warehouse?.name)),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowDialog = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "height-auto flex-1 flex-column d-flex" },
                      [
                        _vm.totalAssetVisitsForWarehouse === 0
                          ? [
                              _c("icon-message", {
                                attrs: {
                                  icon: "truck-outline",
                                  messageHeader:
                                    "No arrivals yet on this warehouse",
                                  message:
                                    "You can choose a different warehouse in the Appointments page.",
                                },
                              }),
                            ]
                          : [
                              _c(
                                "v-tabs",
                                {
                                  staticClass:
                                    "py-3 sticky-header align-center",
                                  attrs: { "hide-slider": "" },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function ($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "flex-grow-1 tab",
                                      attrs: {
                                        "data-testid":
                                          "checkin-panel-pending-tab",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("Pending")]),
                                      _c("span", { staticClass: "count" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.unplannedAndUnresolvedAssetVisitCount
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "flex-grow-1 tab",
                                      attrs: {
                                        "data-testid":
                                          "checkin-panel-planned-tab",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("Planned")]),
                                      _c("span", { staticClass: "count" }, [
                                        _vm._v(
                                          _vm._s(_vm.plannedAssetVisitCount)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab flex-grow-1",
                                      attrs: {
                                        "data-testid":
                                          "checkin-panel-resolved-tab",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("Resolved")]),
                                      _c("span", { staticClass: "count" }, [
                                        _vm._v(
                                          _vm._s(_vm.resolvedAssetVisitCount)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.shouldShowInfo = true
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-information-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  staticClass: "mt-3",
                                  class: {
                                    "d-flex": _vm.isTabFlexBox,
                                  },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function ($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    [
                                      _vm.unplannedAndUnresolvedAssetVisitCount ===
                                      0
                                        ? [
                                            _c("icon-message", {
                                              attrs: {
                                                icon: "check-circle",
                                                messageHeader:
                                                  "No pending arrivals on this warehouse",
                                                message:
                                                  "You can use the tabs above to see other types of arrivals",
                                              },
                                            }),
                                          ]
                                        : _vm._l(
                                            _vm.unplannedAndUnresolvedByDate,
                                            function (visits, date) {
                                              return _c(
                                                "div",
                                                {
                                                  key: date,
                                                  staticClass:
                                                    "asset-visit-group v-item-group",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "date-label",
                                                    },
                                                    [_vm._v(_vm._s(date))]
                                                  ),
                                                  _vm._l(
                                                    visits,
                                                    function (visit) {
                                                      return _c(
                                                        "asset-visit-item",
                                                        {
                                                          key: `${visit.id}-${visit.assetVisitEvents.length}`,
                                                          attrs: {
                                                            warehouse:
                                                              _vm.$selectedWarehouse,
                                                            "last-driver-message-timestamp":
                                                              _vm
                                                                .lastDriverMessageMap[
                                                                visit
                                                                  .messageThread
                                                                  ?.id
                                                              ],
                                                            "unread-messages-count":
                                                              visit
                                                                .messageThread
                                                                ?.unreadMessagesCount,
                                                            visit: visit,
                                                          },
                                                          on: {
                                                            close: function (
                                                              $event
                                                            ) {
                                                              _vm.shouldShowDialog = false
                                                            },
                                                            updated:
                                                              _vm.updateAssetVisitInArr,
                                                            "show-appointment-list-dialog":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAppointmentListDisplay(
                                                                  visit
                                                                )
                                                              },
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-tab-item",
                                    [
                                      _vm.plannedAssetVisitCount === 0
                                        ? [
                                            _c("icon-message", {
                                              attrs: {
                                                icon: "check-circle",
                                                messageHeader:
                                                  "No planned arrivals on this warehouse today",
                                                message:
                                                  "You can use the tabs above to see other types of arrivals",
                                              },
                                            }),
                                          ]
                                        : _vm._l(
                                            _vm.plannedAndUnresolvedByDate,
                                            function (visits, date) {
                                              return _c(
                                                "div",
                                                {
                                                  key: date,
                                                  staticClass:
                                                    "asset-visit-group v-item-group",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "date-label",
                                                    },
                                                    [_vm._v(_vm._s(date))]
                                                  ),
                                                  _vm._l(
                                                    visits,
                                                    function (visit) {
                                                      return _c(
                                                        "asset-visit-item",
                                                        {
                                                          key: `${visit.id}-${visit.assetVisitEvents.length}`,
                                                          attrs: {
                                                            warehouse:
                                                              _vm.$selectedWarehouse,
                                                            "last-driver-message-timestamp":
                                                              _vm
                                                                .lastDriverMessageMap[
                                                                visit
                                                                  .messageThread
                                                                  ?.id
                                                              ],
                                                            "unread-messages-count":
                                                              visit
                                                                .messageThread
                                                                ?.unreadMessagesCount,
                                                            "is-planned": "",
                                                            visit: visit,
                                                          },
                                                          on: {
                                                            updated:
                                                              _vm.updateAssetVisitInArr,
                                                            "show-appointment-list-dialog":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAppointmentListDisplay(
                                                                  visit
                                                                )
                                                              },
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-tab-item",
                                    [
                                      _vm.resolvedAssetVisitCount === 0
                                        ? [
                                            _c("icon-message", {
                                              attrs: {
                                                icon: "check-circle",
                                                messageHeader:
                                                  "No resolved arrivals on this warehouse",
                                                message:
                                                  "You can use the tabs above to see other types of arrivals",
                                              },
                                            }),
                                          ]
                                        : _vm._l(
                                            _vm.resolvedByDate,
                                            function (visits, date) {
                                              return _c(
                                                "div",
                                                {
                                                  key: date,
                                                  staticClass:
                                                    "asset-visit-group v-item-group",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "date-label",
                                                    },
                                                    [_vm._v(_vm._s(date))]
                                                  ),
                                                  _vm._l(
                                                    visits,
                                                    function (visit) {
                                                      return _c(
                                                        "asset-visit-item",
                                                        {
                                                          key: `${visit.id}-${visit.assetVisitEvents.length}`,
                                                          attrs: {
                                                            "allow-acknowledgement": false,
                                                            warehouse:
                                                              _vm.$selectedWarehouse,
                                                            "last-driver-message-timestamp":
                                                              _vm
                                                                .lastDriverMessageMap[
                                                                visit
                                                                  .messageThread
                                                                  ?.id
                                                              ],
                                                            "unread-messages-count":
                                                              visit
                                                                .messageThread
                                                                ?.unreadMessagesCount,
                                                            visit: visit,
                                                            "is-rejected":
                                                              _vm.isAssetVisitCancelled(
                                                                visit
                                                              ),
                                                            "is-appointment-created":
                                                              Boolean(
                                                                visit.appointmentId
                                                              ),
                                                          },
                                                          on: {
                                                            updated:
                                                              _vm.updateAssetVisitInArr,
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
            ],
            2
          ),
      _vm.assetToLink
        ? _c("link-appointment-dialog", {
            attrs: {
              "asset-to-link": _vm.assetToLink,
              "external-activator": "",
              warehouse: _vm.warehouse,
              "is-military-time-enabled": _vm.isMilitaryTimeEnabled,
              "reference-number-settings": _vm.referenceNumberSettings,
              "show-dialog": _vm.shouldShowAppointmentListDialog,
            },
            on: {
              close: function ($event) {
                _vm.shouldShowAppointmentListDialog = false
              },
              linked: _vm.handleAppointmentLinked,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }