<template>
  <div class="w-100 d-flex justify-center align-center mt-10">
    <div class="overlay-contents" data-testid="yard-no-warehouse-overlay">
      <svg-warehouse />
      <h3 class="align-center">No Warehouse Selected</h3>
      <p>Please select a warehouse first.</p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import SvgWarehouse from '@/components/svgs/SvgWarehouse.vue';

export default defineComponent({
  name: 'YardViewNoWarehouse',
  components: { SvgWarehouse }
});
</script>
