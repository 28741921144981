export default {
  methods: {
    getCarrierName(carrier) {
      return `${carrier.firstName} ${carrier.lastName}`;
    },
    getSelectedCarrierText(carrier, truncateLimit, truncateAsWhole) {
      const truncate = this.novaCore.truncateString;
      if (!truncateAsWhole) {
        return `${truncate(this.getCarrierName(carrier), truncateLimit)} -
        ${truncate(carrier.company.name, truncateLimit)} -
        ${truncate(carrier.email, truncateLimit)}`;
      } else {
        return truncate(
          `${this.getCarrierName(carrier)} -
        ${carrier.company.name} -
        ${carrier.email}`,
          truncateLimit
        );
      }
    },
    getCarrierEmailCCs(carrier) {
      return carrier.orgCarrierSettings?.length > 0
        ? carrier.orgCarrierSettings[0]?.emailCCs || []
        : [];
    },
    isCarrierFavorite(carrier) {
      if (!carrier.orgCarrierSettings || !carrier.orgCarrierSettings[0]) {
        return false;
      }

      if (carrier.orgCarrierSettings[0].favoriteWarehouseIds === null) {
        // Means it is an org-level favorite
        return true;
      }

      return (
        this.warehouse &&
        carrier.orgCarrierSettings[0].favoriteWarehouseIds.includes(this.warehouse.id)
      );
    },
    async getData() {
      this.isLoading = true;
      const query = this.getQuery(!this.filters.searchStr);

      // Search by email subscribers per org
      // The join is made on the backend
      if (query.s.$or && query.searchStr) {
        query.s.$or.push({
          '"orgCarrierSettings"."emailCCs"': { $contL: query.searchStr }
        });
      }

      const res = await axios.get('carrier', { params: query });
      if (res?.data?.data) {
        this.carriers = res.data.data;
      } else {
        this.carriers = [];
      }

      this.sortCarriers();

      this.isLoading = false;
    },
    sortCarriers() {
      const rankMap = {};

      this.carriers.forEach(c => {
        let rank = 0;

        if (this.filters.searchStr) {
          const searchPieces = this.filters.searchStr.toLowerCase().split(' ');
          const lowerSearch = this.filters.searchStr.toLowerCase();
          const fullName = `${c.firstName} ${c.lastName}`.toLowerCase();

          // Full name or email match
          if (fullName === lowerSearch || c.email.toLowerCase() === lowerSearch) {
            rank += 3;
          }

          searchPieces.forEach(searchPiece => {
            rank += fullName.indexOf(searchPiece) > -1 ? 2 : 0;
          });
        }

        // Is fav
        rank += this.isCarrierFavorite(c) ? 1 : 0;

        rankMap[c.id] = rank;
      });

      this.carriers.sort((a, b) => (rankMap[a.id] < rankMap[b.id] ? 1 : -1));
    },
    getQuery(contactsOnly = false) {
      const query = this.getQueryBase();

      if (contactsOnly) {
        query.s.$and = [
          {
            '"orgCarrierSettings"."orgId"': this.$org.id
          }
        ];
      }

      if (this.filters.searchStr) {
        query.limit = '60000';
      }
      return query;
    }
  }
};
