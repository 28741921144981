var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "appointment-details" },
        [
          _c("status-manager", {
            staticClass: "mb-4",
            attrs: {
              "read-only": _vm.readOnly,
              appointment: _vm.appointment,
              "warehouse-triggers": _vm.warehouseTriggers,
              "should-edit-status": _vm.shouldEditStatus,
            },
          }),
          _c("section", { staticClass: "sidebar-content" }, [
            _c(
              "div",
              { staticClass: "sidebar" },
              [
                _c(
                  "details-content-box",
                  {
                    attrs: {
                      header: "Carrier Contact",
                      "data-testid": "appointment-details-carrier-box",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center mb-4" },
                      [
                        _c("favorite-button", {
                          staticClass: "mr-2",
                          attrs: {
                            org: _vm.org,
                            carrier: _vm.appointment.user,
                          },
                          on: { "toggle-favorite": _vm.toggleFavoriteCarrier },
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-bold text--color-text-tertiary",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.appointment.user.company.name) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.appointment.user.firstName) +
                          " " +
                          _vm._s(_vm.appointment.user.lastName)
                      ),
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.appointment.user.email))]),
                    _vm.appointment.user.phone
                      ? _c("div", [_vm._v(_vm._s(_vm.appointment.user.phone))])
                      : _vm._e(),
                    _vm.appointment.user.company.scac
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                `SCAC: ${_vm.appointment.user.company.scac}`
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mt-4" },
                      [
                        !_vm.fieldsInEditMode.includes(
                          _vm.EditableDetailsFields.carrier
                        )
                          ? _c(
                              "outline-button",
                              {
                                staticClass: "align-self-start",
                                attrs: { small: "", inline: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEditClick(
                                      _vm.EditableDetailsFields.carrier
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Change Carrier ")]
                            )
                          : [
                              _c("carrier-select", {
                                attrs: {
                                  outlined: "",
                                  "hide-icon": "",
                                  dense: "",
                                  "data-testid":
                                    "appointment-details-carrier-select",
                                  "truncate-as-whole": "",
                                  "entry-point": "Appointment Details Modal",
                                },
                                model: {
                                  value: _vm.selectedCarrier,
                                  callback: function ($$v) {
                                    _vm.selectedCarrier = $$v
                                  },
                                  expression: "selectedCarrier",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "outline-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleNevermindClick(
                                            _vm.EditableDetailsFields.carrier
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Nevermind ")]
                                  ),
                                  _c(
                                    "primary-button",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveField(
                                            _vm.EditableDetailsFields.carrier,
                                            _vm.selectedCarrier?.id,
                                            "userId"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "details-content-box",
                  {
                    staticClass: "mb-0 full-height",
                    attrs: { header: "Appointment Info" },
                  },
                  [
                    _c("div", { staticClass: "data" }, [
                      _c("div", { staticClass: "data-group" }, [
                        _c("span", { staticClass: "data-header" }, [
                          _vm._v("Dock"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.appointment.dock.name))]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "data-group action-group" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column full-width" },
                            [
                              _c("span", { staticClass: "data-header" }, [
                                _vm._v("Load Type"),
                              ]),
                              !_vm.fieldsInEditMode.includes(
                                _vm.EditableDetailsFields.loadType
                              )
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.appointment.loadType.name) +
                                        " "
                                    ),
                                  ])
                                : [
                                    _c("load-type-select", {
                                      staticClass: "mt-1",
                                      attrs: {
                                        outlined: "",
                                        small: "",
                                        clearable: false,
                                        "hide-icon": "",
                                        "selected-warehouse":
                                          _vm.appointment.warehouse,
                                        "selected-docks": [
                                          _vm.appointment.dock,
                                        ],
                                        docks: [_vm.appointment.dock],
                                        "show-only-assigned-load-types": "",
                                        value: _vm.loadTypeId,
                                      },
                                      on: {
                                        input: (value) =>
                                          (_vm.loadTypeId = value.id),
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex justify-end" },
                                      [
                                        _c(
                                          "outline-button",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { small: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleNevermindClick(
                                                  _vm.EditableDetailsFields
                                                    .loadType
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Nevermind ")]
                                        ),
                                        _c(
                                          "primary-button",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.loadTypeId ===
                                                _vm.appointment.loadTypeId,
                                              small: "",
                                              loading:
                                                _vm.fieldsInLoadingState.includes(
                                                  _vm.EditableDetailsFields
                                                    .loadType
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveField(
                                                  _vm.EditableDetailsFields
                                                    .loadType,
                                                  _vm.loadTypeId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Save ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          ),
                          !_vm.fieldsInEditMode.includes(
                            _vm.EditableDetailsFields.loadType
                          )
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEditClick(
                                        _vm.EditableDetailsFields.loadType
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-pencil")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "data-group" }, [
                        _c("span", { staticClass: "data-header" }, [
                          _vm._v("Warehouse"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.appointment.warehouse.name)),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "data-group action-group" },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "data-header" }, [
                              _vm._v("Confirmation Number"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.appointment.confirmationNumber)
                              ),
                            ]),
                          ]),
                          _c("copy-content", {
                            attrs: {
                              content: _vm.appointment.confirmationNumber,
                              label: "Click to copy confirmation number",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "data-group action-group" },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "data-header" }, [
                              _vm._v("Appointment ID"),
                            ]),
                            _c("span", [_vm._v(_vm._s(_vm.appointment.id))]),
                          ]),
                          _c("copy-content", {
                            attrs: {
                              "test-id":
                                "appointment-details-copy-appointment-id",
                              content: _vm.appointment.id,
                              label: "Click to copy confirmation number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "content flex-1" },
              [
                _vm.appointment.status ===
                _vm.novaCore.AppointmentStatus.Scheduled
                  ? _c(
                      "details-content-box",
                      {
                        attrs: {
                          "is-dense": "",
                          "is-editable": !_vm.fieldsInEditMode.includes(
                            _vm.EditableDetailsFields.eta
                          ),
                          field: _vm.EditableDetailsFields.eta,
                          header: "Carrier ETA",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "edit-button",
                              fn: function ({ field }) {
                                return [
                                  _c(
                                    "outline-button",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEditClick(field)
                                        },
                                      },
                                    },
                                    [_vm._v("Update ETA")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1685453845
                        ),
                      },
                      [
                        !_vm.fieldsInEditMode.includes(
                          _vm.EditableDetailsFields.eta
                        )
                          ? [
                              !_vm.appointment.eta
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "text--color-text-tertiary",
                                    },
                                    [_vm._v(" No ETA provided by the carrier ")]
                                  )
                                : [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.computedEta)),
                                        ]),
                                        _vm.appointment.eta
                                          ? _c(
                                              "v-chip",
                                              {
                                                staticClass: "ml-2",
                                                class: _vm.getEtaConditionMeta(
                                                  _vm.etaCondition
                                                ).classes,
                                                attrs: {
                                                  small: "",
                                                  color:
                                                    _vm.getEtaConditionMeta(
                                                      _vm.etaCondition
                                                    ).color,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-size-x-small",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.novaCore.breakWordsAtCaps(
                                                            _vm.etaCondition
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                            ]
                          : [
                              _c("set-eta-form", {
                                ref: "setEtaForm",
                                attrs: { appointment: _vm.appointment },
                                on: {
                                  cancel: _vm.handleNevermindClick,
                                  "condition-updated": _vm.updateEtaCondition,
                                },
                              }),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "details-content-box",
                  { attrs: { header: "Appointment tags", "is-dense": "" } },
                  [
                    _c("tag-manager", {
                      attrs: {
                        outlined: "",
                        "outlined-chips": true,
                        "small-chips": "",
                        "should-enable-custom-tags": true,
                        "read-only":
                          !_vm.$rolePermissions.canUpdateAppointmentTags ||
                          _vm.readOnly,
                        placeholder: "Add tags",
                        "hide-instructions": "",
                        allowTagDelete: !_vm.lockTagEditing,
                      },
                      model: {
                        value: _vm.tags,
                        callback: function ($$v) {
                          _vm.tags = $$v
                        },
                        expression: "tags",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "details-content-box",
                  {
                    attrs: {
                      header: _vm.$refNumSettings(_vm.appointment.warehouse)
                        .displayName,
                      "is-editable": !_vm.fieldsInEditMode.includes(
                        _vm.EditableDetailsFields.refNumber
                      ),
                      field: _vm.EditableDetailsFields.refNumber,
                      "is-dense": "",
                    },
                    on: { click: _vm.handleEditClick },
                  },
                  [
                    !_vm.fieldsInEditMode.includes(
                      _vm.EditableDetailsFields.refNumber
                    )
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.appointment.refNumber || "—") + " "
                          ),
                        ])
                      : [
                          _c("text-field", {
                            attrs: { outlined: "" },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.saveField(
                                  _vm.EditableDetailsFields.refNumber,
                                  _vm.refNumber
                                )
                              },
                            },
                            model: {
                              value: _vm.refNumber,
                              callback: function ($$v) {
                                _vm.refNumber = $$v
                              },
                              expression: "refNumber",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "outline-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNevermindClick(
                                        _vm.EditableDetailsFields.refNumber
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Nevermind ")]
                              ),
                              _c(
                                "primary-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.refNumber ===
                                        _vm.appointment.refNumber ||
                                      _vm.fieldsInLoadingState.includes(
                                        _vm.EditableDetailsFields.refNumber
                                      ),
                                    small: "",
                                    loading: _vm.fieldsInLoadingState.includes(
                                      _vm.EditableDetailsFields.refNumber
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveField(
                                        _vm.EditableDetailsFields.refNumber,
                                        _vm.refNumber
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Save ")]
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
                _c(
                  "details-content-box",
                  {
                    attrs: {
                      header: "Notes",
                      "is-dense": !_vm.appointment.notes,
                      "is-editable": !_vm.fieldsInEditMode.includes(
                        _vm.EditableDetailsFields.notes
                      ),
                      field: _vm.EditableDetailsFields.notes,
                    },
                    on: { click: _vm.handleEditClick },
                  },
                  [
                    !_vm.fieldsInEditMode.includes(
                      _vm.EditableDetailsFields.notes
                    )
                      ? _c("div", {
                          staticClass: "html-content",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.appointment.notes || "&#8212;"
                            ),
                          },
                        })
                      : [
                          _c("rich-text-input", {
                            staticClass: "flex-fill",
                            attrs: {
                              "editor-config": {
                                plugins: ["Essentials", "Paragraph"],
                                toolbar: { items: [] },
                              },
                            },
                            model: {
                              value:
                                _vm.appointmentDataRefs[
                                  _vm.EditableDetailsFields.notes
                                ].value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.appointmentDataRefs[
                                    _vm.EditableDetailsFields.notes
                                  ],
                                  "value",
                                  $$v
                                )
                              },
                              expression:
                                "appointmentDataRefs[EditableDetailsFields.notes].value",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end mt-6" },
                            [
                              _c(
                                "outline-button",
                                {
                                  staticClass: "mr-2",
                                  attrs: { small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNevermindClick(
                                        _vm.EditableDetailsFields.notes
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Nevermind ")]
                              ),
                              _c(
                                "primary-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.appointmentDataRefs[
                                        _vm.EditableDetailsFields.notes
                                      ] === _vm.appointment.notes ||
                                      _vm.fieldsInLoadingState.includes(
                                        _vm.EditableDetailsFields.notes
                                      ),
                                    small: "",
                                    loading: _vm.fieldsInLoadingState.includes(
                                      _vm.EditableDetailsFields.notes
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveField(
                                        _vm.EditableDetailsFields.notes,
                                        _vm.appointmentDataRefs[
                                          _vm.EditableDetailsFields.notes
                                        ].value
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Save ")]
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
                _c(
                  "details-content-box",
                  {
                    attrs: {
                      header: "Booking details",
                      "is-dense": Object.keys(_vm.customFields).length === 0,
                      "is-editable": "",
                      "field-name": _vm.EditableDetailsFields.customFields,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleEditClick(
                          _vm.EditableDetailsFields.customFields
                        )
                      },
                    },
                  },
                  [
                    !Object.keys(_vm.customFields).length
                      ? _c("span", [_vm._v("—")])
                      : _c(
                          "div",
                          { staticClass: "data" },
                          _vm._l(
                            Object.values(_vm.customFields),
                            function (customField) {
                              return _c(
                                "div",
                                {
                                  key: customField.key,
                                  staticClass: "data-group no-divider",
                                },
                                [
                                  _c("span", { staticClass: "data-header" }, [
                                    _vm._v(_vm._s(customField.label)),
                                  ]),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getCustomFieldValue(customField)
                                      ),
                                    },
                                  }),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                  ]
                ),
                _c(
                  "details-content-box",
                  {
                    staticClass: "mb-0",
                    attrs: { header: "Email subscribers", "is-dense": "" },
                  },
                  [
                    _c("email-list-field", {
                      ref: "emailFieldList",
                      attrs: { outlined: "", "small-chips": "" },
                      model: {
                        value: _vm.ccEmails,
                        callback: function ($$v) {
                          _vm.ccEmails = $$v
                        },
                        expression: "ccEmails",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.fieldsInEditMode.includes(_vm.EditableDetailsFields.customFields)
        ? _c("edit-custom-fields-dialog", {
            attrs: {
              "external-activator": "",
              appointment: _vm.appointment,
              "show-dialog": _vm.fieldsInEditMode.includes(
                _vm.EditableDetailsFields.customFields
              ),
            },
            on: {
              close: function ($event) {
                return _vm.novaCore.removeArrayItem(
                  _vm.fieldsInEditMode,
                  _vm.EditableDetailsFields.customFields
                )
              },
              save: (data) =>
                _vm.saveField(_vm.EditableDetailsFields.customFields, data),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }