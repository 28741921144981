import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';

export default {
  async getOneById(spotId, params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`yard/spot-area/${spotId}?${queryString}`, {
      params
    });
    return getResponse(response, options);
  },
  async getManyByWarehouseId(warehouseId, params = {}, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`yard/spot-area?${queryString}`, {
      params: { ...params, s: { warehouseId } }
    });

    return getResponse(response, options);
  },
  async create(data, options = {}) {
    const response = await axios.post('yard/spot-area', data);
    return getResponse(response, options);
  },
  async update(id, data, options = {}) {
    const response = await axios.patch(`yard/spot-area/${id}`, data);
    return getResponse(response, options);
  }
};
