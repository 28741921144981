<template>
  <v-autocomplete
    v-model="selectedAreas"
    :disabled="!selectedWarehouse"
    :items="spotAreas"
    item-text="name"
    item-value="id"
    placeholder="Select areas"
    :return-object="false"
    multiple
    dense
    :clearable="false"
    hide-details>
    <template #selection="{ item, index }">
      <small
        v-if="index === 0"
        :class="{ 'font-weight-bold': selectedAreas.length < spotAreas.length }">
        {{ selectedAreas.length }} of {{ spotAreas.length }} selected
      </small>
    </template>
    <template #prepend-item>
      <v-btn v-if="selectedAreas.length !== spotAreas.length" text @click="selectAll($event)">
        Select all
      </v-btn>
      <v-divider />
    </template>
    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
          <v-btn text small class="text--disabled" @click="selectOnly($event, item)">
            <small>only</small>
          </v-btn>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewAreaSelect',
  props: {},
  setup() {
    const yardStore = useYard();

    const selectOnly = ($event, item) => {
      $event.stopPropagation();
      yardStore.selectedAreasRef.value = [item.id];
    };

    const selectAll = $event => {
      $event.stopPropagation();
      yardStore.selectedAreasRef.value = yardStore.spotAreasRef.value.map(area => area.id);
    };

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      selectedAreas: yardStore.selectedAreasRef,
      spotAreas: yardStore.spotAreasRef,
      selectOnly,
      selectAll
    };
  }
});
</script>
