// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  name: 'Auth',
  state,
  getters,
  actions,
  mutations
};
