import Vue from 'vue';
import Vuex from 'vuex';

import navigation from './modules/navigation';
import Websockets from './modules/websockets';
import app from './app';
import satelliteApp from '@satellite/store/app';
import { synchronizeFeatureFlagsContext } from '@/plugins/featureFlagPlugin';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production'

// Import all modules from the modules folder
function loadLocalStores() {
  const context = require.context('@/modules', true, /store.js$/i);
  return context
    .keys()
    .map(context)
    .map(m => m.default);
}

function loadSatelliteStores() {
  const context = require.context('@satellite/modules', true, /store.js$/i);
  return context
    .keys()
    .map(context)
    .map(m => m.default);
}

const modules = {};
loadSatelliteStores().forEach(module => {
  modules[module.name] = module;
});
loadLocalStores().forEach(module => {
  modules[module.name] = modules[module.name] ? joinStores(modules[module.name], module) : module;
});

function joinStores(existingStore, newStore) {
  let store = {};
  Object.keys(existingStore).map(storeKey => {
    store[storeKey] = { ...existingStore[storeKey], ...newStore[storeKey] };
  });

  return store;
}

modules.navigation = navigation;
modules.App = joinStores(satelliteApp, app);
modules.Websockets = Websockets;

const store = new Vuex.Store({
  modules: modules
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
});

// Properties on the Vuex store
store.modules = () => modules;

synchronizeFeatureFlagsContext(store);

export default store;
