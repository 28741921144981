<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h3 class="mt-4 mb-2 black--text">Warehouse Settings</h3>
      </v-col>
      <v-col cols="12">
        <settings-expansion-panels
          :read-only="readOnly"
          :entity-parent="novaCore.DatabaseEntities.Org"
          :entity-key="novaCore.DatabaseEntities.Warehouse"
          :should-be-expanded="false"
          :entity-parent-obj="editingWarehouse"
          @update-settings="data => (warehouseSettings = data)"
          :entity-settings="warehouse?.settings || {}"></settings-expansion-panels>
      </v-col>
    </v-row>

    <v-row class="mt-9">
      <v-col>
        <h3 class="black--text mb-6">
          RefNumber (PO Number) Validation Setup
          <a
            href="https://community.loadsmart.com/hc/en-us/articles/14946368437907-PO-Ref-Number-Validation-Implementation"
            target="_blank"
            rel="noopener"
            class="link small ml-2">
            (Learn more here)
          </a>
        </h3>
      </v-col>
    </v-row>

    <v-expansion-panels multiple>
      <slot name="prepended-panel"></slot>
      <v-expansion-panel key="refNumberValidationSetup">
        <v-expansion-panel-header ripple>Version</v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <v-row class="mt-6">
            <v-col cols="6">
              <v-select
                :disabled="readOnly"
                :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                outlined
                persistent-hint
                persistent-placeholder
                label="Version"
                v-model="editingWarehouse.refNumberValidationVersion"
                :items="refNumberValidationVersionItems"></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header ripple>Credentials</v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <v-row class="mt-4">
            <v-col>
              <v-text-field
                :disabled="readOnly"
                :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                spellcheck="false"
                type="url"
                v-model="editingWarehouse.refNumberValidationUrl"
                :rules="$validator.rules.url"
                label="Validator URL"></v-text-field>
            </v-col>
          </v-row>

          <v-row
            v-if="
              editingWarehouse.refNumberValidationVersion === novaCore.RefNumberValidationVersion.V2
            ">
            <v-col>
              <v-text-field
                :disabled="readOnly"
                :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                spellcheck="false"
                type="text"
                v-model="editingWarehouse.refNumberValidationPasscode"
                label="Passcode (optional Auth-Bearer token)"></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <h3 class="mt-12 mb-4 black--text">Scheduling Portal Availability</h3>
      </v-col>
    </v-row>

    <v-expansion-panels multiple>
      <slot name="prepended-panel"></slot>
      <v-expansion-panel key="schedulePortalAvailability">
        <v-expansion-panel-header ripple>Carrier Visibility</v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <carrier-scheduling-toggle
                class="pl-2 pb-2"
                entity="Warehouse"
                :disabled="!$rolePermissions.canUpdateWarehouse || readOnly"
                v-model="editingWarehouse.allowCarrierScheduling"></carrier-scheduling-toggle>
            </v-col>
          </v-row>
          <v-row
            v-if="
              editingWarehouse.allowCarrierScheduling &&
              $carrierViewAllowedUserRoles.includes($me.role) &&
              !readOnly
            ">
            <v-col>
              <div class="d-flex">
                <primary-button
                  small
                  test-id="copy-warehouse-button"
                  before-icon="link"
                  class="mr-4"
                  @click="copyWarehouseLink">
                  Copy warehouse link to share
                </primary-button>
                <preview-as-carrier-button
                  :warehouse="warehouse"
                  :disabled="!warehouse.allowCarrierScheduling"></preview-as-carrier-button>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header ripple>Experimental Feature</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <h3 class="mt-10 mb-2 black--text"></h3>
              <div class="mt-5">
                <p>
                  <caution-message>
                    This changes Carrier availability - make sure you understand the implications:
                  </caution-message>
                  Prevent surprise appointments by removing the first
                  <text-field
                    v-model="editingWarehouse.intervalTrimForCarriers"
                    ref="intervalTrimForCarriers"
                    class="inline-text-field no-spinner text-center"
                    type="number"></text-field>
                  <strong>intervals</strong>
                  from Carrier availability. "Interval" in this context refers to a continuous block
                  of Warehouse open-hours.
                </p>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <action-group
      class="pr-0 pt-8"
      confirm-label="Save Settings"
      :hide-cancel="true"
      cancel-color="error"
      :confirm-button-tooltip="readOnly ? novaCore.getRoleActionError() : ''"
      :disable-confirm="!$rolePermissions.canUpdateWarehouse || readOnly"
      @confirm="submit"></action-group>

    <v-dialog
      :value="Boolean(notificationConfigKey)"
      :retain-focus="false"
      width="auto"
      @keydown.esc="notificationConfigKey = null">
      <template v-slot:default>
        <div class="my-2">
          <notification-config-form
            v-if="notificationConfigKey"
            :warehouse-id="warehouse.id"
            :settings="warehouse.settings || {}"
            :notification-type="notificationConfigKey"></notification-config-form>
        </div>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import editWarehouseMixin from '@/modules/warehouses/editWarehouseMixin';

export default {
  mixins: [editWarehouseMixin],
  beforeMount() {
    this.warehouseSettings = { ...(this.warehouse?.settings || {}) };
    this.setEditingWarehouse();
  },
  mounted() {
    this.$eventHub.$on('set-edit-notification-key', notificationKey => {
      this.notificationConfigKey = notificationKey;
    });
  },
  destroyed() {
    this.$eventHub.$off('set-edit-notification-key');
  }
};
</script>
<style scoped lang="scss">
.link {
  font-weight: normal !important;
  font-size: 14px;
}
</style>
