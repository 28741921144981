<template>
  <v-card elevation="0" width="250">
    <v-card-title class="d-flex flex-row">
      <h1 class="font-weight-bold text-uppercase" data-testid="yard-spot-detail-title">
        {{ spot.code }}
      </h1>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDetail" data-testid="yard-spot-detail-close-button">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text data-testid="yard-spot-detail-content">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Spot events</v-expansion-panel-header>
          <v-expansion-panel-content class="scrollable-content">
            <p v-for="event of selectedSpot.spotEvents" :key="event.id" class="mt-2">
              <strong class="text-capitalize">
                {{ event.eventType }}
              </strong>

              <br />
              <span>{{ event.reason }} @ {{ event.createDateTime }}</span>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="d-flex flex-column my-8">
        <h4 class="mb-4">Spot data</h4>
        <v-form ref="form">
          <v-text-field
            v-model="spot.code"
            label="Spot code"
            outlined
            :rules="[validateCode]"
            dense
            data-testid="yard-spot-form-code-input"></v-text-field>

          <v-select
            v-model="spot.type"
            :items="Object.keys(spotType).map(k => k.toLowerCase())"
            label="Spot type"
            :rules="[validateType]"
            data-testid="yard-spot-form-type-select"
            outlined
            dense></v-select>
        </v-form>
      </div>
    </v-card-text>
    <v-card-actions class="mt-6" data-testid="yard-spot-detail-actions">
      <div class="d-flex flex-column full-width">
        <v-btn
          block
          color="primary"
          @click="updateSpot"
          data-testid="yard-spot-form-submit-button"
          class="mb-4">
          <v-icon small class="mr-2">mdi-check</v-icon>
          Update Spot
        </v-btn>
        <v-btn
          block
          outlined
          @click="deleteSpot"
          class="mb-4"
          data-testid="yard-spot-form-delete-button"
          :disabled="isSpotAssigned(spot)">
          <v-icon small class="mr-2">mdi-close</v-icon>
          Delete Spot
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotForm',
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const form = ref(null);
    const selectedWarehouse = computed(() => yardStore.selectedWarehouse);

    const deleteSpot = () => {
      yardStore.deleteSpot(props.spot.id);
    };

    const closeDetail = () => {
      yardStore.selectedSpotRef.value = null;
    };

    const updateSpot = () => {
      if (!form.value?.validate()) {
        return;
      }
      yardStore.updateSpot(props.spot.id, {
        code: props.spot.code,
        type: props.spot.type
      });
    };

    return {
      selectedWarehouse,
      deleteSpot,
      closeDetail,
      isSpotAssigned: yardStore.isSpotAssigned,
      isSpotClosed: yardStore.isSpotClosed,
      isSpotOpen: yardStore.isSpotOpen,
      updateSpot,
      form,
      validateType: yardStore.validateSpotTypeInput,
      validateCode: yardStore.validateSpotCodeInput,
      selectedSpot: yardStore.selectedSpotRef,
      spotStatus: yardStore.spotStatus,
      spotType: yardStore.spotType
    };
  }
});
</script>
<style scoped>
.scrollable-content {
  max-height: 300px; /* Set your desired max height */
  overflow-y: auto; /* Enable vertical scrolling */
}
</style>
