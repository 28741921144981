var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("h1", { staticClass: "mb-8" }, [_vm._v("Live Yard View")]),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "h3",
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          small: "",
                          rounded: "",
                          "data-testid": "yard-area-form-back",
                        },
                        on: { click: _vm.back },
                      },
                      [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                      1
                    ),
                    _vm._v(" " + _vm._s(_vm.selectedWarehouse?.name) + " "),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card",
                { staticClass: "mt-4", attrs: { elevation: "1" } },
                [
                  _c(
                    "div",
                    { staticClass: "pa-6" },
                    [
                      _vm.isEditing || !_vm.defaultSpotAreaId
                        ? _c("yard-view-spot-area-form", {
                            attrs: { "spot-area": _vm.spotArea },
                          })
                        : [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "8" } }, [
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "py-1 pt-2",
                                      attrs: {
                                        "data-testid":
                                          "yard-spot-area-area-name",
                                      },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.spotArea.name)),
                                      ]),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            title: "Update area name",
                                            small: "",
                                            icon: "",
                                            "data-testid":
                                              "yard-spot-area-edit-button",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.isEditing = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-pencil")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                  _c("v-divider"),
                  _vm.defaultSpotAreaId
                    ? _c("yard-view-spot-area", {
                        staticClass: "pa-5",
                        attrs: { editable: true, "spot-area": _vm.spotArea },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-navigation-drawer",
            { attrs: { app: "", right: "", permanent: "" } },
            [
              _c(
                "transition",
                { attrs: { name: "slide-fade", mode: "in-out" } },
                [
                  _vm.selectedSpot?.id
                    ? _c(
                        "v-col",
                        {
                          staticClass: "px-1 left-border-box",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c("yard-view-spot-form", {
                            staticClass: "pl-4",
                            attrs: { spot: _vm.selectedSpot },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isLoading ? _c("yard-view-loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }