var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { staticClass: "my-2" },
    [
      _c(
        "v-col",
        { staticClass: "pl-4 pa-0", attrs: { cols: "10" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c("v-col", { staticClass: "pt-4 pb-0" }, [
                _c("p", { staticClass: "font-size-medium" }, [
                  _vm._v(_vm._s(_vm.spotArea.name)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-0 mt-0 mb-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-2 pb-1" },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        small: "",
                        color: "secondary",
                        "data-testid": `yard-main-area-header-open-${_vm.spotArea.id}`,
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("mdi-eye")]
                      ),
                      _vm._v(" " + _vm._s(_vm.spotsOpen) + " open "),
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        small: "",
                        color: "secondary",
                        "data-testid": `yard-main-area-header-closed-${_vm.spotArea.id}`,
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("mdi-eye")]
                      ),
                      _vm._v(" " + _vm._s(_vm.spotsClosed) + " closed "),
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        small: "",
                        color: "secondary",
                        "data-testid": `yard-main-area-header-occupied-${_vm.spotArea.id}`,
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("mdi-eye")]
                      ),
                      _vm._v(" " + _vm._s(_vm.spotsOccupied) + " occupied "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-col",
        { staticClass: "pt-5", attrs: { cols: "2" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { small: "", text: "" },
              on: { click: _vm.editSpotArea },
            },
            [_vm._v("Edit Area")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }