import Vue from 'vue';

// initial state
const state = {
  developerSettingsCounter: 0,
  localSettings: {
    showDeveloperSettings: false,
    logSubspace: false
  }
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setLogSubspace(state, val) {
    state.localSettings.logSubspace = val;
    this.$app.$updateObjectInLocalStorage('local_settings.logSubspace', val);

    if (val) {
      this.$app.addSubspaceLoggingEventListener();
    } else {
      this.$app.removeSubspaceLoggingEventListener();
    }
  },
  incrementDeveloperSettingsCounter(state) {
    state.developerSettingsCounter++;
  },
  setShowDeveloperSettings(state, val) {
    state.localSettings.showDeveloperSettings = val;
    this.$app.$updateObjectInLocalStorage('local_settings.showDeveloperSettings', val);
  },
  setLocalSettings(state, localSettings) {
    Vue.set(state, 'localSettings', { ...state.localSettings, ...localSettings });
  }
};

export default {
  namespaced: true,
  name: 'Settings',
  state,
  getters,
  actions,
  mutations
};
