import _ from 'lodash';
import { toValue } from '@vueuse/core';
import { computed } from 'vue';

/**
 * @typedef {import('vue').Ref} VueRef
 */

/**
 * Composable helper to manage selecting/deselecting all items in a list
 * @param {Object} options
 * @param {VueRef<any[]>} options.items - Available items to select
 * @param {VueRef<any[]>} options.selected - Selected items
 * @param {string} [options.itemValue] - The property of the item to use as the value
 */
export default function useSelectAll({ items, selected, itemValue = 'id' }) {
  const itemsValue = computed(() => toValue(items));
  const selectedValue = computed(() => toValue(selected));
  const allSelected = computed(() => {
    const a = _(itemsValue.value).map(itemValue).uniq().value();
    const b = _(selectedValue.value).map(itemValue).uniq().value();
    return _.xor(a, b).length === 0;
  });
  function toggleSelectAll() {
    selected.value = allSelected.value ? [] : itemsValue.value;
  }

  return { allSelected, toggleSelectAll };
}
