<template>
  <div class="form-data" :class="{ 'is-list': isList, 'is-compact text-dense': isCompact }">
    <span class="d-flex" :class="keyValueClasses">
      <strong v-if="boldLabels">Phone Number</strong>
      <div v-else>Phone Number:</div>
      <span>{{ assetVisit.phone ?? '----' }}</span>
    </span>
    <span class="d-flex" :class="keyValueClasses">
      <strong v-if="boldLabels">Carrier Company</strong>
      <span v-else>Carrier Company:</span>
      <span>{{ company?.name ?? assetVisit.companyHint }}</span>
    </span>
    <span v-for="data of assetVisitFormData" :key="data.id" class="d-flex" :class="keyValueClasses">
      <strong v-if="boldLabels">{{ data.label }}</strong>
      <span v-else>{{ data.label }}:</span>
      <span
        v-if="isDocument(data)"
        v-html="
          getCustomFieldFormattedValue(data, {
            [CustomFieldType.Document]: { generateLink: true }
          })
        "></span>
      <span
        v-else-if="isMultiDocument(data)"
        v-html="
          getCustomFieldFormattedValue(data, {
            [CustomFieldType.MultiDocument]: { generateLink: true }
          })
        "></span>
      <span v-else>{{ getCustomFieldFormattedValue(data) }}</span>
    </span>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import { CustomFieldType, getCustomFieldFormattedValue, AssetVisit } from '@satellite/../nova/core';

export default {
  props: {
    assetVisit: {
      type: AssetVisit,
      required: true
    },
    company: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    isList: {
      type: Boolean,
      required: false,
      default: false
    },
    isCompact: {
      type: Boolean,
      required: false,
      default: false
    },
    phoneTitle: {
      type: String,
      required: false,
      default: 'Phone Number'
    },
    companyTitle: {
      type: String,
      required: false,
      default: 'Carrier Company'
    },
    boldLabels: {
      type: Boolean,
      required: false,
      default: true
    },
    isInline: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props) {
    const assetVisitFormData = ref([]);
    const keyValueClasses = ref({
      'flex-column': !props.isList || !props.isInline,
      'pb-6': !props.isList,
      'pb-2': props.isList
    });

    async function getAssetVisitFormData() {
      try {
        const response = await axios.get('custom-forms/form-data', {
          params: {
            s: { objectId: props.assetVisit.id },
            limit: 1000
          }
        });
        return response?.data?.data || [];
      } catch (_) {
        return [];
      }
    }

    function isDocument(field) {
      return field.type === CustomFieldType.Document;
    }

    function isMultiDocument(field) {
      return field.type === CustomFieldType.MultiDocument;
    }

    onMounted(async () => {
      assetVisitFormData.value = await getAssetVisitFormData();
    });

    return {
      assetVisitFormData,
      isDocument,
      isMultiDocument,
      CustomFieldType,
      getCustomFieldFormattedValue,
      keyValueClasses
    };
  }
};
</script>

<style lang="scss" scoped>
.form-data {
  &.is-compact * {
    font-size: 12px !important;
  }
}

.form-data:not(.is-list) {
  display: flex;
  flex-wrap: wrap;

  & > span {
    width: 33%;

    &.full-width {
      width: 100%;
    }
  }
}
</style>
