<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <yard-view-page-header />
        <v-row>
          <v-col cols="12">
            <yard-view-page-sub-header />
          </v-col>
        </v-row>
        <v-row>
          <v-col col="12">
            <yard-view-yard v-if="selectedWarehouse?.id" />
            <yard-view-no-warehouse v-else />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-navigation-drawer
        app
        right
        permanent
        class="left-border-box pa-2"
        v-if="selectedWarehouse?.id">
        <transition name="slide-fade" mode="out-in">
          <yard-view-spot-detail :spot="selectedSpot" v-if="selectedSpot?.id" />
          <yard-view-not-assigned-list v-else />
        </transition>
      </v-navigation-drawer>
    </v-row>
    <yard-view-loading v-if="isLoading" />
  </v-container>
</template>

<script>
import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';

import YardViewPageHeader from '@/modules/yard/components/YardViewPageHeader.vue';
import YardViewNotAssignedList from '@/modules/yard/components/YardViewNotAssignedList.vue';
import YardViewYard from '@/modules/yard/components/YardViewYard.vue';
import YardViewNoWarehouse from '@/modules/yard/components/Overlays/YardViewNoWarehouse.vue';

import useYard from '@/modules/yard/composables/useYard';
import YardViewLoading from '@/modules/yard/components/Overlays/YardViewLoading.vue';
import YardViewPageSubHeader from '@/modules/yard/components/YardViewPageSubHeader.vue';
import YardViewSpotDetail from '@/modules/yard/components/Spot//Detail/YardViewSpotDetail.vue';
import { useEventHub, useSockets } from '@/composables';

export default defineComponent({
  name: 'YardViewPage',
  components: {
    YardViewSpotDetail,
    YardViewPageSubHeader,
    YardViewLoading,
    YardViewNoWarehouse,
    YardViewYard,
    YardViewNotAssignedList,
    YardViewPageHeader
  },
  props: {},
  setup() {
    const yardStore = useYard();

    const sockets = useSockets();
    const eventHub = useEventHub();

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-AssetVisit`, yardStore.handleAssetVisitSubspaceEvent);
        eventHub.$on(`${operation}-AssetVisitEvent`, yardStore.handlerAssetVisitEventSubspaceEvent);
      });
      if (yardStore.selectedWarehouseRef.value?.id) {
        yardStore.fetchAssetVisits();
        yardStore.fetchSpotAreas();
        yardStore.fetchSpots();
      }
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-AssetVisit`, yardStore.handleAssetVisitSubspaceEvent);
        eventHub.$off(
          `${operation}-AssetVisitEvent`,
          yardStore.handlerAssetVisitEventSubspaceEvent
        );
      });
    });

    return {
      enableYardView: yardStore.enableYardViewRef,
      selectedWarehouse: yardStore.selectedWarehouseRef,
      isLoading: yardStore.isLoadingSpotsRef,
      selectedSpot: yardStore.selectedSpotRef
    };
  },
  beforeMount() {
    // TODO: Remove this when the ff enable-yard-view-helios is removed
    // Redirect to appointments page if yard view is disabled
    if (!this.enableYardView) {
      this.$router.replace({ name: 'appointments' });
    }
  }
});
</script>
<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
