var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { attrs: { elevation: "0", width: "250" } },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex flex-row" },
        [
          _c(
            "h1",
            {
              staticClass: "font-weight-bold text-uppercase",
              attrs: { "data-testid": "yard-spot-detail-title" },
            },
            [_vm._v(" " + _vm._s(_vm.spot.code) + " ")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                "data-testid": "yard-spot-detail-close-button",
              },
              on: { click: _vm.closeDetail },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { attrs: { "data-testid": "yard-spot-detail-content" } },
        [
          _c(
            "v-expansion-panels",
            [
              _c(
                "v-expansion-panel",
                [
                  _c("v-expansion-panel-header", [_vm._v("Spot events")]),
                  _c(
                    "v-expansion-panel-content",
                    { staticClass: "scrollable-content" },
                    _vm._l(_vm.selectedSpot.spotEvents, function (event) {
                      return _c("p", { key: event.id, staticClass: "mt-2" }, [
                        _c("strong", { staticClass: "text-capitalize" }, [
                          _vm._v(" " + _vm._s(event.eventType) + " "),
                        ]),
                        _c("br"),
                        _c("span", [
                          _vm._v(
                            _vm._s(event.reason) +
                              " @ " +
                              _vm._s(event.createDateTime)
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-column my-8" },
            [
              _c("h4", { staticClass: "mb-4" }, [_vm._v("Spot data")]),
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Spot code",
                      outlined: "",
                      rules: [_vm.validateCode],
                      dense: "",
                      "data-testid": "yard-spot-form-code-input",
                    },
                    model: {
                      value: _vm.spot.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.spot, "code", $$v)
                      },
                      expression: "spot.code",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      items: Object.keys(_vm.spotType).map((k) =>
                        k.toLowerCase()
                      ),
                      label: "Spot type",
                      rules: [_vm.validateType],
                      "data-testid": "yard-spot-form-type-select",
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.spot.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.spot, "type", $$v)
                      },
                      expression: "spot.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "mt-6",
          attrs: { "data-testid": "yard-spot-detail-actions" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column full-width" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-4",
                  attrs: {
                    block: "",
                    color: "primary",
                    "data-testid": "yard-spot-form-submit-button",
                  },
                  on: { click: _vm.updateSpot },
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                    _vm._v("mdi-check"),
                  ]),
                  _vm._v(" Update Spot "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-4",
                  attrs: {
                    block: "",
                    outlined: "",
                    "data-testid": "yard-spot-form-delete-button",
                    disabled: _vm.isSpotAssigned(_vm.spot),
                  },
                  on: { click: _vm.deleteSpot },
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                    _vm._v("mdi-close"),
                  ]),
                  _vm._v(" Delete Spot "),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }