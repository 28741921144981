<template>
  <v-dialog :value="statusType" width="400" @close="statusType = null">
    <v-card v-if="statusType">
      <v-card-title>
        <h5 class="text-capitalize" data-testid="yard-spot-status-dialog-title">
          {{ statusType }} this Spot?
        </h5>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <p v-if="statusType === 'close'">
            This spot will remain unavailable for asset assignment until it is set as open again.
          </p>
          <p v-else>This spot will be available for asset assignment.</p>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="reason"
                data-testid="yard-spot-status-dialog-reason-input"
                label="Reason"
                outlined
                dense
                autofocus
                required
                clearable></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="observation"
                data-testid="yard-spot-status-dialog-observation-input"
                label="Additional Notes"
                outlined
                dense
                clearable></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeDialog" data-testid="yard-spot-status-dialog-cancel">
            Nevermind
          </v-btn>
          <v-btn
            color="primary"
            @click="submit"
            :disabled="!reason"
            data-testid="yard-spot-status-dialog-submit">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { useEventHub } from '@/composables';

export default defineComponent({
  name: 'YardViewSpotStatusDialog',

  props: {
    spot: {
      type: Object,
      required: true
    },
    statusType: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const eventHub = useEventHub();
    const selectedWarehouse = computed(() => yardStore.selectedWarehouse);

    const reason = ref('');
    const observation = ref('');

    const submit = () => {
      if (props.statusType === 'close') {
        closeSpot();
      } else {
        openSpot();
      }
      closeDialog();
    };

    const closeDialog = () => {
      eventHub.$emit('close-spot-status-dialog');
    };

    const closeSpot = () => {
      yardStore.closeSpot(props.spot.id, { reason: reason.value, observation: observation.value });
    };

    const openSpot = () => {
      yardStore.openSpot(props.spot.id, { reason: reason.value, observation: observation.value });
    };

    return {
      selectedWarehouse,
      selectedSpot: yardStore.selectedSpotRef,
      submit,
      closeDialog,
      reason,
      observation,
      spotStatus: yardStore.spotStatus,
      getSpotStatus: yardStore.getSpotStatus,
      spotType: yardStore.spotType
    };
  }
});
</script>
