<template>
  <v-dialog
    :key="renderKey"
    transition="slide-left"
    :content-class="`checkin-notification-dialog ${showAdvertisement ? 'is-advertisement' : ''}`"
    v-model="shouldShowDialog">
    <template #activator="{ on }">
      <div v-on="on" class="checkin-activator" data-testid="grid-header-checkin-activator-button">
        <div class="checkin-header">
          <v-icon class="mr-2 text--color-neutral-90" small>mdi-bell</v-icon>
          <span>Arrivals</span>
        </div>
        <div class="checkin-footer">
          <template v-if="isLoading">
            <v-progress-circular indeterminate size="20" width="1"></v-progress-circular>
          </template>
          <template v-else>
            <template v-if="totalNewAssetVisitCount > 0">
              <v-btn block x-small text>
                <div class="sonar-badge is-relative" v-if="totalNewAssetVisitCount > 0">
                  <div class="badge badge-primary"></div>
                  <div class="badge ping ping-1"></div>
                  <div class="badge ping ping-2"></div>
                </div>
                <span>{{ totalNewAssetVisitCount }} New</span>
              </v-btn>
            </template>
            <template v-else>No new arrivals</template>
          </template>
        </div>
      </div>
    </template>

    <v-card v-if="shouldShowInfo" class="d-flex flex-column">
      <v-card-title class="dialog-title align-center">
        <v-btn icon @click="shouldShowInfo = false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="pl-3">About arrivals</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="shouldShowInfo = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text>
        <span>
          This list shows arrivals from drivers who checked-in on Opendock. Arrivals will be shown
          in the following statuses:
        </span>
        <p class="mt-6">
          <strong>Pending</strong>
          <span class="pt-2 d-block">
            These are unplanned arrivals that are pending an action. You can either create a new
            appointment for this asset, link it to an existing appointment, or reject the load. You
            will see all Pending arrivals that were checked-in within the last 24 hours.
          </span>
        </p>

        <p class="mt-6">
          <strong>Planned</strong>
          <span class="pt-2 d-block">
            These are arrivals that already had an appointment at the moment of check-in, or someone
            linked a Pending arrival to an existing appointment. You will see all Planned arrivals
            that were checked-in within the last 24 hours.
          </span>
        </p>

        <p class="mt-6">
          <strong>Resolved</strong>
          <span class="pt-2 d-block">
            These are unplanned arrivals that have already been acted upon. They either already have
            an appointment created or the load was rejected. You will see all Resolved arrivals that
            were checked-in within the last 24 hours.
          </span>
        </p>
      </v-card-text>
    </v-card>

    <v-card class="d-flex flex-column" v-else>
      <template v-if="showAdvertisement">
        <v-card-title class="dialog-title">
          <v-spacer></v-spacer>
          <v-btn icon @click="shouldShowDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <check-in-advertisement :warehouse="warehouse" />
      </template>

      <template v-else>
        <v-card-title class="dialog-title">
          <span class="headline">Arrivals in {{ warehouse?.name }}</span>
          <v-btn icon @click="shouldShowDialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="height-auto flex-1 flex-column d-flex">
          <template v-if="totalAssetVisitsForWarehouse === 0">
            <icon-message
              icon="truck-outline"
              messageHeader="No arrivals yet on this warehouse"
              message="You can choose a different warehouse in the Appointments page." />
          </template>
          <template v-else>
            <v-tabs v-model="activeTab" class="py-3 sticky-header align-center" hide-slider>
              <v-tab class="flex-grow-1 tab" data-testid="checkin-panel-pending-tab">
                <span>Pending</span>
                <span class="count">{{ unplannedAndUnresolvedAssetVisitCount }}</span>
              </v-tab>
              <v-tab class="flex-grow-1 tab" data-testid="checkin-panel-planned-tab">
                <span>Planned</span>
                <span class="count">{{ plannedAssetVisitCount }}</span>
              </v-tab>
              <v-tab class="tab flex-grow-1" data-testid="checkin-panel-resolved-tab">
                <span>Resolved</span>
                <span class="count">{{ resolvedAssetVisitCount }}</span>
              </v-tab>
              <v-btn icon @click="shouldShowInfo = true" small>
                <v-icon small>mdi-information-outline</v-icon>
              </v-btn>
            </v-tabs>

            <v-tabs-items
              v-model="activeTab"
              class="mt-3"
              :class="{
                'd-flex': isTabFlexBox
              }">
              <v-tab-item>
                <template v-if="unplannedAndUnresolvedAssetVisitCount === 0">
                  <icon-message
                    icon="check-circle"
                    messageHeader="No pending arrivals on this warehouse"
                    message="You can use the tabs above to see other types of arrivals" />
                </template>
                <template v-else>
                  <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                  <div
                    class="asset-visit-group v-item-group"
                    v-for="(visits, date) in unplannedAndUnresolvedByDate"
                    :key="date">
                    <div class="date-label">{{ date }}</div>
                    <asset-visit-item
                      v-for="visit in visits"
                      :key="`${visit.id}-${visit.assetVisitEvents.length}`"
                      :warehouse="$selectedWarehouse"
                      :last-driver-message-timestamp="lastDriverMessageMap[visit.messageThread?.id]"
                      :unread-messages-count="visit.messageThread?.unreadMessagesCount"
                      :visit="visit"
                      @close="shouldShowDialog = false"
                      @updated="updateAssetVisitInArr"
                      @show-appointment-list-dialog="
                        handleAppointmentListDisplay(visit)
                      "></asset-visit-item>
                  </div>
                </template>
              </v-tab-item>

              <v-tab-item>
                <template v-if="plannedAssetVisitCount === 0">
                  <icon-message
                    icon="check-circle"
                    messageHeader="No planned arrivals on this warehouse today"
                    message="You can use the tabs above to see other types of arrivals" />
                </template>

                <template v-else>
                  <div
                    class="asset-visit-group v-item-group"
                    v-for="(visits, date) in plannedAndUnresolvedByDate"
                    :key="date">
                    <div class="date-label">{{ date }}</div>
                    <asset-visit-item
                      v-for="visit in visits"
                      :key="`${visit.id}-${visit.assetVisitEvents.length}`"
                      :warehouse="$selectedWarehouse"
                      :last-driver-message-timestamp="lastDriverMessageMap[visit.messageThread?.id]"
                      :unread-messages-count="visit.messageThread?.unreadMessagesCount"
                      is-planned
                      :visit="visit"
                      @updated="updateAssetVisitInArr"
                      @show-appointment-list-dialog="
                        handleAppointmentListDisplay(visit)
                      "></asset-visit-item>
                  </div>
                </template>
              </v-tab-item>
              <v-tab-item>
                <template v-if="resolvedAssetVisitCount === 0">
                  <icon-message
                    icon="check-circle"
                    messageHeader="No resolved arrivals on this warehouse"
                    message="You can use the tabs above to see other types of arrivals" />
                </template>
                <template v-else>
                  <!-- eslint-disable-next-line vue/no-v-for-template-key -->
                  <div
                    class="asset-visit-group v-item-group"
                    v-for="(visits, date) in resolvedByDate"
                    :key="date">
                    <div class="date-label">{{ date }}</div>
                    <asset-visit-item
                      v-for="visit in visits"
                      :allow-acknowledgement="false"
                      :key="`${visit.id}-${visit.assetVisitEvents.length}`"
                      :warehouse="$selectedWarehouse"
                      :last-driver-message-timestamp="lastDriverMessageMap[visit.messageThread?.id]"
                      :unread-messages-count="visit.messageThread?.unreadMessagesCount"
                      :visit="visit"
                      :is-rejected="isAssetVisitCancelled(visit)"
                      :is-appointment-created="Boolean(visit.appointmentId)"
                      @updated="updateAssetVisitInArr"></asset-visit-item>
                  </div>
                </template>
              </v-tab-item>
            </v-tabs-items>
          </template>
        </v-card-text>
      </template>
    </v-card>

    <link-appointment-dialog
      v-if="assetToLink"
      @close="shouldShowAppointmentListDialog = false"
      :asset-to-link="assetToLink"
      external-activator
      :warehouse="warehouse"
      :is-military-time-enabled="isMilitaryTimeEnabled"
      :reference-number-settings="referenceNumberSettings"
      @linked="handleAppointmentLinked"
      :show-dialog="shouldShowAppointmentListDialog"></link-appointment-dialog>
  </v-dialog>
</template>

<script lang="js">
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { DateTime } from 'luxon';
import AssetVisitItem from '@/modules/appointments/components/AssetVisitItem.vue';
import {
  AssetVisitEventType,
  getPlannedAssetVisits,
  isAssetVisitCancelled,
  getUnplannedAndUnresolvedAssetVisits,
  getResolvedAssetVisits,
} from '@satellite/../nova/core';
import { useStore, useRenderKey } from '@/composables';
import { merge } from 'lodash';
import useMixpanel from '@/composables/useMixpanel';

/**
 * @displayName Check-in Notification Dropdown
 */

let filterInterval = null;
export default {
  components: { AssetVisitItem },
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    org: {
      type: Object,
      required: true
    },
    showAdvertisement: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { renderKey, rerender } = useRenderKey();
    const store = useStore();
    const lastDriverMessageMap = ref({});

    /**
     * TODO: This is an ugly workaround to use the global event hub as it is today.  This will need to be changed to use a composable
     * when we migrate to Vue 3.  Good stack overflow post here for a strategy - https://stackoverflow.com/questions/63471824/vue-js-3-event-bus
     */
    const $eventHub = store.$app.$eventHub;
    // END of ugly workaround

    // Dialogs
    const shouldShowAppointmentListDialog = ref(false);
    const shouldShowDialog = ref(false);
    const shouldShowInfo = ref(false);

    // Loaders
    const isLoading = ref(false);

    const activeTab = ref(null);

    const assetToLink = ref(null);

    const totalAssetVisitsForWarehouse = ref(0);

    // Grouped asset visits
    const assetVisits = ref([]);
    const sortedAssetVisits = computed(() => [...assetVisits.value].sort((a, b) => DateTime.fromISO(a.createDateTime) - DateTime.fromISO(b.createDateTime)));

    const mixpanel = useMixpanel();

    const plannedAssetVisits = computed(() => getPlannedAssetVisits(sortedAssetVisits.value));
    const unacknowledgedPlannedVisits = computed(() => plannedAssetVisits.value.filter(visit => !visit.checkInAcknowledged));
    const unacknowledgedPlannedVisitCount = computed(() => unacknowledgedPlannedVisits.value.length);

    const unplannedAndUnresolvedAssetVisits = computed(() => getUnplannedAndUnresolvedAssetVisits(sortedAssetVisits.value).reverse());
    const unacknowledgedUnPlannedAndUnresolvedVisits = computed(() => unplannedAndUnresolvedAssetVisits.value.filter(visit => !visit.checkInAcknowledged));
    const unacknowledgedUnPlannedAndUnresolvedVisitCount = computed(() => unacknowledgedUnPlannedAndUnresolvedVisits.value.length);

    const resolvedAssetVisits = computed(() => getResolvedAssetVisits(sortedAssetVisits.value).reverse());

    // Grouped asset visit counts
    const assetVisitCount = computed(() => sortedAssetVisits.value.length);
    const plannedAssetVisitCount = computed(() => plannedAssetVisits.value.length);
    const unplannedAndUnresolvedAssetVisitCount = computed(() => unplannedAndUnresolvedAssetVisits.value.length);
    const resolvedAssetVisitCount = computed(() => resolvedAssetVisits.value.length);
    const totalNewAssetVisitCount = computed(() => unacknowledgedPlannedVisitCount.value + unacknowledgedUnPlannedAndUnresolvedVisitCount.value);

    // Grouped by date
    const unplannedAndUnresolvedByDate = computed(() => {
      return orderVisitsByDate(unplannedAndUnresolvedAssetVisits.value);
    });

    const plannedAndUnresolvedByDate = computed(() => {
      return orderVisitsByDate(plannedAssetVisits.value);
    });

    const resolvedByDate = computed(() => {
      return orderVisitsByDate(resolvedAssetVisits.value);
    });

    function orderVisitsByDate(visits) {
      return visits.reduce((acc, visit) => {
        const date = DateTime.fromISO(visit.createDateTime).setZone(props.warehouse.timezone).toFormat('DDDD');
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(visit);
        return acc;
      }, {});
    }

    const getBaseMixpanelData = computed(() => ({
        'Warehouse ID': props.warehouse.id,
        'Warehouse Name': props.warehouse.name,
        'Org ID': props.org.id,
        'Org Name': props.org.name
      })
    );

    const isTabFlexBox = computed(() => {
      return (unplannedAndUnresolvedAssetVisitCount.value === 0 && activeTab.value === 0) ||
        (plannedAssetVisitCount.value === 0 && activeTab.value === 1) ||
        (resolvedAssetVisitCount.value === 0 && activeTab.value === 2);
    });

    const isMilitaryTimeEnabled = computed(() => store.getters['Settings/isMilitaryTimeEnabled']({
      ...props.warehouse, org: props.org
    }));

    const referenceNumberSettings = computed(() => store.getters['Settings/refNumSettings']({
      ...props.warehouse, org: props.org
    }));

    const fetchAssetVisitParams = computed(() => {
      return {
        s: {
          $and: [
            { warehouseId: props.warehouse?.id},
            { createDateTime: {
                $gte: DateTime.now().minus({ days: 1}).toISO()
              }
            }]
        },
        join: ['company||name', 'appointment||start,status', 'assetVisitEvents',  'messageThread||unreadMessagesCount']

      }
    })

    async function makeMessageData() {
      const assetVisitsWithMessageThread = assetVisits.value.filter(visit => visit.messageThread);
      if (!assetVisitsWithMessageThread.length) { return }
      const { data } = await axios.post('/message-thread/unread-messages-count', {
        messageThreadIds: assetVisitsWithMessageThread.map(( av ) => av.messageThread?.id)
      });
      lastDriverMessageMap.value = data.data.reduce((acc, item) => {
        acc[item.id] = item.lastDriverMessageTimestamp;
        return acc;
      }, {});
    }

    async function fetchAssetVisits() {
      isLoading.value = true;
      try {
        const { data } = await axios.get('/asset-visit', { params: fetchAssetVisitParams.value });
        assetVisits.value = data?.data ?? [];
        await makeMessageData();
      } finally {
        isLoading.value = false;
      }
    }

    async function getTotalAssetVisitCountForWarehouse() {
      const response = await axios.get('/asset-visit', { params: { s: { warehouseId: props.warehouse?.id }, limit: 1} });
      return response?.data?.total ?? 0;
    }

    async function fetchAssetVisit(assetVisitId) {
      const { data } = await axios.get(`/asset-visit/${assetVisitId}`, { params: fetchAssetVisitParams.value });
      return data?.data ?? null;
    }

    function removeVisitsOlderThanADay() {
      const indicesToRemove = [];

      assetVisits.value.forEach((visit, index) => {
        if (DateTime.fromISO(visit.createDateTime) < DateTime.now().toUTC().minus({ days: 1})) {
          indicesToRemove.push(index);
        }
      });

      if (indicesToRemove.length > 0) {
        for (let i = indicesToRemove.length - 1; i >= 0; i--) {
          assetVisits.value.splice(indicesToRemove[i], 1);
        }
      }
    }

    function updateAssetVisitInArr(assetVisit) {
      if (!assetVisit) {
        return;
      }

      const existingVisitIndex = assetVisits.value.findIndex(visit => visit.id === assetVisit.id);

      if (existingVisitIndex === -1) {
        return;
      }

      const existingVisit = assetVisits.value[existingVisitIndex];

      // Merge the existing visit with the new asset visit data
      const mergedVisit = merge(
        existingVisit,
        assetVisit
      );

      // Update the merged visit with the unique events
      mergedVisit.assetVisitEvents = assetVisit.assetVisitEvents;

      // Replace the old visit in the array with the updated visit
      assetVisits.value.splice(existingVisitIndex, 1, mergedVisit);
    }

    function insertAssetVisitInArr(assetVisit) {
      if (assetVisit && !assetVisits.value.some(({ id }) => id === assetVisit.id)) {
        assetVisits.value.push(assetVisit);
      }
    }

    async function handleCreateAssetVisitEventSubspaceEvent(payload) {
      // To prevent both the asset visit and asset visit event handlers from duplicating, we should only
      // update the asset visit if it's already in the array
      const index = assetVisits.value.findIndex(visit => visit.id === payload.assetVisitId);
      if (index > -1) {
        updateAssetVisitInArr(await refetchAssetVisitIfInArr(payload.assetVisitId));
      }
    }

    async function handleCreateAssetVisitSubspaceEvent(payload) {
      if (payload?.warehouseId === props.warehouse?.id) {
        insertAssetVisitInArr(await fetchAssetVisit(payload.id));

        // Only refetch total count if it's 0
        if (totalAssetVisitsForWarehouse.value === 0) {
          totalAssetVisitsForWarehouse.value = await getTotalAssetVisitCountForWarehouse();
        }
      }
    }

    async function handleUpdateAssetVisitSubspaceEvent(payload) {
      updateAssetVisitInArr(await refetchAssetVisitIfInArr(payload.id));
    }

    async function handleDeleteAssetVisitEventSubspaceEvent(payload) {
      updateAssetVisitInArr(await refetchAssetVisitIfInArr(payload.assetVisitId));
    }

    async function handleUpdateAppointmentSubspaceEvent(payload) {
      const assetVisit = assetVisits.value.find(assetVisit => assetVisit.id === payload.assetVisit?.id);

      if (assetVisit) {
        updateAssetVisitInArr({ ...assetVisit, appointment: payload });
      }
    }

    async function refetchAssetVisitIfInArr(assetVisitId) {
      const assetVisitIndex = assetVisits.value.findIndex(assetVisit => assetVisit.id === assetVisitId);
      if (assetVisitIndex > -1) {
        return await fetchAssetVisit(assetVisitId);
      }
    }

    function handleAppointmentListDisplay(visit) {
      assetToLink.value = visit;
      shouldShowAppointmentListDialog.value = true;
    }

    async function handleAppointmentLinked() {
      const assetVisit = await refetchAssetVisitIfInArr(assetToLink.value.id);
      if (assetVisit) {
        updateAssetVisitInArr(assetVisit);
      }
      assetToLink.value = null;
      shouldShowAppointmentListDialog.value = false;
      mixpanel.track(mixpanel.events.MODULE.SELF_CHECK_IN.APPOINTMENT_LINKED, {
        ...getBaseMixpanelData.value,
        'Appointment ID': assetVisit?.appointmentId,
        'Asset ID': assetVisit?.id,
        'Existing Appointment': 'Yes'
      });
    }

    function handleUpdateMessageThreadSubspaceEvent(payload) {
      const relatedAssetVisit = assetVisits.value.find(visit => visit.id === payload.assetVisitId);
      if (relatedAssetVisit) {
        const originalCount = relatedAssetVisit.messageThread?.unreadMessagesCount ?? 0;
        relatedAssetVisit.messageThread = payload;
        if (relatedAssetVisit.messageThread.unreadMessagesCount > originalCount) {
          lastDriverMessageMap.value[payload.id] = payload.lastChangedDateTime;
        }
      }
    }

    onMounted(async () => {
      totalAssetVisitsForWarehouse.value = await getTotalAssetVisitCountForWarehouse();
      $eventHub.$on('create-AssetVisit', handleCreateAssetVisitSubspaceEvent);
      $eventHub.$on('update-AssetVisit', handleUpdateAssetVisitSubspaceEvent);
      $eventHub.$on('refresh-AssetVisit', handleUpdateAssetVisitSubspaceEvent);
      $eventHub.$on('update-Appointment', handleUpdateAppointmentSubspaceEvent);
      $eventHub.$on('update-MessageThread', handleUpdateMessageThreadSubspaceEvent);
      $eventHub.$on('create-AssetVisitEvent', handleCreateAssetVisitEventSubspaceEvent);
      $eventHub.$on('delete-AssetVisitEvent', handleDeleteAssetVisitEventSubspaceEvent);
      filterInterval = setInterval(() => {
        removeVisitsOlderThanADay();
      }, 60000)
    })

    onUnmounted(() => {
      filterInterval = clearInterval(filterInterval);
    })

    watch(() => props.warehouse?.id, async () => {
      assetVisits.value = [];
      totalAssetVisitsForWarehouse.value = await getTotalAssetVisitCountForWarehouse();
      await fetchAssetVisits();
    }, {immediate: true});

    watch(shouldShowDialog, () => {
      // Reset active tab and rerender component to collapse accordions when dialog is closed
      if (!shouldShowDialog.value) {
        activeTab.value = 0;
        rerender();
      } else {
        mixpanel.track(mixpanel.events.MODULE.SELF_CHECK_IN.OPEN_ARRIVALS_LIST, {
          ...getBaseMixpanelData.value,
          'Arrivals': totalNewAssetVisitCount.value > 0 ? `${totalNewAssetVisitCount.value} new Arrivals` : 'No new arrivals'
        });
      }
    })

    return {
      AssetVisitEventType,
      activeTab,
      assetToLink,
      assetVisitCount,
      handleAppointmentLinked,
      handleAppointmentListDisplay,
      isAssetVisitCancelled,
      isLoading,
      isTabFlexBox,
      plannedAssetVisitCount,
      plannedAssetVisits,
      renderKey,
      resolvedAssetVisitCount,
      resolvedAssetVisits,
      shouldShowAppointmentListDialog,
      shouldShowDialog,
      totalAssetVisitsForWarehouse,
      totalNewAssetVisitCount,
      unplannedAndUnresolvedAssetVisitCount,
      unplannedAndUnresolvedAssetVisits,
      updateAssetVisitInArr,
      shouldShowInfo,
      isMilitaryTimeEnabled,
      referenceNumberSettings,
      unplannedAndUnresolvedByDate,
      plannedAndUnresolvedByDate,
      resolvedByDate,
      lastDriverMessageMap,
      sortedAssetVisits
    };
  }
}
</script>

<style scoped lang="scss">
:deep .checkin-notification-dialog {
  transform-origin: center center;
  width: 620px;
  max-width: 100%;
  position: fixed;
  right: 0;
  margin: 0;
  height: 100vh;
  max-height: 100%;
  background: $color-neutral-0;

  &.is-advertisement {
    width: 300px;
  }

  .v-card {
    height: 100%;
    box-shadow: none;
    max-height: 100%;

    &__text {
      background-color: $color-background-primary;
    }
  }
}
.checkin-activator {
  border: 1px solid $color-neutral-20;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 120px;
  &:hover {
    cursor: pointer;
  }
}
.checkin-header {
  border-bottom: 1px solid $color-neutral-20;
  background-color: $color-neutral-20;
  padding: 4px 12px !important;
  font-size: 14px;
}
.checkin-footer {
  padding: 3px 3px !important;
  font-size: 12px;
}
.checkin-header,
.checkin-footer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.tab {
  border-radius: 8px;
  border: 1px solid $color-line-divider;
  background: $color-background-secondary;
  color: $color-text-placeholder;
  margin-left: 8px;
  width: 174px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;

  .count {
    font-size: 16px;
  }

  &:first-child {
    margin-left: 0 !important;
  }
}

:deep .v-badge__badge {
  padding: 4px 12px;
}

:deep .light-badge .v-badge__badge {
  color: $color-neutral-90;
}

:deep .v-tabs {
  flex-grow: 0;
}

:deep .v-tabs-items {
  flex: 1;
  align-items: center;
  justify-content: center;
}

:deep .v-window__container {
  width: 100%;
  height: 100%;

  .v-window-item {
    flex: 1;
  }
}

.asset-visit-group {
  .date-label {
    flex: 1;
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  &:first-child {
    .date-label {
      margin-top: 0;
    }
  }
}

.v-tab--active {
  color: $color-text-secondary;
  background-color: $color-primary-20;
  border: 1px solid $color-primary-60;
}

::v-deep {
  .v-tabs-bar {
    height: auto;
  }
  .asset-item-panel {
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
  }
}

.v-item-group {
  gap: 12px;
}

.sticky-header {
  position: sticky;
  top: 50px;
  z-index: 7;
}

.early-tag {
  color: $color-success-100;
}
.late-tag {
  color: $color-warning-100;
}
.on-time-tag {
  color: $color-text-secondary;
}

.badge {
  background-color: $color-primary-60;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 50%;
}
.badge-primary {
  z-index: 2;
  position: relative;
}
.ping {
  position: absolute;
  top: 0;
  left: 0;
}
.ping-1 {
  animation: sonarstart 1s infinite ease;
  z-index: 1;
}
.ping-2 {
  animation: sonarend 1s infinite ease;
  background-color: $color-neutral-0 !important;
  z-index: 1;
}

@keyframes sonarstart {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 1;
  }
}
@keyframes sonarend {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 1;
  }
}
</style>
