<template>
  <div>
    <v-card v-if="isLoading" class="text-center my-4" width="270">
      <v-card-text>
        <v-progress-circular color="primary" indeterminate size="22"></v-progress-circular>
        <p class="small mt-4 font-weight-bold">Loading Asset Visits data...</p>
      </v-card-text>
    </v-card>
    <v-card elevation="0" v-else width="250">
      <v-card-title class="d-flex">
        <p class="font-size-large font-weight-bold">
          <v-icon class="mr-1">mdi-clock-outline</v-icon>
          Not assigned
          <br />
          <v-chip class="mt-2" data-testid="yard-unassigned-list-carrier-count">
            <span class="font-size-medium pa-3">
              {{ assetVisits.length }} carrier{{ assetVisits.length !== 1 ? 's' : '' }}
            </span>
          </v-chip>
        </p>

        <p class="font-size-medium font-weight-regular">
          Drag and drop carriers to assign them a spot.
        </p>
      </v-card-title>
      <v-card-text class="pr-2 pl-4">
        <v-divider class="mb-6"></v-divider>

        <div v-if="assetVisits.length">
          <yard-view-asset-visit
            v-for="assetVisit in assetVisits"
            :asset-visit="assetVisit"
            :key="assetVisit.id" />
        </div>
        <div v-else>
          <p>All good, no drivers waiting.</p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

import YardViewAssetVisit from '@/modules/yard/components/Asset/YardViewAssetVisit.vue';

export default defineComponent({
  name: 'YardViewNotAssignedList',
  components: { YardViewAssetVisit },
  props: {},
  setup() {
    const yardStore = useYard();

    return {
      selectedWarehouse: yardStore.selectedWarehouseRef,
      assetVisits: yardStore.waitingAssetVisitsRef,
      isLoading: yardStore.isLoadingAssetVisitsRef,
      onBeforeMount,
      onBeforeUnmount
    };
  }
});
</script>
