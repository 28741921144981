<template>
  <form-base>
    <template slot="form">
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col>
              <text-field
                :required="true"
                :type="currentPasswordFieldType"
                v-model="currentPassword"
                :rules="$validator.rules.required('Current password')"
                label="Current Password"
                :append-icon="iconShowCurrentPassword"
                @click:append="toggleShowCurrentPassword()">
              </text-field>
            </v-col>
            <v-col>
              <text-field
                :required="true"
                :type="passwordFieldType"
                :rules="$validator.rules.password"
                :append-icon="iconShowPassword"
                @click:append="toggleShowPassword()"
                v-model="newPassword"
                label="New Password">
              </text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template slot="form-actions">
      <password-restrictions :password="newPassword"> </password-restrictions>
      <div class="mt-6">
        <p class="text-center small">
          You will be asked to login again after changing the password!
        </p>
      </div>
      <action-group
        @cancel="$emit('close')"
        confirm-icon="upload"
        confirm-label="Change Password"
        @confirm="submit">
      </action-group>
    </template>
  </form-base>
</template>

<script>
export default {
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      showPassword: false,
      showCurrentPassword: false
    };
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$store
        .dispatch('Users/changeCurrentUserPassword', {
          newPassword: this.newPassword,
          currentPassword: this.currentPassword
        })
        .then(() => this.$emit('close'));
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toggleShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    }
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    },
    iconShowPassword() {
      return this.showPassword ? 'mdi-eye-off' : 'mdi-eye';
    },
    currentPasswordFieldType() {
      return this.showCurrentPassword ? 'text' : 'password';
    },
    iconShowCurrentPassword() {
      return this.showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye';
    }
  }
};
</script>
