var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { "data-testid": `yard-spot-assigned-${_vm.spot.code}` } },
    [
      _c("svg-truck", {
        attrs: { rotation: 90, scale: 1.2, theme: _vm.truckThemeColor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }