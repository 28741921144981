<template>
  <v-row class="mb-4">
    <v-col sm="6">
      <h1>Live Yard View</h1>
    </v-col>
    <v-spacer></v-spacer>
    <v-col sm="6" class="d-flex flex-row justify-end" v-if="selectedWarehouse?.id">
      <v-card
        elevation="1"
        :data-testid="`yard-view-page-header-card-${card.title}`"
        v-for="card of cards"
        width="190"
        height="70"
        class="ml-4"
        :key="card.title">
        <v-card-subtitle class="pb-0 pt-3 text-uppercase">
          {{ card.title }}
        </v-card-subtitle>
        <v-card-text>
          <h3>{{ card.count }}</h3>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewPageHeader',

  props: {},
  setup() {
    const yardStore = useYard();

    const spots = computed(() =>
      yardStore.spotsRef.value.filter(spot =>
        yardStore.selectedAreasRef.value.includes(spot.spotAreaId)
      )
    );

    const parkedCount = computed(
      () =>
        spots.value.filter(
          spot => spot.spotAssignments.length > 0 && spot.type === yardStore.spotType.PARKING
        ).length
    );

    const dockedCount = computed(
      () =>
        spots.value.filter(
          spot => spot.spotAssignments.length > 0 && spot.type === yardStore.spotType.DOCKING
        ).length
    );

    const cards = computed(() => [
      {
        title: 'PARKED',
        count: parkedCount.value
      },
      {
        title: 'DOCKED',
        count: dockedCount.value
      }
    ]);

    return {
      cards,
      selectedWarehouse: yardStore.selectedWarehouseRef
    };
  }
});
</script>
