<template functional>
  <v-overlay
    :value="props.isVisible"
    :absolute="true"
    :color="props.color"
    :opacity="props.opacity">
    <div class="overlay-contents">
      <svg-warehouse />
      <h3 class="align-center">No warehouse or dock selected</h3>
      <p>Please select a warehouse along with one or more docks to continue</p>
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: 'NoWarehouseSelectionOverlay',
  props: {
    /*
     * Flag to control whether the overlay is visible over content
     */
    isVisible: {
      type: Boolean,
      default: false,
      required: true
    },
    /*
     * Overlay background opacity decimal, from 0 to 1
     */
    opacity: {
      type: Number,
      default: 0.7,
      required: false
    },
    /*
     * Overlay background color
     */
    color: {
      type: String,
      default: 'white',
      required: false
    }
  }
};
</script>
