<template>
  <div class="pt-5 px-3 mt-4 mb-4">
    <h2 class="pl-4">All Insights</h2>
    <v-row class="mt-8 px-5">
      <span class="font-weight-bold font-size-base align-self-center pb-4 pr-3 pl-1">Filter:</span>
      <v-col data-testid="chart-grid-warehouse-select-container">
        <warehouse-select
          v-model="selectedWarehouse"
          @input="w => persistLastSelectedData('warehouseId', w?.id)"
          :auto-select-by-id="getLastSelectedWarehouseId()"
          :restricted-warehouse-ids="$me.warehouseAccessList ? $me.warehouseAccessList : null"
          :multi-select="false"
          hide-icon
          should-auto-select-first
          custom-persistent-label="Location"
          :clearable="false"></warehouse-select>
      </v-col>
      <v-col class="d-flex align-center py-0 pb-4">
        <date-range
          :disabled="showAllTime"
          input-max-width="20rem"
          v-model="dateRange"
          v-if="dateRangesReady"
          :initial-range="dateRange"></date-range>
        <all-time-checkbox v-model="showAllTime" class="ml-2"></all-time-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="layout" tile color="secondary" group>
        <v-btn value="stacked">
          <span class="hidden-sm-and-down">Stacked</span>
          <v-icon right>mdi-view-sequential</v-icon>
        </v-btn>
        <v-btn value="grid">
          <span class="hidden-sm-and-down">Grid</span>
          <v-icon right>mdi-view-grid</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="mt-0">
      <v-col :cols="layout === 'grid' ? 6 : 12" class="pt-0">
        <appointments-by-week-day-bar-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          :consolidate-capacity-docks="consolidateCapacityDocks"
          :selected-warehouse="selectedWarehouse"></appointments-by-week-day-bar-chart>
      </v-col>
      <v-col :cols="layout === 'grid' ? 6 : 12" class="pt-0">
        <appointments-by-load-type-bar-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          :selected-warehouse="selectedWarehouse"></appointments-by-load-type-bar-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="layout === 'grid' ? 6 : 12">
        <appointment-statuses-by-dock-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          :consolidate-capacity-docks="consolidateCapacityDocks"
          :selected-warehouse="selectedWarehouse"></appointment-statuses-by-dock-chart>
      </v-col>
      <v-col :cols="layout === 'grid' ? 6 : 12">
        <appointments-time-of-day-bar-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          :selected-warehouse="selectedWarehouse"></appointments-time-of-day-bar-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="layout === 'grid' ? 6 : 12">
        <appointments-in-date-range-bar-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          :selected-warehouse="selectedWarehouse"></appointments-in-date-range-bar-chart>
      </v-col>
      <v-col :cols="layout === 'grid' ? 6 : 12">
        <appointments-by-carrier-bar-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          :selected-warehouse="selectedWarehouse"></appointments-by-carrier-bar-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="layout === 'grid' ? 6 : 12">
        <average-duration-by-load-type-bar-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          :selected-warehouse="selectedWarehouse"></average-duration-by-load-type-bar-chart>
      </v-col>
      <v-col :cols="layout === 'grid' ? 6 : 12">
        <dock-dwell-time-chart
          :key="renderKey"
          :date-range="dateRange"
          :show-all-time="showAllTime"
          v-if="!novaCore.isInternalRole($me.role) && selectedWarehouse"
          :selected-warehouse="selectedWarehouse"></dock-dwell-time-chart>
      </v-col>
      <v-col :cols="layout === 'grid' ? 6 : 8">
        <first-avail-appts
          :key="renderKey"
          v-if="!novaCore.isInternalRole($me.role) && selectedWarehouse"
          :selected-warehouse="selectedWarehouse">
        </first-avail-appts>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import renderMixin from '@satellite/components/mixins/renderMixin';
import dateRangeFilterMixin from '@/modules/insights/components/mixins/dateRangeFilterMixin';
import persistFilterMixin from '@/modules/insights/components/mixins/persistFilterMixin';
import { isUUID } from 'class-validator';

export default {
  mixins: [renderMixin, dateRangeFilterMixin, persistFilterMixin],
  data() {
    return {
      layout: 'stacked',
      selectionPersistKey: 'nova_insights_chart_grid',
      selectedWarehouse: null,
      showAllTime: false,
      queryParams: {},
      initialLookbackDays: 6,
      useCurrentWeek: false,
      dateRangesReady: false,
      consolidateCapacityDocks: true
    };
  },
  computed: {},
  methods: {
    getLastSelectedWarehouseId() {
      const filterData = this.getPersistedFilterData();
      if (isUUID(filterData?.warehouseId)) {
        return filterData.warehouseId;
      }
    }
  },
  beforeMount() {
    this.initDateRanges();
  }
};
</script>
