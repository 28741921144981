<template>
  <v-row class="my-2">
    <v-col class="pl-4 pa-0" cols="10">
      <v-row class="pa-0">
        <v-col class="pt-4 pb-0">
          <p class="font-size-medium">{{ spotArea.name }}</p>
        </v-col>
      </v-row>
      <v-row class="pa-0 mt-0 mb-0">
        <v-col class="pa-2 pb-1">
          <v-chip
            small
            color="secondary"
            class="mr-2"
            :data-testid="`yard-main-area-header-open-${spotArea.id}`">
            <v-icon small class="mr-2">mdi-eye</v-icon>
            {{ spotsOpen }} open
          </v-chip>
          <v-chip
            small
            color="secondary"
            class="mr-2"
            :data-testid="`yard-main-area-header-closed-${spotArea.id}`">
            <v-icon small class="mr-2">mdi-eye</v-icon>
            {{ spotsClosed }} closed
          </v-chip>
          <v-chip
            small
            color="secondary"
            class="mr-2"
            :data-testid="`yard-main-area-header-occupied-${spotArea.id}`">
            <v-icon small class="mr-2">mdi-eye</v-icon>
            {{ spotsOccupied }} occupied
          </v-chip>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
    <v-col cols="2" class="pt-5">
      <v-btn small text class="mr-4" @click="editSpotArea">Edit Area</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router/composables';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotAreaHeader',
  components: {},
  props: {
    spotArea: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const yardStore = useYard();

    const spots = computed(() =>
      yardStore.spotsRef.value.filter(s => s.spotAreaId === props.spotArea.id)
    );
    const editSpotArea = () =>
      router.push({
        name: 'yard-spot-area-detail',
        params: {
          warehouseId: yardStore.selectedWarehouseRef.value.id,
          spotAreaId: props.spotArea.id
        }
      });

    const spotsOpen = computed(
      () => spots.value.filter(s => yardStore.getSpotStatus(s) === yardStore.spotStatus.OPEN).length
    );
    const spotsClosed = computed(
      () =>
        spots.value.filter(s => yardStore.getSpotStatus(s) === yardStore.spotStatus.CLOSED).length
    );
    const spotsOccupied = computed(
      () =>
        spots.value.filter(s => yardStore.getSpotStatus(s) === yardStore.spotStatus.ASSIGNED).length
    );

    return {
      editSpotArea,
      isReadOnly: yardStore.isReadOnlyRef,
      spots,
      spotsOpen,
      spotsClosed,
      spotsOccupied
    };
  }
});
</script>
