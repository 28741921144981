var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("yard-view-page-header"),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c("yard-view-page-sub-header")],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { col: "12" } },
                    [
                      _vm.selectedWarehouse?.id
                        ? _c("yard-view-yard")
                        : _c("yard-view-no-warehouse"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _vm.selectedWarehouse?.id
            ? _c(
                "v-navigation-drawer",
                {
                  staticClass: "left-border-box pa-2",
                  attrs: { app: "", right: "", permanent: "" },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide-fade", mode: "out-in" } },
                    [
                      _vm.selectedSpot?.id
                        ? _c("yard-view-spot-detail", {
                            attrs: { spot: _vm.selectedSpot },
                          })
                        : _c("yard-view-not-assigned-list"),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isLoading ? _c("yard-view-loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }