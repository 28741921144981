<template>
  <div>
    <h3 class="mb-4">
      <v-icon class="mr-2">mdi-truck-outline</v-icon>
      {{ companyName }}
    </h3>

    <template v-if="appointment">
      <strong>Scheduled for</strong>
      <p>
        {{ scheduledFor.date }}
        <br />
        {{ scheduledFor.start }} - {{ scheduledFor.end }}
      </p>

      <strong>Load Type</strong>
      <p>{{ appointment.loadType.name }} ({{ appointment.loadType.direction }})</p>

      <template v-if="appointment.refNumber">
        <strong>Reference Number</strong>
        <p>{{ appointment.refNumber }}</p>
      </template>

      <template>
        <strong>Status</strong>
        <p class="text-capitalize">{{ appointment.status }}</p>
      </template>

      <v-btn
        block
        @click="
          $router.push({
            name: 'appointments',
            replace: true,
            query: {
              appointmentId: appointment.id
            }
          })
        ">
        View Appointment
      </v-btn>
    </template>
    <template v-else>
      <p>No appointment scheduled</p>
    </template>

    <v-divider class="my-6"></v-divider>

    <template>
      <h4>Asset Events</h4>
      <v-list tile>
        <v-list-item v-for="event in assetVisitEvents" :key="event.id">
          <div class="mr-4">
            <v-icon x-small>mdi-clock-outline</v-icon>
          </div>

          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ event.eventType }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>{{ formatHour(event.createDateTime) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </div>
</template>
<script>
import { computed, defineComponent, onBeforeMount, onBeforeUnmount } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import { LuxonDateTimeFormats } from '@satellite/../nova/core';
import { useEventHub, useSockets } from '@/composables';

export default defineComponent({
  name: 'YardViewSpotDetailAssigned',
  props: {
    spotAssignment: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();

    const appointment = computed(() => {
      return props.spotAssignment.assetVisit.appointment;
    });

    const companyName = computed(() => {
      return (
        props.spotAssignment.assetVisit.company?.name || props.spotAssignment.assetVisit.companyHint
      );
    });

    const formatHour = value =>
      yardStore.formatDateTime(
        value,
        LuxonDateTimeFormats.Extended12HrTimeAMPM,
        LuxonDateTimeFormats.Extended24HrTime
      );

    const scheduledFor = computed(() => {
      return {
        date: yardStore.formatDateTime(
          appointment.value?.start,
          LuxonDateTimeFormats.DateWithAbbreviatedMonth
        ),
        start: formatHour(appointment.value?.start),
        end: formatHour(appointment.value?.end)
      };
    });

    const assetVisitEvents = computed(() => {
      return props.spotAssignment.assetVisit.assetVisitEvents || [];
    });

    const sockets = useSockets();
    const eventHub = useEventHub();

    onBeforeMount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$on(`${operation}-Appointment`, yardStore.handleAppointmentSubspaceEvent);
        eventHub.$on(`${operation}-AssetVisitEvent`, yardStore.handlerAssetVisitEventSubspaceEvent);
      });
    });

    onBeforeUnmount(() => {
      Object.keys(sockets.actions).forEach(operation => {
        eventHub.$off(`${operation}-Appointment`, yardStore.handleAppointmentSubspaceEvent);
        eventHub.$off(
          `${operation}-AssetVisitEvent`,
          yardStore.handlerAssetVisitEventSubspaceEvent
        );
      });
    });

    return {
      isSpotOpen: yardStore.isSpotOpen,
      spot: yardStore.selectedSpotRef,
      formatHour,
      companyName,
      appointment,
      scheduledFor,
      assetVisitEvents
    };
  }
});
</script>
<style scoped lang="scss"></style>
