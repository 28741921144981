<template>
  <div>
    <div>Forms required for the drivers arrival and departure..</div>
    <div class="disclaimer font-size-x-small">
      <template v-if="isGateManagementEnabled">
        Customize your forms at the warehouse
        <router-link
          :to="{
            name: 'warehouses.details.forms',
            params: { warehouseId: appointment.dock.warehouseId }
          }">
          Fields and Forms
        </router-link>
        section.
      </template>
      <template v-else>
        Check-in and Asset Details are part of our Gate Management add-on solution.
        <a target="_blank" rel="noopener" :href="novaCore.GATE_MANAGEMENT_SALES_URL">
          Contact Sales
        </a>
        to learn more.
      </template>
    </div>

    <custom-forms-data-panels
      class="mt-4"
      :editable="false"
      :is-locked="!isGateManagementEnabled"
      :object-ids="{
        appointmentId: appointment.id,
        assetvisitId: appointment.assetVisit?.id
      }"
      :appointment="appointment"
      :triggers="staticTriggers"
      :warehouse="appointment.warehouse"
      :timezone="appointment.warehouse.timezone"
      :military-time-enabled="$isMilitaryTimeEnabled(appointment.warehouse)">
      <template #additional-disclaimer="{ trigger, lastChangedDateTimesInTimezone }">
        <span
          v-if="!lastChangedDateTimesInTimezone[trigger?.id] && !appointment.assetVisit?.id"
          class="text--color-text-disabled">
          {{ novaCore.CustomFormsFeaturesData[trigger?.feature]?.title?.split(':')[0] }}
          pending
        </span>
        <span
          class="text--color-text-disabled"
          v-else-if="
            !lastChangedDateTimesInTimezone[trigger?.id] &&
            appointment.assetVisit?.id &&
            trigger?.id !== assetDetailsTrigger?.id
          ">
          No appointment details provided
        </span>
      </template>
      <template
        v-if="appointment.assetVisit?.id"
        v-slot:[`additional-form-data-${assetDetailsTrigger?.id}`]>
        <p class="mb-0">
          <strong class="mr-2">Phone:</strong>
          <span>{{ appointment.assetVisit.phone }}</span>
        </p>
      </template>
    </custom-forms-data-panels>

    <custom-forms-data-panels
      ref="editableFormsPanel"
      :editable="isCheckoutEditable"
      :object-ids="{
        appointmentId: appointment.id,
        assetvisitId: appointment.assetVisit?.id
      }"
      :appointment="appointment"
      :triggers="editableTriggers"
      :warehouse="appointment.warehouse"
      :timezone="appointment.warehouse.timezone"
      :military-time-enabled="$isMilitaryTimeEnabled(appointment.warehouse)">
      <template #additional-disclaimer="{ trigger, lastChangedDateTimesInTimezone }">
        <span v-if="!lastChangedDateTimesInTimezone[trigger?.id]" class="text--color-text-disabled">
          {{ novaCore.CustomFormsFeaturesData[trigger?.feature]?.title?.split(':')[0] }}
          pending
        </span>
      </template>
    </custom-forms-data-panels>
    <v-alert class="mt-4" border="left" colored-border color="warning" v-if="!hasActiveTriggers">
      No Gate Management forms have been enabled.
    </v-alert>
  </div>
</template>
<script>
import { useNovaCore } from '@/composables';
import { computed, ref, watch } from 'vue';

export default {
  props: {
    appointment: {
      type: Object,
      required: true
    },
    triggers: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const novaCore = useNovaCore();
    const editableFormsPanel = ref(null);
    const isCheckoutEditable = ref(false);

    const editableTriggerNames = [novaCore.CustomFormsFeatures.CheckOut];

    const staticTriggers = computed(() => {
      return props.triggers.filter(trigger => {
        return !editableTriggerNames.some(editable =>
          trigger.feature?.toLowerCase()?.includes(editable)
        );
      });
    });

    const assetDetailsTrigger = computed(() => {
      return props.triggers.find(trigger => {
        return trigger.feature === novaCore.CustomFormsFeatures.CheckInAdditionalAssetDetails;
      });
    });

    const editableTriggers = computed(() => {
      return props.triggers.filter(trigger => {
        return !staticTriggers.value.includes(trigger);
      });
    });

    const hasActiveTriggers = computed(() => {
      return props.triggers.filter(trigger => trigger.isActive).length > 0;
    });

    const isGateManagementEnabled = computed(() => {
      return props.appointment.dock.warehouse.geolocation;
    });

    watch(
      () => editableFormsPanel.value?.triggerDataLastChangedDateTimes,
      newVal => {
        if (
          Object.values(newVal).every(value => value) &&
          props.appointment.status === novaCore.AppointmentStatus.Completed
        ) {
          isCheckoutEditable.value = true;
        } else {
          isCheckoutEditable.value = false;
        }
      }
    );

    return {
      novaCore,
      staticTriggers,
      editableTriggers,
      editableFormsPanel,
      isCheckoutEditable,
      hasActiveTriggers,
      isGateManagementEnabled,
      editableTriggerNames,
      assetDetailsTrigger
    };
  }
};
</script>
