<template>
  <form-base :hide-required-legend="true">
    <template slot="form">
      <v-form class="pt-6" ref="form">
        <div class="time-input">
          <div class="d-flex">
            <date-picker
              class="timestamp-date-picker"
              v-model="customDate"
              placeholder="Select date"
              compact-display
              outlined
              no-margins
              :display-field-label="false"
              :display-field-icon="false"
              required></date-picker>
            <time-entry
              class="ml-2"
              outlined
              hide-timezone
              :warehouse="appointment.dock.warehouse"
              v-model="customTime"></time-entry>
          </div>

          <div class="d-flex mt-4">
            <div class="menu-container full-width">
              <v-btn-toggle @change="adjustTime" class="full-width" color="primary">
                <v-btn
                  dense
                  small
                  outlined
                  v-for="(option, index) in timeAdjustmentOptions"
                  class="flex-grow-1"
                  :key="`${index}-timeline-option`">
                  {{ option.label }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </div>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        confirm-label="Save"
        hide-cancel
        :loading="isLoading"
        @confirm="updateAppointment"
        :is-spacer-visible="false">
        <template #additional-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('close')" class="mr-2" :disabled="isLoading">Nevermind</v-btn>
        </template>
      </action-group>
    </template>
  </form-base>
</template>

<script>
import StatusTimelineEditFormBase from '@/modules/appointments/components/StatusTimelineEditFormBase.vue';

export default {
  extends: StatusTimelineEditFormBase
};
</script>

<style lang="scss" scoped>
::v-deep .time-entry .v-input {
  margin-top: 0 !important;
}
.time-input {
  width: 100%;
}
</style>
