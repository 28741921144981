import { getResponse } from '@satellite/services/serviceUtilities';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async updateAssetVisit(assetVisitId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(`asset-visit/${assetVisitId}`, data, requestConfig);

    return getResponse(response, options);
  },
  async getMessageThreadByAssetVisitId(assetVisitId, options = {}, requestConfig = {}) {
    const response = await axios.post(
      'message-thread',
      {
        assetVisitId: assetVisitId
      },
      requestConfig
    );

    return getResponse(response, options);
  },
  async sendMessage(messageThreadId, data, options = {}, requestConfig = {}) {
    const config = {
      ...{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      },
      ...requestConfig
    };
    const response = axios.post(`message-thread/${messageThreadId}/send-sms`, data, config);

    return getResponse(response, options);
  },
  async updateLastReadMessage(messageThreadId, messageId, options = {}, requestConfig = {}) {
    const response = axios.post(
      `message-thread/${messageThreadId}/last-read-message`,
      {
        messageThreadMessageId: messageId
      },
      requestConfig
    );

    return getResponse(response, options);
  },
  async openMessageThread(messageThreadId, options = {}, requestConfig = {}) {
    const response = axios.post(`message-thread/${messageThreadId}/open`, requestConfig);

    return getResponse(response, options);
  },
  async closeMessageThread(messageThreadId, options = {}, requestConfig = {}) {
    const response = axios.post(`message-thread/${messageThreadId}/close`, requestConfig);

    return getResponse(response, options);
  }
};
