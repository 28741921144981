<template>
  <div class="w-100 d-flex justify-center align-center mt-10">
    <div class="overlay-contents" data-testid="yard-no-areas-message">
      <svg-spot-area />
      <h3 class="align-center">No Yard Areas</h3>
      <p>Add areas to the warehouse yard view and start assigning assets to spots.</p>

      <primary-button class="mt-4" @click="() => $router.push({ name: 'yard-add-spot-area' })">
        Add Area
      </primary-button>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import SvgSpotArea from '@/components/svgs/SvgSpotArea.vue';

export default defineComponent({
  name: 'YardViewNoAreas',
  components: { SvgSpotArea }
});
</script>
