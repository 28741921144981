<template>
  <v-card
    :width="cardWidth"
    :height="cardHeight"
    elevation="1"
    @click="handleSpotClick"
    :data-testid="`yard-spot-${spot.code}`"
    :id="spot.id"
    class="spot-title">
    <v-card-title
      class="pa-1"
      :data-testid="`yard-spot-title-${spot.code}`"
      :class="{
        'bg--color-neutral-20': !selectedSpot || selectedSpot?.id !== spot.id,
        'bg--color-primary-60': selectedSpot?.id === spot.id
      }">
      <div class="font-size-small text-center full-width text-uppercase spot-card-code">
        <strong>{{ spot.code }}</strong>
      </div>
    </v-card-title>
    <v-card-text>
      <v-card
        elevation="0"
        :height="cardContentHeight"
        :data-testid="`yard-spot-unassigned-${spot.code}`"
        class="d-flex flex-column align-center justify-center text-center bg--color-background-transparent">
        <p class="mb-1">
          <v-icon small color="dark-grey" :class="`spot-type-${spot.type}`">
            {{ getSpotIcon(spot.type) }}
          </v-icon>
        </p>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';

export default defineComponent({
  name: 'YardViewSpotEditable',
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const yardStore = useYard();
    const selectedWarehouse = computed(() => yardStore.selectedWarehouse);

    const deleteSpot = () => {
      yardStore.deleteSpot(props.spot.id);
    };

    const handleSpotClick = () => {
      if (yardStore.selectedSpotRef.value?.id === props.spot.id) {
        return;
      }
      yardStore.selectedSpotRef.value = null;
      setTimeout(() => {
        yardStore.selectedSpotRef.value = props.spot;
      }, 200);
    };

    return {
      selectedWarehouse,
      selectedSpot: yardStore.selectedSpotRef,
      deleteSpot,
      handleSpotClick,
      getSpotIcon: yardStore.getSpotIcon,
      spotType: yardStore.spotType,
      cardWidth: yardStore.spotCardWidth,
      cardHeight: yardStore.spotCardHeight,
      cardContentHeight: yardStore.spotCardHeight - 45
    };
  }
});
</script>
<style>
.spot-title {
  border: 1px solid darkgrey !important;
}
</style>
