<template>
  <div>
    <div v-if="shouldShowNewMessageDivider(message, idx)" class="text-divider new-message-divider">
      New
    </div>
    <div v-if="isMessageNewDay(message)" class="text-center text--black mt-3 new-day-divider">
      {{ makeChatTimestamp(message.createDateTime, warehouse.timezone, true, false) }}
    </div>
    <div :class="['mt-3', 'message-group', isIncomingMessage(message) ? 'incoming' : 'outgoing']">
      <div
        v-for="file in message.files"
        :key="file.url"
        class="file-message"
        :class="makeMessageClasses(message, true)">
        <div v-if="isFileImage(file.mimeType)" class="pb-1">
          <v-img :src="file.url" width="240" height="240" max-height="240" max-width="240"></v-img>
        </div>
        <div v-else class="non-image-file-container font-size-x-small">
          <generic-text-icon :text="file.name.split('.').pop()" class="mr-2"></generic-text-icon>
          <div>
            <a :href="file.url" target="_blank" class="d-block pt-1">{{ file.name }}</a>
            {{ Math.round(file.size / 1000) }}kb
          </div>
        </div>
        <drivers-chat-message-footer
          v-if="!message.content"
          :asset-visit="assetVisit"
          :message="message"
          :status="messageStatus"
          :warehouse="warehouse"></drivers-chat-message-footer>
        <v-icon class="download-icon" @click="openImage(file.url)">mdi-download</v-icon>
      </div>
      <div v-if="message.content" :class="makeMessageClasses(message)">
        <div class="text-pre-wrap">{{ message.content }}</div>
        <drivers-chat-message-footer
          :asset-visit="assetVisit"
          :message="message"
          :status="messageStatus"
          :warehouse="warehouse"></drivers-chat-message-footer>
      </div>
      <div
        v-if="isOutgoingMessage(message) && messageStatus === novaCore.IMessageStatus.FAILED"
        class="message-status font-size-x-small text--color-neutral-80 status-icon">
        <v-icon v-if="messageStatus === novaCore.IMessageStatus.FAILED" x-small color="red">
          mdi-alert-circle-outline
        </v-icon>
        Failed
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useEventHub, useMessageThread, useNovaCore } from '@/composables';
import { DateTime } from 'luxon';

export default {
  props: {
    assetVisit: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    messageThread: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    idx: {
      type: Number,
      required: true
    },
    newMessageCount: {
      type: Number,
      required: false
    },
    compactMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const eventHub = useEventHub();
    const assetVisit = ref(props.assetVisit);
    const novaCore = useNovaCore();
    const messageStatus = ref(props.message.status);
    const { isIncomingMessage, makeChatTimestamp, isOutgoingMessage } = useMessageThread(
      assetVisit.value
    );

    const isMessageNewDay = message => {
      const messages = props.messageThread?.messages;
      if (!messages) {
        return false;
      }
      const messageIndex = messages.indexOf(message);
      const prevMessage = messages[messageIndex - 1];
      if (!prevMessage) {
        return true;
      }
      const messageDate = DateTime.fromISO(message.createDateTime).setZone(
        props.warehouse.timezone
      );
      const prevMessageDate = DateTime.fromISO(prevMessage.createDateTime).setZone(
        props.warehouse.timezone
      );

      return !messageDate.hasSame(prevMessageDate, 'day');
    };

    const makeMessageClasses = (message, isFile = false) => {
      return [
        'message',
        'py-2',
        'px-3',
        isIncomingMessage(message) ? 'incoming' : 'outgoing',
        isFile && message.content ? 'mb-3' : '',
        props.compactMode ? 'compact-mode' : ''
      ];
    };

    const isFileImage = mimeType => {
      return mimeType && mimeType.startsWith('image');
    };

    const openImage = url => {
      window.open(url, '_blank');
    };

    const shouldShowNewMessageDivider = (message, idx) => {
      return (
        props.messageThread.unreadMessagesCount > 0 &&
        idx === props.messageThread.messages.length - props.messageThread.unreadMessagesCount
      );
    };

    const handleMessageUpdate = updatedMessage => {
      if (updatedMessage.id === props.message.id) {
        messageStatus.value = updatedMessage.status;
      }
    };

    onBeforeMount(() => {
      eventHub.$on('update-MessageThreadMessage', handleMessageUpdate);
    });

    onBeforeUnmount(() => {
      eventHub.$off('update-MessageThreadMessage', handleMessageUpdate);
    });

    return {
      isIncomingMessage,
      makeChatTimestamp,
      isMessageNewDay,
      makeMessageClasses,
      isFileImage,
      openImage,
      shouldShowNewMessageDivider,
      messageStatus,
      novaCore,
      isOutgoingMessage
    };
  }
};
</script>

<style scoped lang="scss">
.message {
  border-radius: 12px;
  position: relative;
  min-width: 160px;
  max-width: 420px;

  &.file-message {
    width: auto;
    min-width: auto;

    .v-image {
      border-radius: 12px;
    }

    .non-image-file-container {
      display: flex;
      align-items: center;

      a {
        line-height: normal;
      }
    }

    .download-icon {
      position: absolute;
      bottom: 3px;
    }

    &.incoming .download-icon {
      right: -30px;
    }

    &.outgoing .download-icon {
      left: -30px;
    }
  }

  &.outgoing {
    background-color: $color-warning-20;
    align-self: flex-end;
    margin-left: auto;

    .generic-text-icon {
      color: $color-warning-20;
    }
  }

  &.incoming {
    background-color: $color-neutral-20;
    align-self: flex-start;

    .generic-text-icon {
      color: $color-neutral-20;
    }
  }

  &.compact-mode {
    max-width: 272px;
  }
}

.message-group {
  width: fit-content;
  position: relative;

  &.outgoing {
    margin-left: auto;
  }

  .message-status {
    text-transform: capitalize;
    text-align: right;
  }
}

.text-divider {
  display: flex;
  flex-basis: 100%;
  font-weight: 600;
  align-items: center;
  color: $color-primary-100;
  font-size: 12px;
  margin: 8px 0px;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    background: $color-primary-60;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 16px;
  }
}
</style>
