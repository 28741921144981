<template>
  <div>
    <div class="d-flex flex-wrap" v-if="spots.length > 0">
      <template v-if="editable">
        <yard-view-spot-editable
          v-for="spot in spots"
          :key="spot.id"
          :spot="spot"
          class="mr-1 mb-6" />
        <yard-view-spot-add :spot-area="spotArea" v-if="spotArea?.id && !isReadOnly" />
      </template>
      <template v-else>
        <yard-view-spot v-for="spot in spots" :key="spot.id" :spot="spot" class="mr-1 mb-6" />
      </template>
    </div>
    <p v-if="!editable && !spots.length" class="small text--disabled mt-4">
      There are no spots available
    </p>
    <div
      v-if="editable && !spots.length"
      class="d-flex flex-row justify-center align-content-center full-width my-12">
      <yard-view-spot-add
        as-button
        :spot-area="spotArea"
        v-if="editable && spotArea?.id && !isReadOnly" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import useYard from '@/modules/yard/composables/useYard';
import YardViewSpot from '@/modules/yard/components/Spot/Card/YardViewSpot.vue';
import { useRouter } from 'vue-router/composables';
import YardViewSpotAdd from '@/modules/yard/components/Spot/YardViewSpotAdd.vue';
import YardViewSpotEditable from '@/modules/yard/components/Spot/YardViewSpotEditable.vue';

export default defineComponent({
  name: 'YardViewSpotArea',
  components: { YardViewSpotEditable, YardViewSpotAdd, YardViewSpot },
  props: {
    spotArea: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter();
    const yardStore = useYard();
    const selectedWarehouse = computed(() => yardStore.selectedWarehouse);
    const spots = computed(() =>
      yardStore.spotsRef.value.filter(s => s.spotAreaId === props.spotArea.id)
    );

    const editSpotArea = () =>
      router.push({
        name: 'yard-spot-area-detail',
        params: { warehouseId: selectedWarehouse.value.id, spotAreaId: props.spotArea.id }
      });

    return {
      selectedWarehouse,
      editSpotArea,
      isReadOnly: yardStore.isReadOnlyRef,
      spots
    };
  }
});
</script>
