var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.statusType, width: "400" },
      on: {
        close: function ($event) {
          _vm.statusType = null
        },
      },
    },
    [
      _vm.statusType
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "h5",
                    {
                      staticClass: "text-capitalize",
                      attrs: { "data-testid": "yard-spot-status-dialog-title" },
                    },
                    [_vm._v(" " + _vm._s(_vm.statusType) + " this Spot? ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    [
                      _vm.statusType === "close"
                        ? _c("p", [
                            _vm._v(
                              " This spot will remain unavailable for asset assignment until it is set as open again. "
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              "This spot will be available for asset assignment."
                            ),
                          ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "data-testid":
                                    "yard-spot-status-dialog-reason-input",
                                  label: "Reason",
                                  outlined: "",
                                  dense: "",
                                  autofocus: "",
                                  required: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.reason,
                                  callback: function ($$v) {
                                    _vm.reason = $$v
                                  },
                                  expression: "reason",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  "data-testid":
                                    "yard-spot-status-dialog-observation-input",
                                  label: "Additional Notes",
                                  outlined: "",
                                  dense: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.observation,
                                  callback: function ($$v) {
                                    _vm.observation = $$v
                                  },
                                  expression: "observation",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            "data-testid": "yard-spot-status-dialog-cancel",
                          },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(" Nevermind ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.reason,
                            "data-testid": "yard-spot-status-dialog-submit",
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" Confirm ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }