var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      key: _vm.renderKey,
      attrs: {
        transition: "slide-left",
        "content-class": `checkin-notification-dialog ${
          _vm.showAdvertisement ? "is-advertisement" : ""
        }`,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass: "checkin-activator",
                    attrs: {
                      "data-testid": "grid-header-checkin-activator-button",
                    },
                  },
                  on
                ),
                [
                  _c(
                    "div",
                    { staticClass: "checkin-header" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2 text--color-neutral-90",
                          attrs: { small: "" },
                        },
                        [_vm._v("mdi-bell")]
                      ),
                      _c("span", [_vm._v("Arrivals")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "checkin-footer" },
                    [
                      _vm.isLoading
                        ? [
                            _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                size: "20",
                                width: "1",
                              },
                            }),
                          ]
                        : [
                            _vm.totalNewAssetVisitCount > 0
                              ? [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        block: "",
                                        "x-small": "",
                                        text: "",
                                      },
                                    },
                                    [
                                      _vm.totalNewAssetVisitCount > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sonar-badge is-relative",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "badge badge-primary",
                                              }),
                                              _c("div", {
                                                staticClass:
                                                  "badge ping ping-1",
                                              }),
                                              _c("div", {
                                                staticClass:
                                                  "badge ping ping-2",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.totalNewAssetVisitCount) +
                                            " New"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [_vm._v("No new arrivals")],
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.shouldShowDialog,
        callback: function ($$v) {
          _vm.shouldShowDialog = $$v
        },
        expression: "shouldShowDialog",
      },
    },
    [
      _vm.shouldShowInfo
        ? _c(
            "v-card",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "v-card-title",
                { staticClass: "dialog-title align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.shouldShowInfo = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                  _c("span", { staticClass: "pl-3" }, [
                    _vm._v("About arrivals"),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.shouldShowInfo = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-text", [
                _c("span", [
                  _vm._v(
                    " This list shows arrivals from drivers who checked-in on Opendock. Arrivals will be shown in the following statuses: "
                  ),
                ]),
                _c("p", { staticClass: "mt-6" }, [
                  _c("strong", [_vm._v("Pending")]),
                  _c("span", { staticClass: "pt-2 d-block" }, [
                    _vm._v(
                      " These are unplanned arrivals that are pending an action. You can either create a new appointment for this asset, link it to an existing appointment, or reject the load. You will see all Pending arrivals that were checked-in within the last 24 hours. "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "mt-6" }, [
                  _c("strong", [_vm._v("Planned")]),
                  _c("span", { staticClass: "pt-2 d-block" }, [
                    _vm._v(
                      " These are arrivals that already had an appointment at the moment of check-in, or someone linked a Pending arrival to an existing appointment. You will see all Planned arrivals that were checked-in within the last 24 hours. "
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "mt-6" }, [
                  _c("strong", [_vm._v("Resolved")]),
                  _c("span", { staticClass: "pt-2 d-block" }, [
                    _vm._v(
                      " These are unplanned arrivals that have already been acted upon. They either already have an appointment created or the load was rejected. You will see all Resolved arrivals that were checked-in within the last 24 hours. "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _c(
            "v-card",
            { staticClass: "d-flex flex-column" },
            [
              _vm.showAdvertisement
                ? [
                    _c(
                      "v-card-title",
                      { staticClass: "dialog-title" },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowDialog = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("check-in-advertisement", {
                      attrs: { warehouse: _vm.warehouse },
                    }),
                  ]
                : [
                    _c(
                      "v-card-title",
                      { staticClass: "dialog-title" },
                      [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v("Arrivals in " + _vm._s(_vm.warehouse?.name)),
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                _vm.shouldShowDialog = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "height-auto flex-1 flex-column d-flex" },
                      [
                        _vm.totalAssetVisitsForWarehouse === 0
                          ? [
                              _c("icon-message", {
                                attrs: {
                                  icon: "truck-outline",
                                  messageHeader:
                                    "No arrivals yet on this warehouse",
                                  message:
                                    "You can choose a different warehouse in the Appointments page.",
                                },
                              }),
                            ]
                          : [
                              _c(
                                "v-tabs",
                                {
                                  staticClass:
                                    "py-3 sticky-header align-center",
                                  attrs: { grow: "" },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function ($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "flex-grow-1 tab",
                                      attrs: {
                                        "data-testid":
                                          "checkin-panel-pending-tab",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(/* Notice content prop - this is a bug in Vuetify where no falsey values render the content. We have to convert to string */) +
                                          " "
                                      ),
                                      _c("v-badge", {
                                        staticClass: "mr-1",
                                        class: {
                                          "light-badge":
                                            _vm.unplannedAndUnresolvedAssetVisitCount ===
                                            0,
                                        },
                                        attrs: {
                                          content:
                                            _vm.unplannedAndUnresolvedAssetVisitCount ||
                                            "0",
                                          inline: "",
                                          color:
                                            _vm.unplannedAndUnresolvedAssetVisitCount >
                                            0
                                              ? "#955800"
                                              : "#EBEEF0",
                                        },
                                      }),
                                      _vm._v(" Pending "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "flex-grow-1 tab",
                                      attrs: {
                                        "data-testid":
                                          "checkin-panel-planned-tab",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(/* Notice content prop - this is a bug in Vuetify where no falsey values render the content. We have to convert to string */) +
                                          " "
                                      ),
                                      _c("v-badge", {
                                        staticClass: "mr-1 light-badge",
                                        attrs: {
                                          content:
                                            _vm.plannedAssetVisitCount || "0",
                                          inline: "",
                                          color: "#EBEEF0",
                                        },
                                      }),
                                      _vm._v(" Planned "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab flex-grow-1",
                                      attrs: {
                                        "data-testid":
                                          "checkin-panel-resolved-tab",
                                      },
                                    },
                                    [_vm._v(" Resolved ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { icon: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.shouldShowInfo = true
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-information-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  staticClass: "pt-3",
                                  class: {
                                    "d-flex": _vm.isTabFlexBox,
                                  },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function ($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    [
                                      _vm.unplannedAndUnresolvedAssetVisitCount ===
                                      0
                                        ? [
                                            _c("icon-message", {
                                              attrs: {
                                                icon: "check-circle",
                                                messageHeader:
                                                  "No pending arrivals on this warehouse",
                                                message:
                                                  "You can use the tabs above to see other types of arrivals",
                                              },
                                            }),
                                          ]
                                        : [
                                            _c(
                                              "v-expansion-panels",
                                              { attrs: { accordion: "" } },
                                              _vm._l(
                                                _vm.unplannedAndUnresolvedAssetVisits,
                                                function (visit) {
                                                  return _c(
                                                    "asset-visit-item-old",
                                                    {
                                                      key: visit.id,
                                                      attrs: {
                                                        warehouse:
                                                          _vm.$selectedWarehouse,
                                                        visit: visit,
                                                        "show-actions": true,
                                                      },
                                                      on: {
                                                        updated:
                                                          _vm.updateAssetVisitInArr,
                                                        "show-appointment-list-dialog":
                                                          function ($event) {
                                                            return _vm.handleAppointmentListDisplay(
                                                              visit
                                                            )
                                                          },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "title",
                                                            fn: function ({
                                                              visit,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "font-weight-bold font--poppins mb-2 text--color-warning-100",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Unplanned arrival "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-tab-item",
                                    [
                                      _vm.plannedAssetVisitCount === 0
                                        ? [
                                            _c("icon-message", {
                                              attrs: {
                                                icon: "check-circle",
                                                messageHeader:
                                                  "No planned arrivals on this warehouse today",
                                                message:
                                                  "You can use the tabs above to see other types of arrivals",
                                              },
                                            }),
                                          ]
                                        : _c(
                                            "v-expansion-panels",
                                            { attrs: { accordion: "" } },
                                            _vm._l(
                                              _vm.plannedAssetVisits,
                                              function (visit) {
                                                return _c(
                                                  "asset-visit-item-old",
                                                  {
                                                    key: visit.id,
                                                    attrs: {
                                                      warehouse:
                                                        _vm.$selectedWarehouse,
                                                      visit: visit,
                                                    },
                                                    on: {
                                                      updated:
                                                        _vm.updateAssetVisitInArr,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function ({
                                                            visit,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center mb-2",
                                                                },
                                                                [
                                                                  !visit.checkInAcknowledged
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  top: "",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "activator",
                                                                                      fn: function ({
                                                                                        on,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            _vm._g(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "pr-2",
                                                                                                attrs:
                                                                                                  {
                                                                                                    disabled:
                                                                                                      _vm.acknowledgementInProcess,
                                                                                                    "data-testid": `checkin-panel-acknowledge-button-${visit.id}`,
                                                                                                    size: "12px",
                                                                                                    color:
                                                                                                      "#FF9A1A",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.stopPropagation()
                                                                                                      return _vm.acknowledgeAssetVisit(
                                                                                                        visit
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _vm._v(
                                                                                                " mdi-circle "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Mark as read "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "pr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Planned arrival"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm.getAppointmentArrivalStatus(
                                                                    visit
                                                                  ) ===
                                                                  _vm
                                                                    .EtaCondition
                                                                    .Early
                                                                    ? _c(
                                                                        "v-chip",
                                                                        {
                                                                          staticClass:
                                                                            "early-tag",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#DCF2E6",
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Early "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm.getAppointmentArrivalStatus(
                                                                        visit
                                                                      ) ===
                                                                      _vm
                                                                        .EtaCondition
                                                                        .Late
                                                                    ? _c(
                                                                        "v-chip",
                                                                        {
                                                                          staticClass:
                                                                            "late-tag",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#FFEED9",
                                                                              "x-small":
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Late "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : visit.appointmentId
                                                                    ? _c(
                                                                        "v-chip",
                                                                        {
                                                                          staticClass:
                                                                            "on-time-tag",
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              color:
                                                                                "#F5F5F5",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " On Time "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-tab-item",
                                    [
                                      _vm.resolvedAssetVisitCount === 0
                                        ? [
                                            _c("icon-message", {
                                              attrs: {
                                                icon: "check-circle",
                                                messageHeader:
                                                  "No resolved arrivals on this warehouse",
                                                message:
                                                  "You can use the tabs above to see other types of arrivals",
                                              },
                                            }),
                                          ]
                                        : _c(
                                            "v-expansion-panels",
                                            { attrs: { accordion: "" } },
                                            _vm._l(
                                              _vm.resolvedAssetVisits,
                                              function (visit) {
                                                return _c(
                                                  "asset-visit-item-old",
                                                  {
                                                    key: visit.id,
                                                    attrs: {
                                                      warehouse:
                                                        _vm.$selectedWarehouse,
                                                      visit: visit,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "title",
                                                          fn: function ({
                                                            visit,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center mb-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "font-weight-bold mr-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Unplanned arrival"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  visit.appointmentId
                                                                    ? _c(
                                                                        "v-chip",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Appointment created"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm.isAssetVisitCancelled(
                                                                        visit
                                                                      )
                                                                    ? _c(
                                                                        "v-chip",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Rejected"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  }
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
            ],
            2
          ),
      _vm.assetToLink
        ? _c("link-appointment-dialog", {
            attrs: {
              "asset-to-link": _vm.assetToLink,
              "external-activator": "",
              warehouse: _vm.warehouse,
              "is-military-time-enabled": _vm.isMilitaryTimeEnabled,
              "reference-number-settings": _vm.referenceNumberSettings,
              "show-dialog": _vm.shouldShowAppointmentListDialog,
            },
            on: {
              close: function ($event) {
                _vm.shouldShowAppointmentListDialog = false
              },
              linked: _vm.handleAppointmentLinked,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }